import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Image_connection from "../../Image_connection";
import "./Doctor_consult_details.css";
import Connection from '../../connection';
import moment from 'moment';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';

import PulseLoader from "react-spinners/PulseLoader";
import VideocamIcon from '@mui/icons-material/Videocam';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import html2canvas from 'html2canvas';

function Doctor_consult_details({ handleClose, getProducts, fee, status, user_address, meeting_id, receiver_id, receiver_name, user_email, user_mobile, sender_id, sender_name, receiver_image, sender_image, fcm_token, web_fcm, user_online, date2, app_type, user_type, active, payment_method }) {

    const navigate = useNavigate();

    const [spinner, setSpinner] = useState(false);
    const [skalton, setSkalton] = useState(true);

    const [front_image, setFront_image] = useState(null);
    const [back_image, setBack_image] = useState(null);

    const [data, setData] = useState([]);
    const [reason, setReason] = useState('');
    const [symptoms, setSymptoms] = useState('');
    const [issue, setIssue] = useState('');
    const [allergic, setAllergic] = useState('');
    const [medication, setMedication] = useState('');

    const [doctor_id, setDoctor_id] = useState('');
    const [doctorname, setDoctorname] = useState('');
    const [doctor_address, setDoctor_address] = useState('');


    useEffect(() => {
        const auth = localStorage.getItem("customer");
        const parsed = JSON.parse(auth);
        const id = parsed[0].id;
        const doctorname = parsed[0].name;
        const doctor_address = parsed[0].address;
        setDoctor_id(id)
        setDoctorname(doctorname)
        setDoctor_address(doctor_address)

        if (payment_method == 'Insurance') {
            checkinsurancehaveornot()
        }
        getIssueAppointment(id);
    }, [])

    const checkinsurancehaveornot = async () => {
        const user_id = receiver_id
        let result = await fetch(Connection + "check_insurance", {
            method: 'post',
            body: JSON.stringify({ user_id }),
            headers: {
                "Content-Type": 'application/json'
            }
        })
        result = await result.json()
        const record4 = result.response
        console.log(".,.,.CHECK INSURANCE,.,.", record4)

        if (record4 == 'success') {
            const reco = result.data

            let frnt_age = reco[0].front_image
            let front_image = Image_connection + frnt_age

            let bac_imag = reco[0].back_image
            let back_image = Image_connection + bac_imag

            setFront_image(front_image)
            setBack_image(back_image)
        }
        else { }
    };


    const getIssueAppointment = async (id) => {
        setSkalton(true);

        const appointment_id = meeting_id

        let result = await fetch(Connection + "getissue_appointment_for_doctor", {
            method: 'post',
            body: JSON.stringify({ id, appointment_id }),
            headers: {
                "Content-Type": 'application/json'
            }
        })
        const response = await result.json();
        let response1 = response.response;
        console.log("response .........", response1);

        if (response1 !== 'fail') {
            let reason = response1[0].reason;
            let symptoms = response1[0].symptoms;
            let issue = response1[0].issue;
            let allergic = response1[0].allergic;
            let medication = response1[0].medication;

            setData(response1);
            setReason(reason);
            setSymptoms(symptoms);
            setIssue(issue);
            setAllergic(allergic);
            setMedication(medication);
            setSkalton(false);
        }
        else {
            setData([]);
            setSkalton(false);
        }
    };


    // before complete Dialog PopUp
    const [completeDialog, setCompleteDialog] = useState(false);
    const handleShowDialog = () => {
        if (!medication) {
            alert("Please enter Physician Solution")
        }
        else {
            setCompleteDialog(true);
        }
    };
    /////////////


    const complete_consult = async () => {
        setCompleteDialog(false);
        setSpinner(true);

        // Date And Time For Notification Date 
        const aa = moment(new Date()).format("YYYY-MM-DD hh:mm A");
        const split = aa.split(' ')
        const date = split[0]
        const n_time = split[1]
        const am_pm = split[2]
        const time = n_time + "" + am_pm
        //////////////////////////////

        let user_id = receiver_id
        let appointment_id = meeting_id
        let user_name = receiver_name
        let doctor_name = sender_name

        let tax = 6
        let d_earning = Number(fee - tax).toFixed(2);
        let type = 'complete'

        let amount;
        if (user_type === "hisd") {
            amount = '0'
        }
        else {
            amount = d_earning;
        }

        let response = await fetch(Connection + "consult_complete_a", {
            method: 'post',
            body: JSON.stringify({ doctor_id, user_id, appointment_id, medication, amount, date, time, type, user_name, doctor_name }),
            headers: {
                "Content-Type": 'application/json'
            }
        })
        const result = await response.json();
        console.log('accept appointment', result.response);


        if (result.response === "success4") {
            toast.success('You have successfully complete this Consultant.');

            if (user_type !== "hisd") {
                const user = localStorage.getItem("customer");
                let parsed = JSON.parse(user);
                const balance = parseFloat(parsed[0].balance);

                if (balance !== '') {
                    const get_balance = parseFloat(parsed[0].balance);
                    const dd_earning = parseFloat(d_earning);
                    parsed[0].balance = get_balance + dd_earning;
                    localStorage.setItem("customer", JSON.stringify(parsed))
                }
                else {
                    const dd_earning = parseFloat(d_earning);
                    parsed[0].balance = balance + dd_earning;
                    localStorage.setItem("customer", JSON.stringify(parsed))
                }
            }

            setSpinner(false);
            handleClose(false)
            app_notification()
            web_notification()
            getProducts(doctor_id)
        }
        else {
            setSpinner(false);
            alert("Something went wrong try again later");
        }
    };


    const app_notification = async () => {
        let to = fcm_token;
        console.log("To is => ", to)

        fetch('https://fcm.googleapis.com/fcm/send', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'key= AAAAaCD4Bcc:APA91bETamRJX5KYdE6sCHUzquj_DpmfqiqH3ogXH1K1m1SH5U5_60OPWrPP5hHMyq_1pQcUN2WMWAPpCOzFDJJ4lDt6X3IDNwLuohzTB8ja3EDGM-FDTePfmCEYpFTsUoH-wguvF6Ux'//cloud server key
            },
            body: JSON.stringify({
                "to": to,
                "priority": "high",
                "notification": {
                    "title": "Mas Clinicas",
                    "body": "Dr." + doctorname + " has completed your online doctor visit.",
                    "sound": 'default',
                    "icon": "myicon",
                }
            }
            )
        }).then(res => res.json())
            .then(resjson => console.log("test", resjson))
            .catch(err => console.log("error =>", err))
    }


    const web_notification = async () => {
        let to = web_fcm;
        console.log("To is => ", to)

        fetch('https://fcm.googleapis.com/fcm/send', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'key= AAAAPzWsmh8:APA91bGwGLlejpat2ndQPc3708f5KqvTPRFm9oZ7RQyBB9khWgUn_2JFZJdIf5Lta7dS2M9wTj_OYaxxmZ3FRVckZGoDJpEWyUC81G1hJliAhiKUGaZJS0P3cP-T_Z5VwGKPQzNsk_75'//cloud server key
            },
            body: JSON.stringify({
                "to": to,
                "priority": "high",
                "notification": {
                    "title": "Mas Clinicas",
                    "body": "Dr." + doctorname + " has completed your online doctor visit.",
                    "sound": 'default',
                    "icon": "myicon",
                }
            }
            )
        }).then(res => res.json())
            .then(resjson => console.log("test", resjson))
            .catch(err => console.log("error =>", err))
    }


    const agora_notification = async () => {
        console.log("fcm_token", fcm_token)
        if ((fcm_token === null || fcm_token === '') && (web_fcm === null || web_fcm === '')) {
            alert("This user is currently unavailable.");
        }
        else {
            document.body.style.overflow = 'auto';

            const call_check = 'do_call'
            const url = `/AgoraCall?call_check=${call_check}&fcm_token=${fcm_token}&web_fcm=${web_fcm}`;
            navigate(url);
        }
    };


    const chat_romm = async () => {
        document.body.style.overflow = 'auto';

        const check_chat = 'do_chat'
        const userprofile = Image_connection + receiver_image
        const url = `/Chat?check_chat=${check_chat}&receiver_image=${receiver_image}&chat_image=${receiver_image}&chat_name=${receiver_name}&receiver_id=${receiver_id}&fcm_token=${fcm_token}&web_fcm=${web_fcm}&active=${active}&sender_name=${sender_name}&receiver_name=${receiver_name}&chat_name_disable=${receiver_name}`;
        navigate(url);
    }


    const handleDownloadImage = () => {
        const prescriptionContainer = document.querySelector('.capture_image');

        if (prescriptionContainer) {
            html2canvas(prescriptionContainer)
                .then((canvas) => {
                    const url = canvas.toDataURL('image/png');

                    // Create an anchor element and trigger the download
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = `Prescription_${meeting_id}.png`; // You can customize the filename
                    document.body.appendChild(a);
                    a.click();

                    // Cleanup
                    document.body.removeChild(a);
                })
                .catch((error) => {
                    console.error('Error capturing image:', error);
                });
        }
    };


    return (
        <div className="Doctor_urgentsideBackground">

            <div className="doctorurgentsideContainer">
                <div className="titledoctorurgentsideCloseBtn">
                    <button onClick={() => { handleClose(false); }}>
                        <ArrowBackIcon />
                    </button>
                    <h3>Consult Detail</h3>
                    <h1></h1>
                </div>

                <hr style={{ marginTop: 15, marginBottom: 3 }} />


                <div className="your-container-style">
                    <div className="row-style">
                        <div className="column-style">
                            <p className="label-style">Patient Name:</p>
                            <p className="label-style">Doctor Name:</p>
                            <p className="label-style">Meeting:</p>
                            <p className="label-style">Date:</p>
                            <p className="label-style">Status:</p>
                            <p className="label-style">Type:</p>
                        </div>

                        <div className="column-style">
                            <p className="value-style" style={{ maxWidth: '85%' }}>{receiver_name}</p>
                            <p className="value-style" style={{ maxWidth: '85%' }}>{sender_name}</p>
                            <p className="value-style">Urgent Visits</p>
                            <p id='datestyleid' className="value-style" style={{ maxWidth: '85%' }}>{date2}</p>
                            <p className={`status-style ${status.toLowerCase()}`}>{status}</p>
                            <p className={`payment-style`}>{user_type == 'hisd' ? 'FREE HISD' : 'Paid'}</p>
                        </div>
                    </div>
                </div>


                {status === 'pending' && (
                    <div className="button_container">
                        <button onClick={() => chat_romm()} className="chat_button">
                            <QuestionAnswerIcon style={{ color: '#874DAF', fontSize: '18px' }} />
                            <span>Chat with Patient</span>
                        </button>

                        <button className="video_main_div">
                            <div onClick={() => agora_notification()} className="video_button">
                                <VideocamIcon style={{ color: '#0751fc', fontSize: '25px' }} />
                            </div>
                        </button>
                    </div>
                )}


                <div className="your-component-container">
                    {skalton ? (
                        <div className="skalton-container">
                            <PulseLoader
                                color={"gray"}
                                loading={skalton}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />
                        </div>
                    ) : (
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            {payment_method == 'Insurance' &&
                                <>
                                    <div className="data-container" style={{ marginBottom: 10 }}>
                                        <p style={{ color: "#874DAF", fontWeight: 'bold', fontSize: 14 }}>Front Image</p>

                                        <hr className="divider" />

                                        <img src={front_image} alt="" className="imageshowindetail" />
                                    </div>

                                    <div className="data-container" style={{ marginBottom: 10 }}>
                                        <p style={{ color: "#874DAF", fontWeight: 'bold', fontSize: 14 }}>Front Image</p>

                                        <hr className="divider" />

                                        <img src={back_image} alt="" className="imageshowindetail" />
                                    </div>
                                </>
                            }

                            {data.length === 0 ? (
                                <div className="no-data-container">
                                    <p style={{ color: 'black' }}>No treatment result</p>
                                </div>
                            ) : (
                                <div className='capture_image' style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    <div className="data-container">
                                        <p style={{ color: "#874DAF", fontWeight: 'bold', fontSize: 14 }}>More Details</p>

                                        <hr className="divider" />

                                        <div className="detail-row">
                                            <p style={{ color: 'black', fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>Reason:</p>
                                            <p style={{ color: 'black', fontSize: 12, maxWidth: '90%' }}>{reason}</p>
                                        </div>

                                        <div className="detail-row">
                                            <p style={{ color: 'black', fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>Symptoms:</p>
                                            <p style={{ color: 'black', fontSize: 12, maxWidth: '90%' }}>{symptoms}</p>
                                        </div>

                                        <div className="detail-row">
                                            <p style={{ color: 'black', fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>Issues:</p>
                                            <p style={{ color: 'black', fontSize: 12, maxWidth: '90%' }}>{issue}</p>
                                        </div>

                                        <div className="detail-row">
                                            <p style={{ color: 'black', fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>Allergic:</p>
                                            <p style={{ color: 'black', fontSize: 12, maxWidth: '90%' }}>{allergic}</p>
                                        </div>
                                    </div>

                                    {status === 'complete' && (
                                        <div className="prescription-container">
                                            <p className="prescription-title">Prescription</p>
                                            <div className="divider"></div>
                                            <p className="prescription-text">{medication}</p>
                                        </div>
                                    )}
                                </div>
                            )}


                            {status === 'pending' && (
                                <>
                                    <p className="physician-solution">Physician Solution</p>
                                    <textarea
                                        onChange={(e) => setMedication(e.target.value)}
                                        value={medication}
                                        className="text-input"
                                        placeholder={"Visit Summary"}
                                    ></textarea>
                                    <p>Please complete this Consult after you give online video consult to patient.</p>
                                    <button onClick={() => handleShowDialog()}
                                        className={'button-android'}
                                    >
                                        <p style={{ color: 'white', fontWeight: 'bold' }}>Complete</p>
                                    </button>
                                </>
                            )}

                            {status === 'complete' && (
                                <button onClick={handleDownloadImage}
                                    className="download-button"
                                >
                                    <DownloadOutlinedIcon style={{ color: 'white', fontSize: 22, marginRight: 5 }} />
                                    <p style={{ color: 'white', fontWeight: 'bold' }}>Download</p>
                                </button>
                            )}
                        </div>
                    )}
                </div>

            </div>

            <Dialog className='cancelapoint_Dialog' open={completeDialog}>
                {/* <div className='done_view'>
                    <CheckIcon />
                </div> */}

                <DialogTitle className='Cancel_dialog_toptitl'>
                    {/* <h4>Congratulations. Your Appointment</h4> */}
                    <h5>Are you sure to want complete this Consult?</h5>
                </DialogTitle>

                <DialogActions className='cancelapoint_Ok_nd_Close'>
                    <Button onClick={() => setCompleteDialog(false)} className='cancelapoint_Ok'>CANCEL</Button>
                    <Button onClick={complete_consult} className='cancelapoint_Ok'>OK</Button>
                </DialogActions>

                <ToastContainer />
            </Dialog>

            <ToastContainer />

            {spinner && (
                <div style={{ width: '100%', height: '100%', backgroundColor: 'rgba(2, 2, 2, 0.8)', position: 'absolute', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <div style={{ display: 'flex', width: '30%', backgroundColor: 'white', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', padding: '30px', boxShadow: '0 1px 5px rgba(0, 0, 0, 0.2)', borderRadius: '6px', }}>
                        <PulseLoader
                            color={"#874DAF"}
                            loading={spinner}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                        <p style={{ fontSize: '16px', color: '#874DAF', fontWeight: 'bold', marginLeft: '15px' }}>
                            Progressing your request
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Doctor_consult_details;
