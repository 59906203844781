import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom"
import "./Modal_payment_done.css";
import moment from 'moment';
import Connection from '../../connection';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PulseLoader from "react-spinners/PulseLoader";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';

function Modal_payment_done({ showpayment_done, handleClose, wallet, handlepaymentdoneClose, day, date, time, appointment_price, category, doctor_name, city, doctor_id, reason, symptoms, issue, allergic, promo_end, method, email, fcm_token, web_fcm, type_of, usertype }) {

   const navigate = useNavigate();

   const [spinner, setSpinner] = useState(false);
   const [firstTime, setFirstTime] = useState(true);
   const [record_data, setRecord_data] = useState('');


   // Dialog PopUp
   const [showDialog, setShowDialog] = useState(false);
   const handleShowDialog = () => {
      setShowDialog(true);
   };
   const handleCloseDialog = () => {
      setShowDialog(false);
   };
   /////////////


   const confirmation = () => {
      setSpinner(true)
      setFirstTime(false)

      const auth = localStorage.getItem('customer');
      if (auth != null) {
         const parsed = JSON.parse(auth);
         const id = parsed[0].id;
         const name = parsed[0].name;

         if (wallet == true) {
            Add_wallet(id)
         }
         else {
            Add_appointment(id, name);
         }
      }
   }


   const Add_wallet = async (id) => {
      let user_id = id
      let amount = appointment_price
      let result = await fetch(Connection + "Add_wallet_a", {
         method: 'post',
         body: JSON.stringify({ user_id, amount }),
         headers: {
            "Content-Type": 'application/json'
         }
      })

      result = await result.json()
      let record = result.response
      console.log("Wallet =>", record)

      if (record === "success") {
         let userData = result.user;
         console.log("User Data:", userData);

         setSpinner(false)
         setRecord_data(record)

         toast.success('Payment has been Successfull');
         localStorage.setItem("customer", JSON.stringify(userData))
      }
      else {
         setSpinner(false)
         setRecord_data('')

         alert("Something went wrong try again later");
      }
   }

   const Add_appointment = async (id, name) => {
      const user_id = id
      const user_name = name
      const state = city
      const doctor_payed = 'false'
      const type = 'success'

      let timee = "";
      let dayy = "";
      let datee = "";
      let status = "";

      if (type_of == 'urgent') {
         timee = ''
         dayy = ''
         datee = ''
         if (method == 'Insurance') {
            status = 'request'
         }
         else {
            status = 'pending'
         }
      }
      else {
         timee = time
         dayy = day
         datee = date
         status = 'request'
      }


      let fee = ''
      let d_earning = '';
      let tax = ''

      if (usertype == 'hisd') {
         fee = '0'
         d_earning = '0'
         tax = '0'
      }
      else {
         if (type_of == 'offline') {
            fee = '0'
            d_earning = '0'
            tax = '0'
         }
         else {
            fee = appointment_price
            d_earning = Number(fee - tax).toFixed(2);
            tax = 6
         }
      }

      // Date And Time For Notification Date 
      const aa = moment(new Date()).format("YYYY-MM-DD hh:mm A");
      const split = aa.split(' ')
      const notification_date = split[0]
      const n_time = split[1]
      const am_pm = split[2]
      const notification_time = n_time + "" + am_pm
      //////////////////////////////

      console.log("1 =>", user_id)
      console.log("2 =>", doctor_id)
      console.log("3 =>", category)
      console.log("4 =>", datee)
      console.log("5 =>", timee)
      console.log("6 =>", dayy)
      console.log("7 =>", fee)
      console.log("8 =>", reason)
      console.log("9 =>", symptoms)
      console.log("10 =>", issue)
      console.log("11 =>", allergic)
      console.log("12 =>", state)
      console.log("13 =>", promo_end)
      console.log("14 =>", tax)
      console.log("15 =>", d_earning)
      console.log("16 =>", method)
      console.log("17 =>", doctor_payed)
      console.log("18 =>", notification_date)
      console.log("19 =>", notification_time)
      console.log("20 =>", type)
      console.log("21 =>", user_name)
      console.log("22 =>", doctor_name)
      console.log("23 =>", type_of)
      console.log("24 =>", usertype)
      console.log("25 =>", status)

      let result = await fetch(Connection + "new_for_add_apoint", {
         method: 'post',
         body: JSON.stringify({ user_id, doctor_id, category, datee, timee, dayy, fee, reason, symptoms, issue, allergic, state, promo_end, tax, d_earning, method, doctor_payed, notification_date, notification_time, type, user_name, doctor_name, type_of, usertype, status }),
         headers: {
            "Content-Type": 'application/json'
         }
      })

      result = await result.json()
      let record = result.response
      console.log("result123", record)

      if (record == "success4") {
         setRecord_data(record)

         if (promo_end == 'true') {
            const user = localStorage.getItem("customer");
            let parsed = JSON.parse(user);
            parsed[0].promo = 'true';
            localStorage.setItem("customer", JSON.stringify(parsed))
         }

         app_notification(name)
         web_notification(name)

         setTimeout(() => {
            setSpinner(false);
            toast.success('Your Appointment successfully booked!');
         }, 100);
      }
      else {
         alert("Something went wrong try again later");
         setRecord_data('')
         setSpinner(false);
      }
   }


   const app_notification = async (name) => {
      let to = fcm_token;

      let sendtye = "";
      if (type_of == 'online') {
         sendtye = 'Online Appointment'
      }
      else if (type_of == 'offline') {
         sendtye = 'Clinic Appointment'
      }
      else {
         sendtye = 'Urgent Care'
      }

      let notificationBody = "";
      if (usertype == 'hisd') {
         notificationBody = name + " has booked free hisd appointment with you! " + (sendtye);
      }
      else {
         notificationBody = name + " has booked an appointment with you! " + (sendtye);
      }

      console.log("App To is => ", to)
      fetch('https://fcm.googleapis.com/fcm/send', {
         method: 'POST',
         headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'key= AAAAaCD4Bcc:APA91bETamRJX5KYdE6sCHUzquj_DpmfqiqH3ogXH1K1m1SH5U5_60OPWrPP5hHMyq_1pQcUN2WMWAPpCOzFDJJ4lDt6X3IDNwLuohzTB8ja3EDGM-FDTePfmCEYpFTsUoH-wguvF6Ux'//cloud server key
         },
         body: JSON.stringify({
            "to": to,
            "priority": "high",
            "notification": {
               "title": "Mas Clinicas",
               "body": notificationBody,
               "sound": 'default',
               "icon": "myicon",
            }
         }
         )
      }).then(res => res.json())
         .then(resjson => console.log("test", resjson))
         .catch(err => console.log("error =>", err))
   }


   const web_notification = async (name) => {
      let to = web_fcm;

      let sendtye = "";
      if (type_of == 'online') {
         sendtye = 'Online Appointment'
      }
      else if (type_of == 'offline') {
         sendtye = 'Clinic Appointment'
      }
      else {
         sendtye = 'Urgent Care'
      }

      let notificationBody = "";
      if (usertype == 'hisd') {
         notificationBody = name + " has booked free hisd appointment with you! " + (sendtye);
      }
      else {
         notificationBody = name + " has booked an appointment with you! " + (sendtye);
      }

      console.log("Web To is => ", to)
      fetch('https://fcm.googleapis.com/fcm/send', {
         method: 'POST',
         headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'key= AAAAPzWsmh8:APA91bGwGLlejpat2ndQPc3708f5KqvTPRFm9oZ7RQyBB9khWgUn_2JFZJdIf5Lta7dS2M9wTj_OYaxxmZ3FRVckZGoDJpEWyUC81G1hJliAhiKUGaZJS0P3cP-T_Z5VwGKPQzNsk_75'//cloud server key
         },
         body: JSON.stringify({
            "to": to,
            "priority": "high",
            "notification": {
               "title": "Mas Clinicas",
               "body": notificationBody,
               "sound": 'default',
               "icon": "myicon",
            }
         }
         )
      }).then(res => res.json())
         .then(resjson => console.log("test", resjson))
         .catch(err => console.log("error =>", err))
   }


   const BacktoHome = () => {
      // handlepaymentdoneClose(false)
      document.body.style.overflow = 'auto';
      navigate('/')
   }


   return (
      <div className="modal_paymentdonBackground">

         <div className="modalpaymentdonContainer">

            {spinner == false ?
               <>
                  {firstTime == true ?
                     <div className='confirmmaindiv'>
                        <div className='Start_Appointment'>
                           <h2>Confirmation</h2>

                           <hr className='line' />

                           {wallet == true ?
                              <>
                                 <h4 className='h3spec'>You requested for amount ${appointment_price}</h4>
                              </>
                              :
                              <>
                                 <h4 className='h3spec'>Your appointment going to book with Dr. {doctor_name}</h4>

                                 <h4 className='h4spec'>Appointment Details:</h4>

                                 <div className='mention_nd_nmae'>
                                    <p>Doctor Name</p>
                                    <h5>{doctor_name}</h5>
                                 </div>

                                 <div className='mention_nd_nmae'>
                                    <p>Category</p>
                                    <h5>{category}</h5>
                                 </div>

                                 <div className='mention_nd_nmae'>
                                    <p>Type</p>
                                    <h5>{type_of}</h5>
                                 </div>

                                 {type_of != 'urgent' &&
                                    <>
                                       <div className='mention_nd_nmae'>
                                          <p>Date</p>
                                          <h5>{date}</h5>
                                       </div>

                                       <div className='lastention_nd_nmae'>
                                          <p>Time</p>
                                          <h5>{time}</h5>
                                       </div>
                                    </>
                                 }
                              </>
                           }
                        </div>

                        <div onClick={confirmation} className='first_confirm_bottn'>
                           <h4>Please Confirm</h4>
                        </div>
                     </div>
                     :
                     <>
                        {record_data != '' ?
                           <>
                              {wallet == true ?
                                 <div className='Start_Appointment'>
                                    <div className='done_view'>
                                       <CheckIcon />
                                    </div>

                                    <h2>Payment Processed Successfully</h2>

                                    <hr className='line' />

                                    <h4 className='h3spec'>You have successfully paid ${appointment_price} from your card to your Wallet</h4>
                                 </div>
                                 :

                                 <>
                                    {usertype == 'hisd' ?
                                       <div className='Start_Appointment'>
                                          <div className='done_view'>
                                             <CheckIcon />
                                          </div>

                                          <h2>Congratulation!</h2>

                                          <hr className='line' />

                                          <h4 className='h3spec'>Your FREE HISD appointment successfully booked.</h4>

                                          <h4 className='h4spec'>Appointment Details:</h4>

                                          <div className='mention_nd_nmae'>
                                             <p>Doctor Name</p>
                                             <h5>{doctor_name}</h5>
                                          </div>

                                          <div className='mention_nd_nmae'>
                                             <p>Category</p>
                                             <h5>{category}</h5>
                                          </div>

                                          <div className='mention_nd_nmae'>
                                             <p>Type</p>
                                             <h5>{type_of}</h5>
                                          </div>

                                          {type_of != 'urgent' &&
                                             <>
                                                <div className='mention_nd_nmae'>
                                                   <p>Date</p>
                                                   <h5>{date}</h5>
                                                </div>

                                                <div className='lastention_nd_nmae'>
                                                   <p>Time</p>
                                                   <h5>{time}</h5>
                                                </div>
                                             </>
                                          }
                                       </div>
                                       :
                                       <div className='Start_Appointment'>
                                          <div className='done_view'>
                                             <CheckIcon />
                                          </div>

                                          {method == 'Insurance' ?
                                             <h2>Congratulation!</h2>
                                             :
                                             <>
                                                {type_of == 'offline' ?
                                                   <h2>Congratulation!</h2>
                                                   :
                                                   <h2>Payment processed successfully</h2>
                                                }
                                             </>
                                          }

                                          <hr className='line' />

                                          {method == 'Insurance' ?
                                             <h4 className='h3spec'>Your Insurance Appointment successfully booked.</h4>
                                             :
                                             <>
                                                {type_of == 'offline' ?
                                                   <h4 className='h3spec'>Your In Person Clinic Appointment successfully booked.</h4>
                                                   :
                                                   <h4 className='h3spec'>You have successfully paid $ {appointment_price} from your {method} Card Number to Dr. {doctor_name}</h4>
                                                }
                                             </>
                                          }
                                          <h4 className='h4spec'>Appointment Details:</h4>

                                          <div className='mention_nd_nmae'>
                                             <p>Doctor Name</p>
                                             <h5>{doctor_name}</h5>
                                          </div>

                                          <div className='mention_nd_nmae'>
                                             <p>Category</p>
                                             <h5>{category}</h5>
                                          </div>

                                          <div className='mention_nd_nmae'>
                                             <p>Type</p>
                                             <h5>{type_of}</h5>
                                          </div>

                                          {type_of != 'urgent' &&
                                             <>
                                                <div className='mention_nd_nmae'>
                                                   <p>Date</p>
                                                   <h5>{date}</h5>
                                                </div>

                                                <div className='lastention_nd_nmae'>
                                                   <p>Time</p>
                                                   <h5>{time}</h5>
                                                </div>
                                             </>
                                          }
                                       </div>
                                       // <div className='Start_Consult'>
                                       //    <div className='done_view'>
                                       //       <CheckIcon />
                                       //    </div>

                                       //    <h2>Payment processed successfully</h2>

                                       //    <hr className='line' />

                                       //    <h4 className='h3spec'>You have successfully booked a live consult with Dr. {doctor_name}</h4>

                                       //    <h4 className='h4spec'>Please wait, soon you will be in a video consultation with your booked doctor.</h4>

                                       //    <Dialog className='Consult_Dialog' open={showDialog} onClose={handleCloseDialog}>
                                       //       <DialogTitle className='Consult_dialog_toptitl'>
                                       //          <h4>The doctor will connect</h4>
                                       //          <h5>with you in a few minutes.</h5>
                                       //       </DialogTitle>

                                       //       <DialogActions className='Consult_Ok_nd_Close'>
                                       //          <Button onClick={handleCloseDialog} className='Consult_Ok'>CLOSE</Button>
                                       //          <div className='line'></div>
                                       //          <Button onClick={handleCloseDialog} className='Consult_Close'>OK</Button>
                                       //       </DialogActions>
                                       //    </Dialog>
                                       // </div>
                                    }
                                 </>
                              }
                           </>
                           :
                           <div className='Start_Cancel'>
                              <div className='done_view'>
                                 <CloseIcon />
                              </div>

                              <h2>Payment Processing Failed.</h2>

                              <hr className='line' />

                              <h4 className='h3spec'>You have failed to booked an appointment with Dr. {doctor_name}</h4>
                           </div>
                        }

                        {record_data != '' ?
                           <div onClick={BacktoHome} className='last_backbutun'>
                              <h4>Go to Home</h4>
                           </div>
                           :
                           <div onClick={BacktoHome} className='last_cancelbackbutun'>
                              <h4>Go to Home</h4>
                           </div>
                        }
                     </>
                  }
               </>
               :
               <></>
            }

            {spinner == true &&
               <div className='spinner_main_loader'>
                  <div className='spinner_inside'>
                     <PulseLoader
                        color={"#874DAF"}
                        loading={spinner}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                     />
                  </div>
               </div>
            }

         </div>

         <ToastContainer />
      </div>
   );
}

export default Modal_payment_done;
