import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom"
import 'react-toastify/dist/ReactToastify.css';
import "./Modal_categ.css";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import Physician1 from '../../assets/Physician1.png';
import oral from '../../assets/oral.png';
import heart from '../../assets/heart.png';
import Pediatric1 from '../../assets/Pediatric1.png';
import Dermotoglist1 from '../../assets/Dermotoglist1.png';
import ortho from '../../assets/ortho.png';
import brain from '../../assets/brain.png';
import Mental_Health from '../../assets/Mental_Health.png';
import terapist from '../../assets/terapist.png';


function Modal({ handleClose, type_of, usertype }) {

   const navigate = useNavigate();

   useEffect(() => {
      const auth = localStorage.getItem("customer");
      const parsed = JSON.parse(auth);
      const id = parsed[0].id;
   }, [])

   const handleCategoryClick = async (category) => {
      document.body.style.overflow = 'auto';

      const catrgoty = category['category']

      const url = `/Doctor_by_category/${catrgoty}?value1=${type_of}&value2=${usertype}`;
      navigate(url);
   };

   return (
      <div className="modal_categBackground">

         <div className="modalcategContainer">
            <div className="titlecategCloseBtn">
               <h2>Category</h2>
               <button onClick={() => { handleClose(false); }}>X</button>
            </div>

            <hr style={{ marginTop: 15, marginBottom: 15 }} />

            <div className='all_category_inside'>
               <div onClick={() => handleCategoryClick({ category: 'General Clinic' })} className='single_category'>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                     <div className='cirecle_category'>
                        <img className="category_Icon" src={Physician1} alt={"Category"} />
                     </div>

                     <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 15 }}>
                        <h4>General Clinic</h4>
                        <h5>Doctors</h5>
                     </div>
                  </div>

                  <KeyboardArrowRightIcon />
               </div>
               <hr style={{ marginTop: 3, width: '82%', display: 'flex', alignSelf: 'flex-end', marginBottom: 3 }} />


               <div onClick={() => handleCategoryClick({ category: 'Dentist' })} className='single_category'>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                     <div className='cirecle_category'>
                        <img className="category_Icon" src={oral} alt={"Category"} />
                     </div>

                     <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 15 }}>
                        <h4>Dentist</h4>
                        <h5>Doctors</h5>
                     </div>
                  </div>

                  <KeyboardArrowRightIcon />
               </div>
               <hr style={{ marginTop: 3, width: '82%', display: 'flex', alignSelf: 'flex-end', marginBottom: 3 }} />


               <div onClick={() => handleCategoryClick({ category: 'Cardiology' })} className='single_category'>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                     <div className='cirecle_category'>
                        <img className="category_Icon" src={heart} alt={"Category"} />
                     </div>

                     <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 15 }}>
                        <h4>Cardiology</h4>
                        <h5>Doctors</h5>
                     </div>
                  </div>

                  <KeyboardArrowRightIcon />
               </div>
               <hr style={{ marginTop: 3, width: '82%', display: 'flex', alignSelf: 'flex-end', marginBottom: 3 }} />


               <div onClick={() => handleCategoryClick({ category: 'Pediatric' })} className='single_category'>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                     <div className='cirecle_category'>
                        <img className="category_Icon" src={Pediatric1} alt={"Category"} />
                     </div>

                     <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 15 }}>
                        <h4>Pediatric</h4>
                        <h5>Doctors</h5>
                     </div>
                  </div>

                  <KeyboardArrowRightIcon />
               </div>
               <hr style={{ marginTop: 3, width: '82%', display: 'flex', alignSelf: 'flex-end', marginBottom: 3 }} />


               <div onClick={() => handleCategoryClick({ category: 'Dermatology' })} className='single_category'>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                     <div className='cirecle_category'>
                        <img className="category_Icon" src={Dermotoglist1} alt={"Category"} />
                     </div>

                     <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 15 }}>
                        <h4>Dermatology</h4>
                        <h5>Doctors</h5>
                     </div>
                  </div>

                  <KeyboardArrowRightIcon />
               </div>
               <hr style={{ marginTop: 3, width: '82%', display: 'flex', alignSelf: 'flex-end', marginBottom: 3 }} />


               <div onClick={() => handleCategoryClick({ category: 'Orthopedician' })} className='single_category'>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                     <div className='cirecle_category'>
                        <img className="category_Icon" src={ortho} alt={"Category"} />
                     </div>

                     <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 15 }}>
                        <h4>Orthopedician</h4>
                        <h5>Doctors</h5>
                     </div>
                  </div>

                  <KeyboardArrowRightIcon />
               </div>
               <hr style={{ marginTop: 3, width: '82%', display: 'flex', alignSelf: 'flex-end', marginBottom: 3 }} />


               <div onClick={() => handleCategoryClick({ category: 'Neurologists' })} className='single_category'>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                     <div className='cirecle_category'>
                        <img className="category_Icon" src={brain} alt={"Category"} />
                     </div>

                     <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 15 }}>
                        <h4>Neurologists</h4>
                        <h5>Doctors</h5>
                     </div>
                  </div>

                  <KeyboardArrowRightIcon />
               </div>
               <hr style={{ marginTop: 3, width: '82%', display: 'flex', alignSelf: 'flex-end', marginBottom: 3 }} />


               <div onClick={() => handleCategoryClick({ category: 'Mental Health' })} className='single_category'>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                     <div className='cirecle_category'>
                        <img className="category_Icon" src={Mental_Health} alt={"Category"} />
                     </div>

                     <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 15 }}>
                        <h4>Mental Health</h4>
                        <h5>Doctors</h5>
                     </div>
                  </div>

                  <KeyboardArrowRightIcon />
               </div>
               <hr style={{ marginTop: 3, width: '82%', display: 'flex', alignSelf: 'flex-end', marginBottom: 3 }} />


               <div onClick={() => handleCategoryClick({ category: 'Therapist' })} className='single_category'>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                     <div className='cirecle_category'>
                        <img className="category_Icon" src={terapist} alt={"Category"} />
                     </div>

                     <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 15 }}>
                        <h4>Therapist</h4>
                        <h5>Doctors</h5>
                     </div>
                  </div>

                  <KeyboardArrowRightIcon />
               </div>
               <hr style={{ marginTop: 3, width: '82%', display: 'flex', alignSelf: 'flex-end', marginBottom: 15 }} />

            </div>

         </div>

      </div>
   );
}

export default Modal;
