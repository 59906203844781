import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./Modal_cancel_appoint.css";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Connection from '../../connection';
import moment from 'moment';
import PulseLoader from "react-spinners/PulseLoader";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import CheckIcon from '@mui/icons-material/Check';

function Modal_cancel_appoint({ handleClose, handlecancelappoint, get_apointments, appointment_id, doctor_id, user_id, doctor_nam, user_nam, user_email, doctor_email, fee, time, date, fcm_token, web_fcm }) {

   const navigate = useNavigate();

   const [spinner, setspinner] = React.useState(false);
   const [role, setRole] = React.useState('');
   const [username, setUsername] = React.useState('');

   const [cancel_app, setCancel_app] = React.useState(false);

   // Cancel Dialog PopUp
   const [cancelDialog, setCancelDialog] = useState(false);
   const handleShowDialog = () => {
      toast.success('Your Appointment successfully Cancelled!');
      setCancelDialog(true);
   };
   const handleCloseDialog = () => {
      const auth = localStorage.getItem("customer");
      const parsed = JSON.parse(auth);
      const id = parsed[0].id;

      setCancelDialog(false);
      handlecancelappoint(false);

      if (role == 'doctor') {
         handleClose(false)
      }
      get_apointments(id)
   };
   /////////////


   const options = [
      'I want to change to another doctor',
      'I want to change package',
      'I dont want to consult',
      'I have recovered the disease',
      'I have found a suitable medicine',
      'I just want to cancel',
      'I dont want to tell',
      'Others',
   ];
   const [other, setOther] = React.useState(false);
   const [cancel_reason_1, setCancel_reason] = React.useState('');
   const [selectedOption, setSelectedOption] = React.useState('');
   const handleCheckboxChange = (option) => {
      if (option == 'Others') {
         setOther(true)
         setCancel_reason('')
      }
      else {
         setCancel_reason(option)
         setOther(false)
      }
      setSelectedOption(option)
   };


   useEffect(() => {
      const auth = localStorage.getItem("customer");
      const parsed = JSON.parse(auth);
      const rolee = parsed[0].role;
      const name = parsed[0].name;
      setRole(rolee)
      setUsername(name)

      // state update with new date and appointment date
      cancel_checkkk()
      ////////////////
   }, [])


   const cancel_checkkk = () => {
      console.log("date => ", date)
      console.log("time =>", time)

      const appointmentDate = new Date(date);
      const [timeValue, meridiem] = time.split(" ");
      const [hoursValue, minutesValue] = timeValue.split(":").map(Number);

      let hours = hoursValue;
      if (meridiem === "pm" && hours !== 12) {
         hours += 12;
      }

      appointmentDate.setHours(hours, minutesValue);

      const currentTime = new Date();
      const twentyFourHoursFromNow = new Date(currentTime.getTime() + 86400000);

      if (currentTime >= appointmentDate) {
         // Past appointment
         // You can cancel the appointment
         // Place your cancellation logic here
         setCancel_app(true)
      }
      else if (appointmentDate <= twentyFourHoursFromNow) {
         // Appointment within the next 24 hours
         // You can cancel the appointment
         // Place your cancellation logic here
         setCancel_app(true)
      }
      else {
         // Future appointment, more than 24 hours away
         setCancel_app(false)
      }
   };


   const cancel_appointment = async () => {
      if (cancel_app == false) {
         alert("Your appointment's time is less than 24 hours. You can cancel appointment before 24 hours.")
      }
      else {
         if (cancel_reason_1 == '') {
            alert("Please select reason for cancel appoitment.")
         }
         else {
            setspinner(true)

            let tax = 6
            const rr_amunt = Number(fee - tax).toFixed(2);

            // Date And Time For Notification Date 
            const aa = moment(new Date()).format("YYYY-MM-DD hh:mm A");
            const split = aa.split(' ')
            const date = split[0]
            const timee = split[1]
            const am_pm = split[2]
            const time = timee + "" + am_pm
            //////////////////////////////

            const cancel_reason = cancel_reason_1.replace(/\\/g, '\\\\').replace(/'/g, "\\'")
            const type = "cancel"

            let status;
            let r_amount;
            let user_name;
            let doctor_name;

            if (role == 'user') {
               status = 'pending'
               r_amount = rr_amunt
               user_name = username
               doctor_name = doctor_nam
            }
            else {
               status = 'transfered'
               r_amount = fee
               user_name = user_nam
               doctor_name = username
            }

            console.log("1 =>", user_id)
            console.log("2 =>", appointment_id)
            console.log("3 =>", cancel_reason)
            console.log("4 =>", r_amount)
            console.log("5 =>", fee)
            console.log("6 =>", doctor_id)
            console.log("7 =>", time)
            console.log("8 =>", type)
            console.log("9 =>", date)
            console.log("10 =>", user_name)
            console.log("11 =>", doctor_name)

            let result = await fetch(Connection + "cancel_appointment_1", {
               method: 'post',
               body: JSON.stringify({ user_id, appointment_id, cancel_reason, status, r_amount, fee, doctor_id, time, type, date, user_name, doctor_name }),
               headers: {
                  "Content-Type": 'application/json'
               }
            })

            const response = await result.json();
            console.log('cancel appointment', response.response);

            if (response.response == "success") {
               setspinner(false)
               handleShowDialog()
               app_notification()
               web_notification()
            }
            else {
               setspinner(false)
               alert("Something went wrong.");
            }
         };
      }
   }


   const app_notification = async () => {
      let to = fcm_token;
      console.log("To is => ", to)

      fetch('https://fcm.googleapis.com/fcm/send', {
         method: 'POST',
         headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'key= AAAAaCD4Bcc:APA91bETamRJX5KYdE6sCHUzquj_DpmfqiqH3ogXH1K1m1SH5U5_60OPWrPP5hHMyq_1pQcUN2WMWAPpCOzFDJJ4lDt6X3IDNwLuohzTB8ja3EDGM-FDTePfmCEYpFTsUoH-wguvF6Ux'//cloud server key
         },
         body: JSON.stringify({
            "to": to,
            "priority": "high",
            "notification": {
               "title": "Mas Clinicas",
               "body": username + " is cancelled appointment with you",
               "sound": 'default',
               "icon": "myicon",
            }
         }
         )
      }).then(res => res.json())
         .then(resjson => console.log("test", resjson))
         .catch(err => console.log("error =>", err))
   }


   const web_notification = async () => {
      let to = web_fcm;
      console.log("To is => ", to)

      fetch('https://fcm.googleapis.com/fcm/send', {
         method: 'POST',
         headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'key= AAAAPzWsmh8:APA91bGwGLlejpat2ndQPc3708f5KqvTPRFm9oZ7RQyBB9khWgUn_2JFZJdIf5Lta7dS2M9wTj_OYaxxmZ3FRVckZGoDJpEWyUC81G1hJliAhiKUGaZJS0P3cP-T_Z5VwGKPQzNsk_75'//cloud server key
         },
         body: JSON.stringify({
            "to": to,
            "priority": "high",
            "notification": {
               "title": "Mas Clinicas",
               "body": username + " is cancelled appointment with you",
               "sound": 'default',
               "icon": "myicon",
            }
         }
         )
      }).then(res => res.json())
         .then(resjson => console.log("test", resjson))
         .catch(err => console.log("error =>", err))
   }



   return (
      <div className="modal_cancelappointground">

         <div className="modalcancelapointContainer">
            <div className="titlecancelapointCloseBtn">
               <button onClick={() => { handlecancelappoint(false); }}>
                  <ArrowBackIcon />
               </button>
               <h3>Cancel Appointment</h3>
            </div>

            <h3 className='hspecilh3'>Reason for Cancel Appointment</h3>

            <div className='cancelcheckbox'>
               {options.map((option) => (
                  <label style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10 }} key={option}>
                     <input className={selectedOption === option ? 'custom_checkbox_active' : 'custom_checkbox_inactive'} type="checkbox" value={option} checked={selectedOption === option}
                        onChange={() => handleCheckboxChange(option)}
                     />
                     <h4>{option}</h4>
                  </label>
               ))}
            </div>

            {other == true &&
               <textarea onChange={(e) => setCancel_reason(e.target.value)} className="resaon_inpu" placeholder="Please describe your reason." ></textarea>
            }

            <div onClick={cancel_appointment} className='cancel_btn'>
               <p>Submit</p>
            </div>

            <Dialog className='cancelapoint_Dialog' open={cancelDialog}>
               <div className='done_view'>
                  <CheckIcon />
               </div>

               <DialogTitle className='Cancel_dialog_toptitl'>
                  <h4>Congratulations. Your Appointment</h4>
                  <h5> successfully Cancelled!</h5>
               </DialogTitle>

               <DialogActions className='cancelapoint_Ok_nd_Close'>
                  <Button onClick={handleCloseDialog} className='cancelapoint_Ok'>DONE</Button>
               </DialogActions>

               <ToastContainer />
            </Dialog>

            {/* {spinner == true &&
               <div className='spinner_main_loader'>
                  <div className='spinner_inside'>
                     <PulseLoader
                        color={"#874DAF"}
                        loading={spinner}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                     />
                  </div>
               </div>
            } */}
         </div>

         {spinner && (
            <div style={{ width: '100%', height: '100%', backgroundColor: 'rgba(2, 2, 2, 0.8)', position: 'absolute', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
               <div style={{ display: 'flex', width: '30%', backgroundColor: 'white', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', padding: '30px', boxShadow: '0 1px 5px rgba(0, 0, 0, 0.2)', borderRadius: '6px', }}>
                  <PulseLoader
                     color={"#874DAF"}
                     loading={spinner}
                     aria-label="Loading Spinner"
                     data-testid="loader"
                  />
                  <p style={{ fontSize: '16px', color: '#874DAF', fontWeight: 'bold', marginLeft: '15px' }}>
                     Progressing your request
                  </p>
               </div>
            </div>
         )}
      </div>
   );
}

export default Modal_cancel_appoint;
