import React, { useState, useEffect } from 'react'
import './Doctor_All_Appointment.css';
import { Link, useNavigate } from 'react-router-dom';
import Connection from "../../connection";
import Image_connection from "../../Image_connection";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import Doctor_site_appointment_detail from '../../MyComponent/Doctor_site_appointment_detail/Doctor_site_appointment_detail';
import Drawer from 'react-drawer';
import 'react-drawer/lib/react-drawer.css';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import VideocamIcon from '@mui/icons-material/Videocam';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';

export default function Doctor_All_Appointment() {
  const navigate = useNavigate();

  const [role, setRole] = React.useState('');

  const [skalton, setSkalton] = React.useState(true);
  const [upcom_arr, setUpcom_arr] = useState([]);
  const [past_arr, setPast_arr] = useState([]);

  const [show, setShow] = useState(false);

  const [payment_method, setPayment_method] = React.useState('');
  const [user_name, setUser_name] = React.useState('');
  const [date, setDate] = React.useState('');
  const [day, setDay] = React.useState('');
  const [time, setTime] = React.useState('');
  const [user_profile, setUser_profile] = React.useState('');
  const [user_address, setUser_address] = React.useState('');
  const [user_email, setUser_email] = React.useState('');
  const [user_mobile, setUser_mobile] = React.useState('');
  const [user_id, setUser_id] = React.useState('')
  const [appointment_id, setAppointment_id] = React.useState('');
  const [fee, setFee] = React.useState('');
  const [user_fcm_token, setUser_fcm_token] = React.useState('');
  const [user_web_fcm, setUser_web_fcm] = React.useState('');
  const [status, setStatus] = React.useState('');
  const [active, setActive] = React.useState('');
  const [device_token, setDevice_token] = React.useState('');
  const [user_gender, setUser_gender] = React.useState('');
  const [category, setCategory] = React.useState('');
  const [app_type, setApp_type] = React.useState('');
  const [user_type, setUser_type] = React.useState('');
  const [doctor_name, setDoctor_name] = React.useState('');
  const [u_user_id, setU_user_id] = React.useState('');



  // Change Btn
  const [text4, setText4] = React.useState(2);
  const [text5, setText5] = React.useState(1);
  const [check_first_design, setCheck_first_design] = React.useState('Upcoming');
  const changebt1 = (text, val) => {
    setText4(1);
    setText5(1);

    if (text === "text4") {
      setText4(2);
    }
    else if (text === "text5") {
      setText5(2);
    }

    setCheck_first_design(val)
  }
  /////////////////////////



  // Change Btn
  const [text1, setText1] = React.useState(2);
  const [text2, setText2] = React.useState(1);
  const [text3, setText3] = React.useState(1);
  const [check_design, setCheck_design] = React.useState('All');
  const [all_arr, setAll_arr] = React.useState([]);
  const [online_arr, setOnline_arr] = React.useState([]);
  const [offline_arr, setOffline_arr] = React.useState([]);

  const changebtn2 = (text, val) => {
    setText1(1);
    setText2(1);
    setText3(1);

    if (text === "text1") {
      setText1(2);
    }
    else if (text === "text2") {
      setText2(2);
    }
    else if (text === "text3") {
      setText3(2);
    }

    setCheck_design(val)

    if (val == "All") {
      setUpcom_arr(all_arr)
    }
    else if (val == "Online Appointment") {
      setUpcom_arr(online_arr)
    }
    else if (val == "Offline Appointment") {
      setUpcom_arr(offline_arr)
    }
  }
  /////////////////////////



  // Change Btn
  const [text6, setText6] = React.useState(2);
  const [text7, setText7] = React.useState(1);
  const [text8, setText8] = React.useState(1);
  const [check_second_design, setCheck_second_design] = React.useState('All');
  const [pst_all_arr, setPst_all_arr] = React.useState([]);
  const [pst_online_arr, setPst_online_arr] = React.useState([]);
  const [pst_offline_arr, setPst_offline_arr] = React.useState([]);

  const changebt3 = (text, val) => {
    setText6(1);
    setText7(1);
    setText8(1);

    if (text === "text6") {
      setText6(2);
    }
    else if (text === "text7") {
      setText7(2);
    }
    else if (text === "text8") {
      setText8(2);
    }

    setCheck_second_design(val)

    if (val == "All") {
      setPast_arr(pst_all_arr)
    }
    else if (val == "Online Appointment") {
      setPast_arr(pst_online_arr)
    }
    else if (val == "Offline Appointment") {
      setPast_arr(pst_offline_arr)
    }
  }
  /////////////////////////

  useEffect(() => {
    window.scrollTo(0, 0);

    const auth = localStorage.getItem("customer");
    const parsed = JSON.parse(auth);
    const id = parsed[0].id;
    const roole = parsed[0].role;
    setRole(roole)

    getProducts(id)
  }, [])

  const getProducts = async (id) => {
    let result = await fetch(Connection + `requested_appointments_to_doctor_all_p/${id}`, {

    })
    result = await result.json()
    const record4 = result.response;
    console.warn(record4)

    if (record4) {
      const currentDate = new Date();

      const upcomingArr = [];
      const pastAppArr = [];

      record4.forEach((appointment) => {
        const dateString = appointment.date;
        const timeString = appointment.time;

        if (dateString || timeString) {
          const dateObject = new Date(dateString);
          const [timeHours, timeMinutes] = timeString.split(':');

          let hours = parseInt(timeHours);
          const minutes = parseInt(timeMinutes);

          const isPM = timeString.toLowerCase().includes('pm');
          if (isPM && hours !== 12) {
            hours += 12;
          } else if (!isPM && hours === 12) {
            hours = 0;
          }

          dateObject.setHours(hours, minutes, 0, 0);
          const isUpcoming = dateObject.getTime() > currentDate.getTime();

          console.log('Current Date:', currentDate);
          console.log('Date to Check:', dateObject);
          console.log('Is Upcoming:', isUpcoming);

          if (isUpcoming) {
            upcomingArr.push(appointment);
          } else {
            pastAppArr.push(appointment);
          }
        }
      });

      setUpcom_arr(upcomingArr.filter((record) => record.u_user_id !== null && record.status !== 'wait_for_pay' && record.status !== 'request'));
      setAll_arr(upcomingArr.filter((record) => record.u_user_id !== null && record.status !== 'wait_for_pay' && record.status !== 'request'));
      setOnline_arr(upcomingArr.filter((record) => record.type === 'online' && record.u_user_id !== null && record.status !== 'wait_for_pay' && record.status !== 'request'));
      setOffline_arr(upcomingArr.filter((record) => record.type === 'offline' && record.u_user_id !== null && record.status !== 'wait_for_pay' && record.status !== 'request'));


      setPast_arr(pastAppArr.filter((record) => record.u_user_id !== null && record.status !== 'wait_for_pay' && record.status !== 'request'));
      setPst_all_arr(pastAppArr.filter((record) => record.u_user_id !== null && record.status !== 'wait_for_pay' && record.status !== 'request'));
      setPst_online_arr(pastAppArr.filter((record) => record.type === 'online' && record.u_user_id !== null && record.status !== 'wait_for_pay' && record.status !== 'request'));
      setPst_offline_arr(pastAppArr.filter((record) => record.type === 'offline' && record.u_user_id !== null && record.status !== 'wait_for_pay' && record.status !== 'request'));

      setSkalton(false);
    }
    else {
      setSkalton(false);
      setUpcom_arr([]);
      setPast_arr([]);
    }
  }


  const handleShow = (payment_method, user_name, date, day, time, user_profile, user_address, user_email, user_mobile, user_id, id, fee, user_fcm_token, user_web_fcm, status, active, device_token, user_gender, category, type, user_type, doctor_name, u_user_id) => {
    document.body.style.overflow = 'hidden';

    setPayment_method(payment_method)
    setUser_name(user_name)
    setDate(date)
    setDay(day)
    setTime(time)
    setUser_profile(user_profile)
    setUser_address(user_address)
    setUser_email(user_email)
    setUser_mobile(user_mobile)
    setUser_id(user_id)
    setAppointment_id(id)
    setFee(fee)
    setUser_fcm_token(user_fcm_token)
    setUser_web_fcm(user_web_fcm)
    setStatus(status)
    setActive(active)
    setDevice_token(device_token)
    setUser_gender(user_gender)
    setCategory(category)
    setApp_type(type)
    setUser_type(user_type)
    setDoctor_name(doctor_name)
    setU_user_id(u_user_id)

    setShow(true);
  };

  const handleClose = () => {
    document.body.style.overflow = 'auto';
    setShow(false);
  };

  const agora_notification = async (user_fcm_token, user_web_fcm) => {
    console.log("fcm_token", user_fcm_token)
    console.log("user_web_fcm", user_web_fcm)
    console.log("role", role)

    if ((user_fcm_token === null || user_fcm_token === '') && (user_web_fcm === null || user_web_fcm === '')) {
      alert("This user is currently unavailable.");
    }
    else {
      const call_check = 'do_call'
      const url = `/AgoraCall?call_check=${call_check}&fcm_token=${user_fcm_token}&web_fcm=${user_web_fcm}`;
      navigate(url);
    }
  };


  const chat_romm = async (user_profile, user_name, user_id, user_fcm_token, user_web_fcm, active, doctor_name) => {
    const check_chat = 'do_chat'
    const userprofile = Image_connection + user_profile
    const url = `/Chat?check_chat=${check_chat}&receiver_image=${user_profile}&chat_image=${user_profile}&chat_name=${user_name}&receiver_id=${user_id}&fcm_token=${user_fcm_token}&web_fcm=${user_web_fcm}&active=${active}&sender_name=${doctor_name}&receiver_name=${user_name}&chat_name_disable=${user_name}`;
    navigate(url);
  }



  return (

    <div className='appointment_startup'>

      <div className="buttonContainer">
        <button className={text4 === 1 ? 'inactiveButton' : 'activeButton'} onClick={() => changebt1('text4', 'Upcoming')}>
          Upcoming
        </button>

        <button className={text5 === 1 ? 'inactiveButton' : 'activeButton'} onClick={() => changebt1('text5', 'Past')}>
          History
        </button>
      </div>

      {check_first_design == 'Upcoming' &&
        <>
          {/* <div className='viewofchange_btn'>
            <div className={text1 === 1 ? "in_active_button" : "active_button"} onClick={() => changebtn2("text1", 'All')}>
              <h4>All</h4>
            </div>

            <div className={text2 === 1 ? "in_active_button" : "active_button"} onClick={() => changebtn2("text2", 'Online Appointment')}>
              <h4>Online Appointment</h4>
            </div>

            <div className={text3 === 1 ? "in_active_button" : "active_button"} onClick={() => changebtn2("text3", 'Offline Appointment')}>
              <h4>Clinic Appointment</h4>
            </div>
          </div> */}

          {skalton == true ?
            <SkeletonTheme baseColor="lightgray" highlightColor="#bebebe">
              <div className='skelton_appoint_div'>
                <Skeleton count={2} className='sklnappointheight' />
              </div>
            </SkeletonTheme>
            :
            <>
              {upcom_arr != '' ?
                <>
                  {upcom_arr.map((row) => {
                    const appointment_id = row.id;
                    return (
                      <div className="one_appointment_card" key={appointment_id}>
                        <div style={{ display: 'flex', flexDirection: 'row', paddingHorizontal: 15, alignItems: 'center', justifyContent: 'space-between' }}>
                          <p className='docsitnamfist'>Dr. {row.doctor_name} </p>

                          <button style={{ backgroundColor: 'white', border: 'none' }}
                            onClick={() => handleShow(
                              row.payment_method,
                              row.user_name,
                              row.date,
                              row.day,
                              row.time,
                              row.user_profile,
                              row.user_address,
                              row.user_email,
                              row.user_mobile,
                              row.user_id,
                              row.id,
                              row.fee,
                              row.user_fcm_token,
                              row.user_web_fcm,
                              row.status,
                              row.active,
                              row.device_token,
                              row.user_gender,
                              row.category,
                              row.type,
                              row.user_type,
                              row.doctor_name,
                              row.u_user_id,
                            )}>
                            <p className='botnkatext'>More Details</p>
                          </button>
                        </div>

                        <div className='dockaimgkasect'>
                          <div className='pahalimg'>
                            {row.user_profile != null ?
                              <img
                                className='oknowimgin'
                                src={Image_connection + row.user_profile}
                                alt="Profile"
                              />
                              :
                              <img
                                className='oknowimgin'
                                src={require('../../assets/empty.png')}
                                alt="Empty"
                              />
                            }
                          </div>

                          <div className='akriinimg'>
                            <p id='nownmofusr'>{row.user_name}</p>
                            <p id='nowadofusr'>{row.user_address}</p>
                          </div>
                        </div>

                        <h4 className='opendatetimemix'><CalendarMonthIcon /> {row.time} | {row.date}</h4>

                        <div className='Paid_progress'>
                          {row.user_type == 'hisd' ?
                            <h4>(FREE HISD)</h4>
                            :
                            <>
                              {row.type == 'offline' ?
                                <h4>(Clinic Appointment)</h4>
                                :
                                <h4>(Paid)</h4>
                              }
                            </>
                          }

                          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                            <div className='doted_sta'></div>
                            {row.status == 'pending' &&
                              <h4>In-Process</h4>
                            }
                            {row.status == 'complete' &&
                              <h4>Showed-up</h4>
                            }
                            {row.status == 'cancel' &&
                              <h4>Cancelled</h4>
                            }
                            {row.status == 'request' &&
                              <h4>Requested</h4>
                            }
                          </div>
                        </div>

                        {row.type !== 'offline' && (
                          <>
                            {row.status === 'cancel' || row.status === 'complete' || row.status === 'request' ? (
                              <div style={{ flexDirection: 'row', alignItems: 'center' }}></div>
                            ) : (
                              <div className='lasttwobtndoc'>
                                <div className='fistcallbutn'>
                                  <button onClick={() => agora_notification(row.user_fcm_token, row.user_web_fcm)} className='maincallbutn'>
                                    <VideocamIcon className='textsizcalicn' />
                                  </button>
                                </div>

                                <button onClick={() => chat_romm(row.user_profile, row.user_name, row.user_id, row.user_fcm_token, row.user_web_fcm, row.active, row.doctor_name)}
                                  className='secndchtbutn'>
                                  <QuestionAnswerIcon className='iconsizcht' />
                                  <span className='sizofchattxt'>Chat with Patient</span>
                                </button>
                              </div>
                            )}
                          </>
                        )}

                        {row.payment_method == 'Insurance' && <p style={{ color: '#00c480', fontSize: 14, fontWeight: 'bold', paddingLeft: 5, marginTop: 5 }}>Insurance Appointment</p>}
                      </div>
                    )
                  })}
                </>
                :
                <div style={{ width: '100%', height: '60vh', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                  <p>No Record Found</p>
                </div>
              }
            </>
          }
        </>
      }




      {check_first_design == 'Past' &&
        <>
          {/* <div className='viewofchange_btn'>
            <div className={text6 === 1 ? "in_active_button" : "active_button"} onClick={() => changebt3("text6", 'All')}>
              <h4>All</h4>
            </div>

            <div className={text7 === 1 ? "in_active_button" : "active_button"} onClick={() => changebt3("text7", 'Online Appointment')}>
              <h4>Online Appointment</h4>
            </div>

            <div className={text8 === 1 ? "in_active_button" : "active_button"} onClick={() => changebt3("text8", 'Offline Appointment')}>
              <h4>Clinic Appointment</h4>
            </div>
          </div> */}

          {skalton == true ?
            <SkeletonTheme baseColor="lightgray" highlightColor="#bebebe">
              <div className='skelton_appoint_div'>
                <Skeleton count={2} className='sklnappointheight' />
              </div>
            </SkeletonTheme>
            :
            <>
              {past_arr != '' ?
                <>
                  {past_arr.map((row) => {
                    const appointment_id = row.id;
                    return (
                      <div className="one_appointment_card" key={appointment_id}>
                        <div style={{ display: 'flex', flexDirection: 'row', paddingHorizontal: 15, alignItems: 'center', justifyContent: 'space-between' }}>
                          <p className='docsitnamfist'>Dr. {row.doctor_name} </p>

                          <button style={{ backgroundColor: 'white', border: 'none' }}
                            onClick={() => handleShow(
                              row.payment_method,
                              row.user_name,
                              row.date,
                              row.day,
                              row.time,
                              row.user_profile,
                              row.user_address,
                              row.user_email,
                              row.user_mobile,
                              row.user_id,
                              row.id,
                              row.fee,
                              row.user_fcm_token,
                              row.user_web_fcm,
                              row.status,
                              row.active,
                              row.device_token,
                              row.user_gender,
                              row.category,
                              row.type,
                              row.user_type,
                              row.doctor_name,
                              row.u_user_id,
                            )}>
                            <p className='botnkatext'>More Details</p>
                          </button>
                        </div>

                        <div className='dockaimgkasect'>
                          <div className='pahalimg'>
                            {row.user_profile != null ?
                              <img
                                className='oknowimgin'
                                src={Image_connection + row.user_profile}
                                alt="Profile"
                              />
                              :
                              <img
                                className='oknowimgin'
                                src={require('../../assets/empty.png')}
                                alt="Empty"
                              />
                            }
                          </div>

                          <div className='akriinimg'>
                            <p id='nownmofusr'>{row.user_name}</p>
                            <p id='nowadofusr'>{row.user_address}</p>
                          </div>
                        </div>

                        <h4 className='opendatetimemix'><CalendarMonthIcon /> {row.time} | {row.date}</h4>

                        <div className='Paid_progress'>
                          {row.user_type == 'hisd' ?
                            <h4>(FREE HISD)</h4>
                            :
                            <>
                              {row.type == 'offline' ?
                                <h4>(Clinic Appointment)</h4>
                                :
                                <h4>(Paid)</h4>
                              }
                            </>
                          }

                          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                            <div className='doted_sta'></div>
                            {row.status == 'pending' &&
                              <h4>In-Process</h4>
                            }
                            {row.status == 'complete' &&
                              <h4>Showed-up</h4>
                            }
                            {row.status == 'cancel' &&
                              <h4>Cancelled</h4>
                            }
                            {row.status == 'request' &&
                              <h4>Requested</h4>
                            }
                          </div>
                        </div>

                        {row.type !== 'offline' && (
                          <>
                            {row.status === 'cancel' || row.status === 'complete' || row.status === 'request' ? (
                              <div style={{ flexDirection: 'row', alignItems: 'center' }}></div>
                            ) : (
                              <div className='lasttwobtndoc'>
                                <div className='fistcallbutn'>
                                  <button onClick={() => agora_notification(row.user_fcm_token, row.user_web_fcm)} className='maincallbutn'>
                                    <VideocamIcon className='textsizcalicn' />
                                  </button>
                                </div>

                                <button onClick={() => chat_romm(row.user_profile, row.user_name, row.user_id, row.user_fcm_token, row.user_web_fcm, row.active, row.doctor_name)}
                                  className='secndchtbutn'>
                                  <QuestionAnswerIcon className='iconsizcht' />
                                  <span className='sizofchattxt'>Chat with Patient</span>
                                </button>
                              </div>
                            )}
                          </>
                        )}

                        {row.payment_method == 'Insurance' && <p style={{ color: '#00c480', fontSize: 14, fontWeight: 'bold', paddingLeft: 5, marginTop: 5 }}>Insurance Appointment</p>}
                      </div>
                    )
                  })}
                </>
                :
                <div style={{ width: '100%', height: '60vh', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                  <p>No Record Found</p>
                </div>
              }
            </>
          }
        </>
      }




      {show == true &&
        <Doctor_site_appointment_detail show={show} handleClose={handleClose} getProducts={getProducts} payment_method={payment_method} user_name={user_name} date={date} day={day} time={time} user_profile={user_profile} user_address={user_address} user_email={user_email} user_mobile={user_mobile} user_id={user_id} appointment_id={appointment_id} fee={fee} user_fcm_token={user_fcm_token} user_web_fcm={user_web_fcm} status={status} active={active} device_token={device_token} user_gender={user_gender} category={category} app_type={app_type} user_type={user_type} doctor_name={doctor_name} u_user_id={u_user_id}>
          <p>Modal Content Goes Here</p>
        </Doctor_site_appointment_detail>
      }

    </div>
  );
}