import React, { useState, useEffect } from 'react'
import Connection from "../../connection";
import FadeLoader from "react-spinners/FadeLoader";
import Add_payment from '../../MyComponent/Add_payment/Add_payment';

export default function Patient_Wallet() {

    const [skalton, setSkalton] = React.useState(true);
    const [user_data, setUser_data] = useState([]);
    const [app_data, setApp_data] = useState([]);

    const [balance, setBalance] = React.useState(0);
    const [user_id, setUser_id] = React.useState('');

    const [show, setShow] = useState(false);

    // Change Btn
    const [text1, setText1] = useState(2);
    const [text2, setText2] = useState(1);
    const [checkDesign, setCheckDesign] = useState('wallet');

    const changeBtn = (text, val) => {
        setText1(1);
        setText2(1);

        if (text === "text1") {
            setText1(2);
        }
        else if (text === "text2") {
            setText2(2);
        }

        setCheckDesign(val);
    };
    /////////////////////////



    useEffect(() => {
        window.scrollTo(0, 0);

        const auth = localStorage.getItem("customer");
        const parsed = JSON.parse(auth);
        const id = parsed[0].id;

        let balnce = parsed[0].balance;
        if (balnce == null) {
            setBalance(0)
        }
        else {
            let balance_1 = Number(balnce).toFixed(2);
            setBalance(balance_1)
        }

        setUser_id(id)
        getProducts(id)
    }, [])

    const getProducts = async (id) => {
        let result = await fetch(Connection + "Display_User_Wallet_a", {
            method: 'post',
            body: JSON.stringify({ id }),
            headers: {
                "Content-Type": 'application/json'
            }
        })
        result = await result.json()
        const record4 = result.response
        console.log("Data user wallet =>", record4.data)
        console.log("Data app wallet =>", record4.data1)

        if (record4) {
            setUser_data(record4.data)
            setApp_data(record4.data1)

            setSkalton(false);
        }
        else {
            setSkalton(false);
        }
    }


    const handleShows = () => {
        document.body.style.overflow = 'hidden';
        setShow(true);
    };

    const handleClose = () => {
        document.body.style.overflow = 'auto';
        setShow(false);
    };


    return (

        <div className='appointment_startup'>

            <div className='consult_toptwo_tutton'>
                <button onClick={() => changeBtn('text1', 'wallet')} className={text1 === 1 ? 'inactiveProcess' : 'activeProcess'}>
                    <span className={text1 === 1 ? 'inprocesstext' : 'processtext'}>{'Wallet'}</span>
                </button>

                <button onClick={() => changeBtn('text2', 'Appointment')} className={text2 === 1 ? 'inactiveProcess' : 'activeProcess'}>
                    <span className={text2 === 1 ? 'inprocesstext' : 'processtext'}>{'Transactions'}</span>
                </button>
            </div>

            <div className='mainofbalanndhistry'>
                <div className='availablebalance'>
                    <div>
                        <p className='avalletext'>Available balance</p>
                        <p className='balanctext'>${balance}</p>
                    </div>

                    <button onClick={() => handleShows()} className='payoutbutton'>Add Money</button>
                </div>
            </div>


            {skalton == true ?
                <div className='loadingfordataload'>
                    <FadeLoader
                        color={"#874DAF"}
                        loading={skalton}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                </div>
                :
                <div className='mainofwallet'>
                    {checkDesign == 'wallet' ?
                        <>
                            {user_data != '' ?
                                <>
                                    {user_data.map((row) => {
                                        let ss = row.date.split(" ");
                                        let date_1 = ss[0];
                                        let time_1 = ss[1];

                                        let xx = date_1;
                                        let qq = new Date(xx).toString();
                                        let ddd = qq.split(" ");
                                        let dd_1 = ddd[1];
                                        let dd_2 = ddd[2];
                                        let dd_3 = ddd[3];
                                        let final_date = dd_1 + " " + dd_2 + " " + dd_3;

                                        return (
                                            <div key={row.id} className='mainpayouts'>
                                                <div className='payoutinsidesingle'>
                                                    <span style={{ color: 'black', fontSize: 14 }}>Transaction Id : {row.id}</span>
                                                    <span style={{ color: 'gray', fontSize: 12 }}>{final_date}</span>
                                                </div>

                                                <div className='payoutinsidesingle'>
                                                    {row.method == 'Referral Program' ?
                                                        <span style={{ color: 'gray', fontSize: 12 }}>{row.method} payment</span>
                                                        :
                                                        <span style={{ color: 'gray', fontSize: 12 }}>Wallet Payment</span>
                                                    }

                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                        <span style={{ color: 'black', fontSize: 14 }}>${row.amount}</span>
                                                        <i className="fas fa-money-bill-wave" style={{ color: '#24AE34', fontSize: 14, marginLeft: 4 }}></i>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </>
                                :
                                <div style={{ width: '100%', height: '60vh', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                    <p>No Record Found</p>
                                </div>
                            }
                        </>
                        :
                        <>
                            {app_data != '' ?
                                <>
                                    {app_data.map((row) => {
                                        let ss = row.date.split(" ");
                                        let date_1 = ss[0];
                                        let time_1 = ss[1];

                                        let xx = date_1;
                                        let qq = new Date(xx).toString();
                                        let ddd = qq.split(" ");
                                        let dd_1 = ddd[1];
                                        let dd_2 = ddd[2];
                                        let dd_3 = ddd[3];
                                        let final_date = dd_1 + " " + dd_2 + " " + dd_3;

                                        let total = parseInt(row.payment - row.profit).toString();

                                        return (
                                            <div key={row.appointment_id} className='mainwallet'>
                                                <div className='insidewalletsingle'>
                                                    {row.appointment_id !== null ? (
                                                        <span style={{ color: 'black', fontSize: 14 }}>Appointment ID : {row.appointment_id}</span>
                                                    ) : (
                                                        <span style={{ color: 'black', fontSize: 14 }}>Consultation ID : {row.meeting_id}</span>
                                                    )}
                                                    <span style={{ color: 'gray', fontSize: 12 }}>{final_date}</span>
                                                </div>

                                                <div className='insidewalletsingle'>
                                                    <span style={{ color: 'gray', fontSize: 12 }}>{row.payment_method} payment</span>
                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 3 }}>
                                                        <span style={{ color: 'black', fontSize: 14 }}>${row.payment}</span>
                                                        <i className="fas fa-money-bill-wave" style={{ color: '#24AE34', fontSize: 14 }}></i>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </>
                                :
                                <div style={{ width: '100%', height: '60vh', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                    <p>No Record Found</p>
                                </div>
                            }
                        </>
                    }
                </div>
            }

            {show == true &&
                <Add_payment show={show} handleClose={handleClose}>
                    <p>Modal Content Goes Here</p>
                </Add_payment>
            }
        </div>
    );
}