import React, { useState, useEffect, useRef } from 'react'
import "./Modal_Payment.css";
import 'react-toastify/dist/ReactToastify.css';
// import 'react-credit-cards/es/styles-compiled.css';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Modal_payment_done from '../Modal_payment_done/Modal_payment_done';

import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";

function Modal_Payment({ showpayment, handleClose, wallet, handlepaymentClose, day, date, time, appointment_price, category, doctor_name, city, doctor_id, reason, symptoms, issue, allergic, promo_end, method, email, fcm_token, web_fcm, type_of, usertype, appointment_id, callfunction, insuranceoayment }) {

    const [user_name, setUser_name] = React.useState('');
    const [spinner, setSpinner] = useState(false);
    const [showpayment_done, setShowpayment_done] = useState(false);

    useEffect(() => {
        // User ID From LocalStorage
        const auth = localStorage.getItem("customer");
        const parsed = JSON.parse(auth);
        const user_name = parsed[0].name;
        setUser_name(user_name)
        /////////////
    }, [])

    const [stripePromise, setStripePromise] = useState(null);
    const [clientSecret, setClientSecret] = useState("");

    useEffect(() => {
        fetch("https://admin.masclinicas.com/config").then(async (r) => {
            const { publishableKey } = await r.json();
            setStripePromise(loadStripe(publishableKey));
        });
    }, []);

    useEffect(() => {
        const amount = appointment_price * 100; // Set the desired amount
        fetch(`https://admin.masclinicas.com/create-payment-intent?amount=${amount}`, {
            method: "POST",
        })
            .then(async (result) => {
                var { clientSecret } = await result.json();
                setClientSecret(clientSecret);
            })
            .catch((error) => {
                // Handle any errors here
                console.error("Error fetching payment intent:", error);
            });
    }, []);


    const handlepaymentdoneOPEN = () => {
        setShowpayment_done(true);
    };

    const handlepaymentdoneClose = () => {
        setShowpayment_done(false);
    };


    return (
        <div className="modal_paymentBackground">

            <div className='modalpaymentContainer'>
                <div className="headerpaymentCloseBtn">
                    <button onClick={() => { handlepaymentClose(false); }}>
                        <ArrowBackIosNewIcon className='iconnn' />
                    </button>
                    <h3>Card Payment</h3>
                </div>


                {clientSecret && stripePromise && (
                    <Elements stripe={stripePromise} options={{ clientSecret }}>
                        <CheckoutForm handlepaymentdoneOPEN={handlepaymentdoneOPEN} appointment_id={appointment_id} handleClose={handleClose} callfunction={callfunction} insuranceoayment={insuranceoayment} />
                    </Elements>
                )}
            </div>


            {showpayment_done &&
                <Modal_payment_done showpayment_done={true} handleClose={handleClose} wallet={wallet} handlepaymentdoneClose={handlepaymentdoneClose} day={day} date={date} time={time} appointment_price={appointment_price} category={category} doctor_name={doctor_name} city={city} doctor_id={doctor_id} reason={reason} symptoms={symptoms} issue={issue} allergic={allergic} promo_end={promo_end} method={method} email={email} fcm_token={fcm_token} web_fcm={web_fcm} type_of={type_of} usertype={usertype}>
                    <p>Modal Appointment Done Goes Here</p>
                </Modal_payment_done>
            }

        </div>
    );
}

export default Modal_Payment;