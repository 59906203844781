import React, { useState, useEffect, useContext } from 'react'
import './Doctor_Home.css';
import { Link, useNavigate } from 'react-router-dom';
import Connection from "../../connection";
import Image_connection from "../../Image_connection";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import Switch from 'react-switch';
import { GlobalInfo } from "../../App"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PulseLoader from "react-spinners/PulseLoader";
import Doctor_site_appointment_detail from '../../MyComponent/Doctor_site_appointment_detail/Doctor_site_appointment_detail';
import Add_schedulling from '../../MyComponent/Add_schedulling/Add_schedulling';
import { firebaseApp, messaging } from '../../firebase';
import { getToken } from 'firebase/messaging';

import CheckIcon from '@mui/icons-material/Check';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import mass_1 from '../../assets/mass_1.png';
import playstore_icon from '../../assets/playstore_icon.png';
import Appstore_icon from '../../assets/Appstore_icon.png';
import mobile_screen_shot from '../../assets/mobile_screen_shot.png';

const Doctor_Home = (props) => {
    const { do_free_hisd, do_status } = useContext(GlobalInfo)
    const navigate = useNavigate();

    const [show, setShow] = useState(false);
    const [ad_sche, setAd_sche] = useState(false);

    const [data3, setData3] = useState([]);
    const [data2, setData2] = useState([]);
    const [data1, setData1] = useState([]);
    const [skalton, setSkalton] = useState(true);
    const [skalto1, setSkalto1] = useState(true);
    const [spinner, setSpinner] = useState(false);

    const [isOn2, setIsOn2] = useState(false);

    const [user_name, setUser_name] = React.useState('');
    const [id, setId] = React.useState('');
    const [consult_now, setConsult_now] = React.useState(false);


    const [payment_method, setPayment_method] = React.useState('');
    const [date, setDate] = React.useState('');
    const [day, setDay] = React.useState('');
    const [time, setTime] = React.useState('');
    const [user_profile, setUser_profile] = React.useState('');
    const [user_address, setUser_address] = React.useState('');
    const [user_email, setUser_email] = React.useState('');
    const [user_mobile, setUser_mobile] = React.useState('');
    const [user_id, setUser_id] = React.useState('')
    const [appointment_id, setAppointment_id] = React.useState('');
    const [fee, setFee] = React.useState('');
    const [user_fcm_token, setUser_fcm_token] = React.useState('');
    const [user_web_fcm, setUser_web_fcm] = React.useState('');
    const [status, setStatus] = React.useState('');
    const [active, setActive] = React.useState('');
    const [device_token, setDevice_token] = React.useState('');
    const [user_gender, setUser_gender] = React.useState('');
    const [category, setCategory] = React.useState('');
    const [app_type, setApp_type] = React.useState('');
    const [user_type, setUser_type] = React.useState('');
    const [doctor_name, setDoctor_name] = React.useState('');
    const [u_user_id, setU_user_id] = React.useState('');


    useEffect(() => {
        Notification.requestPermission().then(permission => {
            if (permission === 'granted') {
                getToken(messaging, { vapidKey: "BHwo7XkJcDLDE1g1t-ZLtjVXqlmSn12QX2qihLtgIM1COE1GqOi4US04hjv25md38FC0dSy-2C_bFFbov-cV8D4" }).then(currentToken => {
                    const austh = localStorage.getItem("customer");
                    const parsed = JSON.parse(auth);
                    const id = parsed[0].id;
                    Update_fcmtoken(id, currentToken)
                });
            }
        });

        // User ID From LocalStorage
        const auth = localStorage.getItem("customer");
        const parsed = JSON.parse(auth);
        const user_name = parsed[0].name;
        const id = parsed[0].id;
        const consul_now = parsed[0].consult_now;
        const available = parsed[0].available;

        setId(id)
        setUser_name(user_name)


        if (consul_now == null || consul_now == 'Off') {
            setConsult_now(false)
        }
        else {
            setConsult_now(true)


            if (available == null || available == 'false') {
                setIsOn2(false)
            }
            else {
                setIsOn2(true)
            }
        }
        /////////////

        setTimeout(() => {
            check_doctor_sheduling(id)
        }, 100);
        get_pending_appointments_Doctor(id)
        display_appointments_doctor_all(id)
    }, [])


    const Update_fcmtoken = async (id, fcm_token) => {
        let result = await fetch(Connection + "update_both_token", {
            method: 'post',
            body: JSON.stringify({ id, fcm_token }),
            headers: {
                "Content-Type": 'application/json'
            }
        })

        result = await result.json()
        let record = result.response

        if (record === 'Update successful') {
            console.log("Update successful");
        }
        else {
            console.log("Update failed");
        }
    };


    const check_doctor_sheduling = async (id) => {
        const doctor_id = id
        let result = await fetch(Connection + "check_doctor_sheduling", {
            method: 'post',
            body: JSON.stringify({ doctor_id }),
            headers: {
                "Content-Type": 'application/json'
            }
        })
        result = await result.json()
        const record4 = result.response
        console.log(".,.,.,.,.", record4)

        if (record4 == 'fail') {
            document.body.style.overflow = 'hidden';
            setAd_sche(true)
        }
        else { }
    };


    // For Scroll bottom to the top
    const [showScrollButton, setShowScrollButton] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const shouldShowButton = scrollTop > 200;
            setShowScrollButton(shouldShowButton);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    /////////////////////////////

    const goonlinkapp = (link) => {
        window.open(link, '_blank');
    }


    const toggleSwitch = (checked) => {
        setIsOn2(checked);
        update_consult_now_availbility(checked);
    };

    const update_consult_now_availbility = async (val) => {
        const doctor_id = id
        const avilbility = val;

        let result = await fetch(Connection + "update_consult_now_availbility", {
            method: 'post',
            body: JSON.stringify({ doctor_id, avilbility }),
            headers: {
                "Content-Type": 'application/json'
            }
        })
        const response = await result.json();
        console.log('result123', response);

        if (response && response.response) {
            let available = response.response[0].available;

            const user = localStorage.getItem("customer");
            const parsed = JSON.parse(user);
            parsed[0].available = available;

            localStorage.setItem("customer", JSON.stringify(parsed))

            if (available == null || available == 'false') {
                setIsOn2(false)
            }
            else {
                setIsOn2(true)
            }
        }
    };


    const get_pending_appointments_Doctor = async (id) => {
        try {
            let result = await fetch(Connection + `requested_appointments_to_doctor_all_p/${id}`, {})
            const response = await result.json();
            // console.log('appointment', response);

            if (response && response.response) {
                const currentDate = new Date();
                let pending = "pending"
                let wait_for_pay = "wait_for_pay"

                const recordreq = response.response.filter(record4 => record4.status === pending && record4.type != 'urgent' && record4.u_user_id !== null);
                const recordpay = response.response.filter(record4 => record4.status === wait_for_pay && record4.u_user_id !== null);

                setData2(recordreq);
                setData3(recordpay);

                // const upcomingAppointments = response.response.filter((record) => {
                //     const dateObject = new Date(record.date);
                //     const [timeHours, timeMinutes] = record.time.split(':');
                //     let hours = parseInt(timeHours);
                //     const minutes = parseInt(timeMinutes);

                //     const isPM = record.time.toLowerCase().includes('pm');
                //     if (isPM && hours !== 12) {
                //         hours += 12;
                //     }
                //     else if (!isPM && hours === 12) {
                //         hours = 0;
                //     }

                //     dateObject.setHours(hours, minutes, 0, 0);
                //     const isUpcoming = dateObject.getTime() > currentDate.getTime();

                //     return isUpcoming && record.status === pending && record.u_user_id !== null;
                // });

                setSkalto1(false);
            }
            else {
                setData2()
                setSkalto1(false);
            }
        }
        catch (error) {
            console.error('Error:', error);
        }
    }



    const display_appointments_doctor_all = async (id) => {
        try {
            let result = await fetch(Connection + `requested_appointments_to_doctor_all_p/${id}`, {})
            const response = await result.json();
            // console.log('appointment', response);

            if (response && response.response) {
                let request = "request"
                const record5 = response.response.filter(record4 => record4.status === request && record4.u_user_id !== null);

                setData1(record5);
                setSkalton(false);
            }
            else {
                setData1()
                setSkalton(false);
            }
        }
        catch (error) {
            console.error('Error:', error);
        }
    }




    const Accept_appointment = async (appointment_id, time, date, payment_method) => {
        setSpinner(true)
        window.scrollTo(0, 0);
        document.body.style.overflow = 'hidden';

        let status;
        if (payment_method == 'Insurance') {
            status = 'wait_for_pay'
        }
        else {
            status = 'pending'
        }

        let result = await fetch(Connection + "do_it_pending", {
            method: 'post',
            body: JSON.stringify({ appointment_id, status }),
            headers: {
                "Content-Type": 'application/json'
            }
        })
        const response = await result.json();
        console.log('accept appointment', response.response);

        if (response.response == 'success') {
            setSpinner(false)
            document.body.style.overflow = 'auto';

            toast.success('Please be available on appointment time');

            get_pending_appointments_Doctor(id)
            display_appointments_doctor_all(id)
        }
        else {
            setSpinner(false)
            document.body.style.overflow = 'auto';
            alert("Please try again later");
        }
    };



    const handleShow = (payment_method, user_name, date, day, time, user_profile, user_address, user_email, user_mobile, user_id, id, fee, user_fcm_token, user_web_fcm, status, active, device_token, user_gender, category, type, user_type, doctor_name, u_user_id) => {
        document.body.style.overflow = 'hidden';

        setPayment_method(payment_method)
        setUser_name(user_name)
        setDate(date)
        setDay(day)
        setTime(time)
        setUser_profile(user_profile)
        setUser_address(user_address)
        setUser_email(user_email)
        setUser_mobile(user_mobile)
        setUser_id(user_id)
        setAppointment_id(id)
        setFee(fee)
        setUser_fcm_token(user_fcm_token)
        setUser_web_fcm(user_web_fcm)
        setStatus(status)
        setActive(active)
        setDevice_token(device_token)
        setUser_gender(user_gender)
        setCategory(category)
        setApp_type(type)
        setUser_type(user_type)
        setDoctor_name(doctor_name)
        setU_user_id(u_user_id)

        setShow(true);
    };


    const handleClose = () => {
        document.body.style.overflow = 'auto';
        setShow(false);
    };


    const handleClosad_sche = () => {
        document.body.style.overflow = 'auto';
        setAd_sche(false);
    };


    return (
        <div className="Doctorhome_website">

            <header className='docto_hometop'>
                <div className='logo_dochome_name'>
                    <div className='circle_logo'>
                        <img className="image_logo" src={mass_1} />
                    </div>

                    <h4>Hello, {user_name}!</h4>
                </div>

                <h3>Find the Best Doctor Near You</h3>

                {consult_now == true &&
                    <div className='consult_onof'>
                        <h4>Urgent Care Visit Availbility - (Appear Online)</h4>
                        <Switch
                            onChange={toggleSwitch}
                            checked={isOn2}
                            onColor="#874DAF"
                            offColor="#c6c5c5"
                            height={24}
                            width={48}
                        />
                    </div>
                }

                {/* <div style={{ cursor: 'pointer', width: 'calc(100% - 20px)', borderRadius: '10px', overflow: 'hidden', marginTop: '10px', alignSelf: 'center' }}>
                    <img src={require('../../assets/s.png')} alt="Image" style={{ width: '100%', height: 'calc(100% / 5)' }} />
                </div> */}
            </header>


            <div className='allpendingappointm'>
                <h3>Upcoming Visits</h3>

                <div className='maindivpendingappo'>
                    {skalto1 ? (
                        <SkeletonTheme baseColor="lightgray" highlightColor="#bebebe">
                            <div className='pending_appoi'>
                                <Skeleton className='skeltofpendngupcm' />
                                <Skeleton className='skeltofpendngupcm' />
                                <Skeleton className='skeltofpendngupcm' />
                                <Skeleton className='skeltofpendngupcm' />
                            </div>
                        </SkeletonTheme>
                    ) : (
                        <div style={{ width: '100%' }}>
                            {data2.length === 0 ? (
                                <div className='nopenrecorddiv'>
                                    <h4>No Record Found</h4>
                                </div>
                            ) : (
                                <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                    {data2.map((appointment) => (
                                        <div
                                            onClick={() => handleShow(
                                                appointment.payment_method,
                                                appointment.user_name,
                                                appointment.date,
                                                appointment.day,
                                                appointment.time,
                                                appointment.user_profile,
                                                appointment.user_address,
                                                appointment.user_email,
                                                appointment.user_mobile,
                                                appointment.user_id,
                                                appointment.id,
                                                appointment.fee,
                                                appointment.user_fcm_token,
                                                appointment.user_web_fcm,
                                                appointment.status,
                                                appointment.active,
                                                appointment.device_token,
                                                appointment.user_gender,
                                                appointment.category,
                                                appointment.type,
                                                appointment.user_type,
                                                appointment.doctor_name,
                                                appointment.u_user_id,
                                            )}
                                            key={appointment.appointment_id}
                                            className='upcomngmain'
                                        >
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    {appointment.user_profile != null ? (
                                                        <img
                                                            className='upcomimge'
                                                            src={Image_connection + appointment.user_profile}
                                                            alt="profile"
                                                        />
                                                    ) : (
                                                        <img
                                                            className='upcomimge'
                                                            src={require('../../assets/empty.png')}
                                                            alt="empty"
                                                        />
                                                    )}
                                                    <div className='upcmnginsde'>
                                                        <p className='poftext'>
                                                            {appointment.doctor_name}
                                                            {appointment.user_type === 'hisd' && (
                                                                <span className='spanoftet'>(FREE HISD)</span>
                                                            )}
                                                        </p>

                                                        {appointment.type === 'urgent' && (
                                                            <p className='clicoronlne'>Urgent Care</p>
                                                        )}
                                                        {appointment.type === 'offline' && (
                                                            <p className='clicoronlne'>Clinic Appointment</p>
                                                        )}
                                                        {appointment.type === 'online' && (
                                                            <p className='clicoronlne'>Schedule Appointment</p>
                                                        )}
                                                    </div>
                                                </div>
                                                <p className='feofupcom'>${appointment.fee}</p>
                                            </div>
                                            <p className='datofupcome'>{appointment.date}</p>
                                            <div className='mainoflastupco'>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <AccessTimeIcon className='timeicon' />
                                                    <p className='timeoftextin'>{appointment.time}</p>
                                                </div>
                                                {appointment.status === 'pending' ? (
                                                    <p className='statusof'>● In-Process</p>
                                                ) : (
                                                    <p className='statusof'>● Requested</p>
                                                )}
                                            </div>

                                            {appointment.payment_method == 'Insurance' && <p style={{ color: '#00c480', fontSize: 15, marginHorizontal: 6, marginBottom: 2 }}>Insurance Appointment</p>}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>

            <hr style={{ width: '100%', marginTop: 20 }} />



            {data3.length === 0 ?
                <></>
                :
                <>
                    <div className='allpendingappointm'>
                        <h3>Wait for payment</h3>

                        <div className='maindivpendingappo'>
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                {data3.map((appointment) => (
                                    <div
                                        onClick={() => handleShow(
                                            appointment.payment_method,
                                            appointment.user_name,
                                            appointment.date,
                                            appointment.day,
                                            appointment.time,
                                            appointment.user_profile,
                                            appointment.user_address,
                                            appointment.user_email,
                                            appointment.user_mobile,
                                            appointment.user_id,
                                            appointment.id,
                                            appointment.fee,
                                            appointment.user_fcm_token,
                                            appointment.user_web_fcm,
                                            appointment.status,
                                            appointment.active,
                                            appointment.device_token,
                                            appointment.user_gender,
                                            appointment.category,
                                            appointment.type,
                                            appointment.user_type,
                                            appointment.doctor_name,
                                            appointment.u_user_id,
                                        )}
                                        key={appointment.appointment_id}
                                        className='upcomngmain'
                                    >
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                {appointment.user_profile != null ? (
                                                    <img
                                                        className='upcomimge'
                                                        src={Image_connection + appointment.user_profile}
                                                        alt="profile"
                                                    />
                                                ) : (
                                                    <img
                                                        className='upcomimge'
                                                        src={require('../../assets/empty.png')}
                                                        alt="empty"
                                                    />
                                                )}
                                                <div className='upcmnginsde'>
                                                    <p className='poftext'>
                                                        {appointment.doctor_name}
                                                        {appointment.user_type === 'hisd' && (
                                                            <span className='spanoftet'>(FREE HISD)</span>
                                                        )}
                                                    </p>

                                                    {appointment.type === 'urgent' && (
                                                        <p className='clicoronlne'>Urgent Care</p>
                                                    )}
                                                    {appointment.type === 'offline' && (
                                                        <p className='clicoronlne'>Clinic Appointment</p>
                                                    )}
                                                    {appointment.type === 'online' && (
                                                        <p className='clicoronlne'>Schedule Appointment</p>
                                                    )}
                                                </div>
                                            </div>
                                            <p className='feofupcom'>${appointment.fee}</p>
                                        </div>
                                        <p className='datofupcome'>{appointment.date}</p>
                                        <div className='mainoflastupco'>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                {appointment.type != 'urgent' &&
                                                    <AccessTimeIcon className='timeicon' />
                                                }
                                                <p className='timeoftextin'>{appointment.time}</p>
                                            </div>

                                            <p className='statusof'>● Wait for pay</p>
                                        </div>

                                        {appointment.payment_method == 'Insurance' && <p style={{ color: '#00c480', fontSize: 15, marginHorizontal: 6, marginBottom: 2 }}>Insurance Appointment</p>}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <hr style={{ width: '100%', marginTop: 20 }} />
                </>
            }


            <div className='allappointforaccept'>
                <h3>Next Consultation</h3>

                <div className='maindivallappo'>
                    {skalton ? (
                        <SkeletonTheme baseColor="lightgray" highlightColor="#bebebe">
                            <div className='request_appoi'>
                                <Skeleton className='skeltdochmapt' />
                                <Skeleton className='skeltdochmapt' />
                                <Skeleton className='skeltdochmapt' />
                                <Skeleton className='skeltdochmapt' />
                            </div>
                        </SkeletonTheme>
                    ) : (
                        <div style={{ width: '100%' }}>
                            {data1.length === 0 ? (
                                <div className='norecorddiv'>
                                    <h4>No Record Found</h4>
                                </div>
                            ) : (
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', marginTop: 10 }}>
                                    {data1.map((appointment) => (
                                        <div key={appointment.id} style={{ marginBottom: '20px' }}>
                                            <div className='foraceptman'>
                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    <div>
                                                        <p className='unameofacpt'>{appointment.user_name}</p>
                                                        <p className='acatrgoryofacpt'>{appointment.category}</p>
                                                        <p className='afeeofacpt'>${appointment.fee}</p>

                                                        {appointment.type === 'urgent' && (
                                                            <p className='cliconoffreshl'>Urgent Care</p>
                                                        )}
                                                        {appointment.type === 'offline' && (
                                                            <p className='cliconoffreshl'>Clinic Appointment</p>
                                                        )}
                                                        {appointment.type === 'online' && (
                                                            <p className='cliconoffreshl'>Schedule Appointment</p>
                                                        )}

                                                        {appointment.payment_method == 'Insurance' &&
                                                            <p className='cliconoffreshl'>Insurance Appointment</p>
                                                        }

                                                        {appointment.rescedule_reason !== null && (
                                                            <p className='reschilforacpt'>Appointment Reschedule Request</p>
                                                        )}
                                                    </div>

                                                    {appointment.user_profile != null ? (
                                                        <img
                                                            className='imaginaceptapoint'
                                                            src={Image_connection + appointment.user_profile}
                                                            alt="Profile"
                                                        />
                                                    ) : (
                                                        <img
                                                            className='imaginaceptapoint'
                                                            src={require('../../assets/empty.png')}
                                                            alt="empty"
                                                        />
                                                    )}
                                                </div>

                                                <div className='lininacpt'></div>

                                                {appointment.type != 'urgent' &&
                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '95%', alignSelf: 'center' }}>
                                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                            <CalendarMonthIcon className='calendrmothicn' />
                                                            <p className='dattimixacpt'>{appointment.date}</p>
                                                        </div>

                                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 5 }}>
                                                            <AccessTimeIcon className='calendrmothicn' />
                                                            <p className='dattimixacpt'>{appointment.time}</p>
                                                        </div>
                                                    </div>
                                                }

                                                {appointment.payment_method == 'Insurance' ?
                                                    <button
                                                        onClick={() => handleShow(
                                                            appointment.payment_method,
                                                            appointment.user_name,
                                                            appointment.date,
                                                            appointment.day,
                                                            appointment.time,
                                                            appointment.user_profile,
                                                            appointment.user_address,
                                                            appointment.user_email,
                                                            appointment.user_mobile,
                                                            appointment.user_id,
                                                            appointment.id,
                                                            appointment.fee,
                                                            appointment.user_fcm_token,
                                                            appointment.user_web_fcm,
                                                            appointment.status,
                                                            appointment.active,
                                                            appointment.device_token,
                                                            appointment.user_gender,
                                                            appointment.category,
                                                            appointment.type,
                                                            appointment.user_type,
                                                            appointment.doctor_name,
                                                            appointment.u_user_id,
                                                        )}
                                                        className='btnofacept'>
                                                        Verify Insurance
                                                    </button>
                                                    :
                                                    <button
                                                        onClick={() => Accept_appointment(appointment.id, appointment.time, appointment.date, appointment.payment_method)}
                                                        className='btnofacept'>
                                                        Accept
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>


            <hr className='spaceindocorsumins' />

            <div className="services-section">
                <div className="service">
                    <i className="fa fa-stethoscope"></i>
                    <h2>Doctors & Specialists</h2>
                    <p>Find the best doctors and specialists in your area.</p>
                </div>
                <div className="service">
                    <i className="fa fa-heartbeat"></i>
                    <h2>Health Records & History</h2>
                    <p>Keep track of your health records and history.</p>
                </div>
                <div className="service">
                    <i className="fa fa-medkit"></i>
                    <h2>Medications & Prescriptions</h2>
                    <p>Manage your medications and prescriptions.</p>
                </div>
            </div>


            <hr style={{ width: '100%', marginTop: 30, marginBottom: 50 }} />


            <section className='playstore_appsign'>
                <img className="image_playstore" src={mobile_screen_shot} />

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <div className='name_ty_logo'>
                        <div className='circule_for_logo'>
                            <img className="imf_log" src={mass_1} />
                        </div>
                        <h4>MAS CLINICAS</h4>
                    </div>

                    <div className='twoo_three_divs'>
                        <CheckIcon style={{ color: '#014e78', fontSize: 15 }} />
                        <h5>Download the app</h5>
                    </div>

                    <div className='twoo_three_divs'>
                        <CheckIcon style={{ color: '#014e78', fontSize: 15 }} />
                        <h5>Book Appointment</h5>
                    </div>

                    <div className='twoo_three_divs'>
                        <CheckIcon style={{ color: '#014e78', fontSize: 15 }} />
                        <h5>Consult with Doctor</h5>
                    </div>

                    <div className='two_btn_goplay'>
                        <div onClick={() => goonlinkapp("https://play.google.com/store/apps/details?id=com.masclinicas")} className='PlayStore'>
                            <img className="playstore_img" src={playstore_icon} />
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10 }}>
                                <h6>GET IT ON</h6>
                                <h4>Google Play</h4>
                            </div>
                        </div>

                        <div onClick={() => goonlinkapp("https://apps.apple.com/pk/app/mas-clinicas/id1631688867")} className='PlayStore'>
                            <img className="playstore_img" src={Appstore_icon} />
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10 }}>
                                <h6>Available on the</h6>
                                <h4>App Store</h4>
                            </div>
                        </div>
                    </div>

                </div>
            </section>


            <section className='footer_top'></section>
            <div className="footer_second_end">
                <div className="col-12 col-sm-12 col-lg-6 mb-4 order-0 order-sm-0"><a className="text-decoration-none" href="#"><img src="assets/img/gallery/footer-logo.png" height="51" alt="" /></a>
                    <p className="p_app_name">Mas Clinicas</p>
                    <p className="text_light">The world's most trusted <br />telehealth company.</p>
                </div>

                <div className='footor_inside'>
                    <div className="col-6 col-sm-4 col-lg-2 mb-3 order-2 order-sm-1">
                        <h5 className="Departments">Departments</h5>
                        <ul className="list-unstyled mb-md-4 mb-lg-0">
                            <li className="lh-lg"><a className="footer_link" href="#!">Eye care</a></li>
                            <li className="lh-lg"><a className="footer_link" href="#!">Cardiac are</a></li>
                            <li className="lh-lg"><a className="footer_link" href="#!">Heart care</a></li>
                        </ul>
                    </div>

                    <div className="col-6 col-sm-4 col-lg-2 mb-3 order-3 order-sm-2">
                        <h5 className="Departments">Membership</h5>
                        <ul className="list-unstyled mb-md-4 mb-lg-0">
                            <li className="lh-lg"><a className="footer_link" href="#!">Free trial</a></li>
                            <li className="lh-lg"><a className="footer_link" href="#!">Silver</a></li>
                            <li className="lh-lg"><a className="footer_link" href="#!">Premium</a></li>
                        </ul>
                    </div>

                    <div className="col-6 col-sm-4 col-lg-2 mb-3 order-3 order-sm-2">
                        <h5 className="Departments"> Customer Care</h5>
                        <ul className="list-unstyled mb-md-4 mb-lg-0">
                            <li className="lh-lg"><a className="footer_link" href="#!">About Us</a></li>
                            <li className="lh-lg"><a className="footer_link" href="#!">Contact US</a></li>
                            <li className="lh-lg"><a className="footer_link" href="#!">Get Update</a></li>
                        </ul>
                    </div>
                </div>

            </div>


            <section className='footer_end'>
                <p>All rights Reserved &copy; Your Company, 2021</p>

                {/* <div class="col-12 col-sm-8 col-md-6">
            <p class="fs--1 my-2 text-center text-md-end text-200"> Made with&nbsp;
              <svg class="bi bi-suit-heart-fill" xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="#F95C19" viewBox="0 0 16 16">
                <path d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z"></path>
              </svg>&nbsp;by&nbsp;<a class="fw-bold text-info" href="https://themewagon.com/" target="_blank">ThemeWagon </a>
            </p>
         </div> */}
            </section>

            <ToastContainer />


            {spinner && (
                <div style={{ width: '100%', height: '100%', backgroundColor: 'rgba(2, 2, 2, 0.8)', position: 'absolute', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <div style={{ display: 'flex', width: '30%', backgroundColor: 'white', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', padding: '30px', boxShadow: '0 1px 5px rgba(0, 0, 0, 0.2)', borderRadius: '6px', }}>
                        <PulseLoader
                            color={"#874DAF"}
                            loading={spinner}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                        <p style={{ fontSize: '16px', color: '#874DAF', fontWeight: 'bold', marginLeft: '15px' }}>
                            Progressing your request
                        </p>
                    </div>
                </div>
            )}

            {show == true &&
                <Doctor_site_appointment_detail show={show} handleClose={handleClose} getProducts={get_pending_appointments_Doctor} getPrducts2={display_appointments_doctor_all} payment_method={payment_method} user_name={user_name} date={date} day={day} time={time} user_profile={user_profile} user_address={user_address} user_email={user_email} user_mobile={user_mobile} user_id={user_id} appointment_id={appointment_id} fee={fee} user_fcm_token={user_fcm_token} user_web_fcm={user_web_fcm} status={status} active={active} device_token={device_token} user_gender={user_gender} category={category} app_type={app_type} user_type={user_type} doctor_name={doctor_name} u_user_id={u_user_id}>
                    <p>Modal Content Goes Here</p>
                </Doctor_site_appointment_detail>
            }


            {ad_sche &&
                <Add_schedulling show={ad_sche} handleClose={handleClosad_sche} check_doctor_sheduling={check_doctor_sheduling}>
                    <p>Modal Content Goes Here</p>
                </Add_schedulling>
            }

            {showScrollButton && (
                <button onClick={scrollToTop} className="scroll_top_button">
                    <ArrowUpwardIcon />
                </button>
            )}

        </div>
    );
}

export default Doctor_Home;