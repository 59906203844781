import React, { useState, useEffect } from 'react'
import "./Update_Schedule.css";
import Connection from '../../connection';
import Image_connection from "../../Image_connection";
import PulseLoader from "react-spinners/PulseLoader";
import { Link, useNavigate } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import 'react-loading-skeleton/dist/skeleton.css'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';


function Update_Schedule() {
    const navigate = useNavigate();

    const [value_for_updating_index, setValue_for_updating_index] = useState(null);

    // Dialog PopUp
    const [showDialog, setShowDialog] = useState(false);
    const handleOpenDialog = (doctorTime) => {
        if (doctorTime) {
            const selectedSchedules = checkDesign === 'Online' ? onlineSchedules : offlineSchedules;
            const index = selectedSchedules[selectedDay].indexOf(doctorTime); // Update with the correct state name

            setValue_for_updating_index(index)
        }
        else {
            setValue_for_updating_index(null)
        }
        setShowDialog(true);
    };
    /////////////


    // Change Btn
    const [text9, setText9] = React.useState(2);
    const [text10, setText10] = React.useState(1);
    const [checkDesign, setCheckDesign] = React.useState('Online');
    const changebt1 = (text, val) => {
        setText9(1);
        setText10(1);

        if (text === "text9") {
            setText9(2);
        }
        else if (text === "text10") {
            setText10(2);
        }

        setCheckDesign(val)
    }
    /////////////////////////


    const [notification_date, setNotification_date] = React.useState("");
    const [notification_time, setNotification_time] = React.useState("");

    const [profile, setProfile] = useState(null);
    const [doctor_id, setDoctorId] = useState('');
    const [doctor_name, setDoctor_name] = useState('');
    const [category_1, setCategory_1] = useState('');

    const [selectedTime, setSelectedTime] = useState(null);

    const [skalton, setSkalton] = useState(true);
    const [spinner, setSpinner] = useState(false);



    useEffect(() => {
        // User ID From LocalStorage
        const auth = localStorage.getItem("customer");
        const parsed = JSON.parse(auth);
        const id = parsed[0].id;
        const name = parsed[0].name;
        const category_1 = parsed[0].category;
        setDoctorId(id)
        setDoctor_name(name)
        setCategory_1(category_1)


        let profile1 = parsed[0].profile;
        if (profile1 == null) {
            setProfile(null)
        }
        else {
            let profile = Image_connection + profile1
            setProfile(profile)
        }

        /////////////


        // Date And Time For Notification
        const aa = moment(new Date()).format("YYYY-MM-DD hh:mm A");
        const split = aa.split(' ')
        const datwe = split[0]
        const time = split[1]
        const am_pm = split[2]
        const final_time = time + "" + am_pm
        setNotification_date(datwe)
        setNotification_time(final_time)
        /////////////

        get_schedule(id)
    }, [])


    const get_schedule = async (id) => {
        let doctor_id = id
        let result = await fetch(Connection + "get_schedule", {
            method: 'post',
            body: JSON.stringify({ doctor_id }),
            headers: {
                "Content-Type": 'application/json'
            }
        })

        result = await result.json()
        const record4 = result

        if (record4 !== 'fail') {
            const { online_arr, offline_arr } = record4[0];

            if (online_arr != '' && online_arr != null) {
                console.log("online array => ", online_arr)

                const parsedOnlineArr = JSON.parse(online_arr);

                setOnlineSchedules(parsedOnlineArr)
            }
            else {
                setOnlineSchedules({})
            }

            if (offline_arr != '' && offline_arr != null) {
                console.log("offline array => ", offline_arr)

                const parsedOfflineArr = JSON.parse(offline_arr);

                setOfflineSchedules(parsedOfflineArr)
            }
            else {
                setOfflineSchedules({})
            }

            setSkalton(false)
        }
        else {
            setOnlineSchedules({})
            setOfflineSchedules({})
            setSkalton(false)
        }
    };


    const [text1, setText1] = useState(2);
    const [text2, setText2] = useState(1);
    const [text3, setText3] = useState(1);
    const [text4, setText4] = useState(1);
    const [text5, setText5] = useState(1);
    const [text6, setText6] = useState(1);
    const [text7, setText7] = useState(1);
    const [selectedDay, setSelectedDay] = useState("Monday");
    const [onlineSchedules, setOnlineSchedules] = useState({
        Monday: [],
        Tuesday: [],
        Wednesday: [],
        Thursday: [],
        Friday: [],
        Saturday: [],
        Sunday: [],
    });

    const [offlineSchedules, setOfflineSchedules] = useState({
        Monday: [],
        Tuesday: [],
        Wednesday: [],
        Thursday: [],
        Friday: [],
        Saturday: [],
        Sunday: [],
    });

    const changebtn2 = (value, value2) => {
        setSelectedDay(value2);

        setText1(1);
        setText2(1);
        setText3(1);
        setText4(1);
        setText5(1);
        setText6(1);
        setText7(1);

        if (value === "text1") {
            setText1(2);
        }
        else if (value === "text2") {
            setText2(2);
        }
        else if (value === "text3") {
            setText3(2);
        }
        else if (value === "text4") {
            setText4(2);
        }
        else if (value === "text5") {
            setText5(2);
        }
        else if (value === "text6") {
            setText6(2);
        }
        else if (value === "text7") {
            setText7(2);
        }
    };



    const timeSelect = (date) => {
        const slot = date.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        }).toLowerCase();
        console.log("slot", slot);

        const selectedSchedules = checkDesign === 'Online' ? onlineSchedules : offlineSchedules;
        const oppositeSchedules = checkDesign === 'Online' ? offlineSchedules : onlineSchedules;
        const scheduleType = checkDesign === 'Online' ? 'Online' : 'Offline';

        if (value_for_updating_index !== null) {
            if (!selectedSchedules[selectedDay].includes(slot) && !oppositeSchedules[selectedDay].includes(slot)) {
                let updatedDayArray = [...selectedSchedules[selectedDay]];
                updatedDayArray[value_for_updating_index] = slot;

                if (checkDesign === 'Online') {
                    setOnlineSchedules({
                        ...selectedSchedules,
                        [selectedDay]: updatedDayArray,
                    });
                }
                else {
                    setOfflineSchedules({
                        ...selectedSchedules,
                        [selectedDay]: updatedDayArray,
                    });
                }

                setValue_for_updating_index(null); // Reset the value_for_updating_index after updating the slot
                console.log("Already");
                setShowDialog(false);
            }
            else {
                if (selectedSchedules[selectedDay].includes(slot)) {
                    alert(`This slot already added in ${scheduleType} schedule for ${selectedDay}`);
                }
                else if (oppositeSchedules[selectedDay].includes(slot)) {
                    alert(`This slot already added in ${oppositeSchedules === offlineSchedules ? 'Offline' : 'Online'} schedule for ${selectedDay}`);
                }
            }
        }
        else {
            if (!selectedSchedules[selectedDay].includes(slot) && !oppositeSchedules[selectedDay].includes(slot)) {
                let updatedDayArray = [...selectedSchedules[selectedDay]];
                updatedDayArray.push(slot);

                if (checkDesign === 'Online') {
                    setOnlineSchedules({
                        ...selectedSchedules,
                        [selectedDay]: updatedDayArray,
                    });
                }
                else {
                    setOfflineSchedules({
                        ...selectedSchedules,
                        [selectedDay]: updatedDayArray,
                    });
                }
                console.log("New");
            }
            else {
                if (selectedSchedules[selectedDay].includes(slot)) {
                    alert(`This slot already added in ${scheduleType} schedule for ${selectedDay}`);
                }
                else if (oppositeSchedules[selectedDay].includes(slot)) {
                    alert(`This slot already added in ${oppositeSchedules === offlineSchedules ? 'Offline' : 'Online'} schedule for ${selectedDay}`);
                }
            }
        }
    };



    // Flat list show both slot already booked and new time slot select
    const renderSlots = () => {
        const selecthedules = checkDesign === 'Online' ? onlineSchedules : offlineSchedules;
        const scheduleArray = selecthedules[selectedDay] || []; // Update with the correct state name

        if (scheduleArray.length === 0) {
            return (
                <div style={{ flexDirection: 'row', flexWrap: 'wrap', marginVertical: '10px', alignItems: 'center' }}>
                    <button onClick={() => handleOpenDialog()} style={{ border: 'none', backgroundColor: '#874DAF', width: '100px', justifyContent: 'center', alignItems: 'center', borderRadius: '10px', marginLeft: '10px', padding: '10px 0px', marginTop: '10px', borderColor: '#874DAF' }}>
                        <span style={{ color: 'white' }}>Add Time</span>
                    </button>

                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', margin: '15px 0px 15px 0px' }}>
                        <span style={{ color: 'black' }}>No Slots Available</span>
                    </div>
                </div>
            );
        }
        else {
            return (
                <div style={{ flexDirection: 'row', flexWrap: 'wrap', marginVertical: '10px', alignItems: 'center' }}>
                    <button onClick={() => handleOpenDialog()} style={{ border: 'none', backgroundColor: '#874DAF', width: '100px', justifyContent: 'center', alignItems: 'center', borderRadius: '10px', marginLeft: '10px', padding: '10px 0px', marginTop: '10px', borderColor: '#874DAF' }}>
                        <span style={{ color: 'white' }}>Add Time</span>
                    </button>

                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                        {createTable1(scheduleArray)}
                    </div>
                </div>
            );
        }
    };


    const createTable1 = (scheduleArray) => {
        return scheduleArray.map((doctorTime, index) => (
            <div key={index}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '10px 0px 5px 0px' }}>
                    <div onClick={() => handleOpenDialog(doctorTime)}
                        style={{ width: '100px', justifyContent: 'center', alignItems: 'center', borderRadius: '10px', display: 'flex', border: '1px solid #874DAF', marginLeft: '10px', padding: '10px 0px' }}
                    >
                        <span style={{ color: '#874DAF', fontWeight: 'bold' }}>{doctorTime}</span>
                    </div>

                    <DeleteForeverOutlinedIcon onClick={() => arrayIndexForDelete(doctorTime)} style={{ color: '#874DAF', fontSize: '23px', position: 'absolute' }} />
                </div>
            </div>
        ));
    };


    const arrayIndexForDelete = (slot) => {
        let selectedSchedulesCopy = checkDesign === 'Online' ? { ...onlineSchedules } : { ...offlineSchedules };

        const index1 = selectedSchedulesCopy[selectedDay].indexOf(slot);
        if (index1 >= 0) {
            selectedSchedulesCopy[selectedDay].splice(index1, 1);

            if (checkDesign === 'Online') {
                setOnlineSchedules(selectedSchedulesCopy)
            }
            else {
                setOfflineSchedules(selectedSchedulesCopy)
            }
        }
    };


    const Update_Shedule = async () => {
        let isOnlineEmpty = true;

        Object.keys(onlineSchedules).forEach((day) => {
            if (onlineSchedules[day].length > 0) {
                isOnlineEmpty = false;
            }
        });

        if (isOnlineEmpty) {
            // alert('Please add schedule for online mode.');
            alert('Please add schedule.');
        }
        else {
            setSpinner(true);

            console.log('onlineSchedules => ', onlineSchedules);
            // console.log('offlineSchedules => ', offlineSchedules);

            let online_arr = JSON.stringify(onlineSchedules)
            // let offline_arr = JSON.stringify(offlineSchedules)

            let result = await fetch(Connection + "update_schedule_2", {
                method: 'post',
                body: JSON.stringify({ doctor_id, online_arr }),
                headers: {
                    "Content-Type": 'application/json'
                }
            })

            result = await result.json()
            let record = result.response

            if (record === 'fail') {
                setSpinner(false);
                alert('Please try again later.');
            }
            else {
                setSpinner(false);
                toast.success('Your schedule was successfully updated!');
                get_schedule(doctor_id)
            }

        }
    };


    return (
        <div className="update_schedualBackground">

            <div className="update_schedualContainer">
                <div style={{ width: '100%', backgroundColor: '#874DAF', padding: '5px 10px', display: 'flex', flexDirection: 'row' }}>
                    <img
                        style={{ width: '55px', height: '55px', borderRadius: '40px', objectFit: 'cover' }}
                        src={profile || 'https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG.png'}
                        alt="Profile"
                    />
                    <div style={{ marginLeft: '10px', justifyContent: 'center' }}>
                        <p style={{ color: 'white', fontWeight: 'bold', fontSize: '18px', margin: '0' }}>{doctor_name}</p>
                        <p style={{ color: 'white', maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', margin: '0' }}>{category_1}</p>
                    </div>
                </div>


                {/* <div className="updatebuttonContainer">
                    <button className={text9 === 1 ? 'inactiveButton' : 'activeButton'} onClick={() => changebt1('text9', 'Online')}>
                        Online
                    </button>

                    <button className={text10 === 1 ? 'inactiveButton' : 'activeButton'} onClick={() => changebt1('text10', 'Offline')}>
                        Offline
                    </button>
                </div> */}

                <div style={{ width: '100%', backgroundColor: 'white', borderRadius: '15px', alignSelf: 'center', padding: '20px', marginTop: '10px', boxShadow: '0px 2px 3.84px 0px rgba(0,0,0,0.25)', marginBottom: '20px' }}>
                    <div style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <span style={{ color: 'black', fontWeight: 'bold', fontSize: '17px' }}>Check-up Time</span>
                    </div>

                    <div style={{ padding: '10px 0px' }}>
                        <div className='maindivofall7day'>
                            <button onClick={() => changebtn2('text1', 'Monday')} className={text1 === 1 ? 'view1' : 'view'}>
                                <span className={text1 === 1 ? 'text1' : 'text'}>Monday</span>
                            </button>

                            <button onClick={() => changebtn2('text2', 'Tuesday')} className={text2 === 1 ? 'view1' : 'view'}>
                                <span className={text2 === 1 ? 'text1' : 'text'}>Tuesday</span>
                            </button>

                            <button onClick={() => changebtn2('text3', 'Wednesday')} className={text3 === 1 ? 'view1' : 'view'}>
                                <span className={text3 === 1 ? 'text1' : 'text'}>Wednesday</span>
                            </button>

                            <button onClick={() => changebtn2('text4', 'Thursday')} className={text4 === 1 ? 'view1' : 'view'}>
                                <span className={text4 === 1 ? 'text1' : 'text'}>Thursday</span>
                            </button>

                            <button onClick={() => changebtn2('text5', 'Friday')} className={text5 === 1 ? 'view1' : 'view'}>
                                <span className={text5 === 1 ? 'text1' : 'text'}>Friday</span>
                            </button>

                            <button onClick={() => changebtn2('text6', 'Saturday')} className={text6 === 1 ? 'view1' : 'view'}>
                                <span className={text6 === 1 ? 'text1' : 'text'}>Saturday</span>
                            </button>

                            <button onClick={() => changebtn2('text7', 'Sunday')} className={text7 === 1 ? 'view1' : 'view'}>
                                <span className={text7 === 1 ? 'text1' : 'text'}>Sunday</span>
                            </button>
                        </div>
                    </div>

                    <span style={{ color: 'black', fontWeight: 'bold', fontSize: '17px' }}>Time</span>

                    {skalton == true ?
                        <SkeletonTheme baseColor="lightgray" highlightColor="#bebebe">
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginVertical: '10px' }}>
                                <Skeleton width={100} height={35} style={{ marginLeft: 5, marginRight: 5, marginTop: 10 }} />
                                <Skeleton width={100} height={35} style={{ marginLeft: 5, marginRight: 5, marginTop: 10 }} />
                                <Skeleton width={100} height={35} style={{ marginLeft: 5, marginRight: 5, marginTop: 10 }} />
                                <Skeleton width={100} height={35} style={{ marginLeft: 5, marginRight: 5, marginTop: 10 }} />
                                <Skeleton width={100} height={35} style={{ marginLeft: 5, marginRight: 5, marginTop: 10 }} />
                                <Skeleton width={100} height={35} style={{ marginLeft: 5, marginRight: 5, marginTop: 10 }} />
                                <Skeleton width={100} height={35} style={{ marginLeft: 5, marginRight: 5, marginTop: 10 }} />
                                <Skeleton width={100} height={35} style={{ marginLeft: 5, marginRight: 5, marginTop: 10 }} />
                                <Skeleton width={100} height={35} style={{ marginLeft: 5, marginRight: 5, marginTop: 10 }} />
                                <Skeleton width={100} height={35} style={{ marginLeft: 5, marginRight: 5, marginTop: 10 }} />
                                <Skeleton width={100} height={35} style={{ marginLeft: 5, marginRight: 5, marginTop: 10 }} />
                                <Skeleton width={100} height={35} style={{ marginLeft: 5, marginRight: 5, marginTop: 10 }} />
                            </div>
                        </SkeletonTheme>
                        :
                        <>
                            {renderSlots()}
                        </>
                    }
                </div>

                <div onClick={() => Update_Shedule()} className="donebuttoContainer">
                    <button className="customButton">
                        Update
                    </button>
                </div>
            </div>



            <Dialog className='timedate_Dialog' open={showDialog}>
                <p style={{ marginLeft: 10, marginTop: 10 }}>Select Time</p>
                <div className='timeowninside'>
                    <DatePicker
                        selected={selectedTime}
                        onChange={(date) => {
                            setSelectedTime(date);
                            timeSelect(date);
                        }}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        dateFormat="h:mm aa"
                        timeCaption="Time"
                    />
                </div>

                <DialogActions className='timedate_Ok_nd_Close'>
                    <Button onClick={() => setShowDialog(false)} className='freeapoint_Ok'>DONE</Button>
                </DialogActions>
            </Dialog>


            <ToastContainer />


            {spinner == true &&
                <div style={{ width: '100%', height: '100%', backgroundColor: 'rgba(2, 2, 2, 0.8)', position: 'absolute', top: 0, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <div style={{ display: 'flex', width: '30%', backgroundColor: 'white', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', padding: '30px', boxShadow: '0 1px 5px rgba(0, 0, 0, 0.2)', borderRadius: '6px', }}>
                        <PulseLoader
                            color={"#874DAF"}
                            loading={spinner}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                        <p style={{ fontSize: '16px', color: '#874DAF', fontWeight: 'bold', marginLeft: '15px' }}>
                            Progressing your request
                        </p>
                    </div>
                </div>
            }
        </div>
    );
}

export default Update_Schedule;