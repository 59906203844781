import React, { useState, useEffect, useContext } from 'react'
import './Home.css';
import { Link, useNavigate } from 'react-router-dom';
import Connection from "../../connection";
import Image_connection from "../../Image_connection";
import { makeStyles } from '@material-ui/core/styles';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { GlobalInfo } from "../../App"
import { firebaseApp, messaging } from '../../firebase';
import { getToken } from 'firebase/messaging';
import axios from 'axios';
import Modal_detail from '../../MyComponent/Modal_detail/Modal_detail';
import Pay_insurance_optin from '../../MyComponent/Pay_insurance_optin/Pay_insurance_optin';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import StarIcon from '@mui/icons-material/Star';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import SearchIcon from '@mui/icons-material/Search';
import CheckIcon from '@mui/icons-material/Check';
import Modal_categ from '../../MyComponent/Modal_categ/Modal_categ';
import Type_Selection from '../../MyComponent/Type_Selection/Type_Selection';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import Physician1 from '../../assets/Physician1.png';
import oral from '../../assets/oral.png';
import heart from '../../assets/heart.png';
import Pediatric1 from '../../assets/Pediatric1.png';
import Dermotoglist1 from '../../assets/Dermotoglist1.png';
import ortho from '../../assets/ortho.png';
import brain from '../../assets/brain.png';
import Mental_Health from '../../assets/Mental_Health.png';
import terapist from '../../assets/terapist.png';

import online_cons from '../../assets/online_cons.png';
import Online_forrr from '../../assets/Online_forrr.png';
import local_image_doc1 from '../../assets/local_image_doc1.png';
import free_hisd from '../../assets/free_hisd.png';
import HoustonISD from '../../assets/HoustonISD.png';
import Frame_3 from '../../assets/Frame_3.png';
import Frame_2 from '../../assets/Frame_2.png';
import mass_1 from '../../assets/mass_1.png';
import playstore_icon from '../../assets/playstore_icon.png';
import Appstore_icon from '../../assets/Appstore_icon.png';
import mobile_screen_shot from '../../assets/mobile_screen_shot.png';

const Home = (props) => {
  const { do_free_hisd, do_status } = useContext(GlobalInfo)
  const navigate = useNavigate();

  const classes = useStyles();

  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [skalto1, setSkalto1] = useState(true);

  const [apointdetail, setApointdetail] = useState(false);
  const [paymentOnn, setPaymentOnn] = useState(false);
  const [payment_method, setPayment_method] = React.useState('');
  const [date, setDate] = React.useState('');
  const [status, setStatus] = React.useState('');
  const [day, setDay] = React.useState('');
  const [time, setTime] = React.useState('');
  const [doctor_name, setDoctor_name] = React.useState('');
  const [category, setCategory] = React.useState('');
  const [doctor_address, setDoctor_address] = React.useState('');
  const [doctor_profile, setDoctor_profile] = React.useState('');
  const [fee, setFee] = React.useState('');
  const [insurance_fee, setInsurance_fee] = React.useState('');
  const [user_name, setUser_name] = React.useState('');
  const [doctor_experience, setDoctor_experience] = React.useState('');
  const [appointment_id, setAppointment_id] = React.useState('');
  const [app_type, setApp_type] = React.useState('');
  const [user_type, setUser_type] = React.useState('');
  const [doctor_id, setDoctor_id] = React.useState('');
  const [doctor_email, setDoctor_email] = React.useState('');
  const [fcm_token, setFcm_token] = React.useState('');
  const [web_fcm, setWeb_fcm] = React.useState('');


  const [doctor, setDoctor] = useState([]);
  const [skalton, setSkalton] = React.useState(true);

  const [user_nme, setUser_nme] = React.useState('');
  const [show, setShow] = useState(false);

  const [typemodal, setTypemodal] = useState(false);
  const [type_of, setType_of] = useState('');
  const [usertype, setUsertype] = useState('');

  const [doctorId, setDoctorId] = useState('');
  const [doctorcategory, setDoctorcategory] = useState('');

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener('online', handleOnlineStatusChange);
    window.addEventListener('offline', handleOnlineStatusChange);

    return () => {
      window.removeEventListener('online', handleOnlineStatusChange);
      window.removeEventListener('offline', handleOnlineStatusChange);
    };
  }, []);

  useEffect(() => {
    Notification.requestPermission().then(permission => {
      if (permission === 'granted') {
        getToken(messaging, { vapidKey: "BHwo7XkJcDLDE1g1t-ZLtjVXqlmSn12QX2qihLtgIM1COE1GqOi4US04hjv25md38FC0dSy-2C_bFFbov-cV8D4" }).then(currentToken => {

          const auth = localStorage.getItem("customer");
          const parsed = JSON.parse(auth);
          const id = parsed[0].id;
          Update_fcmtoken(id, currentToken)
        });
      }
    });

    // User ID From LocalStorage
    const auth = localStorage.getItem("customer");
    const parsed = JSON.parse(auth);
    const id = parsed[0].id;
    const user_name = parsed[0].name;
    setUser_nme(user_name)
    /////////////

    get_doctor()
    display_appointments_patient_all(id)
  }, [])


  // For Scroll bottom to the top
  const [showScrollButton, setShowScrollButton] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const shouldShowButton = scrollTop > 200;
      setShowScrollButton(shouldShowButton);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  /////////////////////////////


  const Update_fcmtoken = async (id, fcm_token) => {
    console.log('id =>', id)
    console.log('fcm_token =>', fcm_token)
    let result = await fetch(Connection + "update_both_token", {
      method: 'post',
      body: JSON.stringify({ id, fcm_token }),
      headers: {
        "Content-Type": 'application/json'
      }
    })

    result = await result.json()
    let record = result.response

    if (record === 'Update successful') {
      console.log("Update successful");
    }
    else {
      console.log("Update failed");
    }
  };


  const get_doctor = async () => {
    let result = await fetch(Connection + "das_get_doctor", {

    })
    result = await result.json()
    setDoctor(result)
    console.warn(result)

    if (result) {
      setSkalton(false);
    }
  }


  const goondoctorall = () => {
    const searchbar = 'true'
    const url = `/Doctor_list?searchbar=${searchbar}`;
    navigate(url)
    // navigate("/Doctor_list")
  }

  const goonlinkapp = (link) => {
    window.open(link, '_blank');
  }

  const goonmyapppointment = () => {
    navigate("/Patient_All_Appointment")
  }


  const handleShow = (value) => {
    const type_of = value['type_of']
    const usertype = value['usertype']

    setType_of(type_of)
    setUsertype(usertype)

    if (type_of == 'urgent') {
      const catgry = 'urgent'
      const url = `/Doctor_by_category/${catgry}?value1=${type_of}&value2=${usertype}`;
      navigate(url);
    }
    else {
      document.body.style.overflow = 'hidden';
      setShow(true);
    }
  };


  const handleClose = () => {
    document.body.style.overflow = 'auto';
    setShow(false);
  };


  const display_appointments_patient_all = async (id) => {
    try {
      let result = await fetch(Connection + `get_user_appointment_A/${id}`, {})
      const response = await result.json();

      if (response && response.response) {
        let request = "request"
        let wait_forpay = "wait_for_pay"
        const recordreq = response.response.filter(record4 => record4.status === request && record4.u_doctor_id !== null);
        const recordpay = response.response.filter(record4 => record4.status === wait_forpay && record4.u_doctor_id !== null);

        setData1(recordreq);
        setData2(recordpay);
        setSkalto1(false);
      }
      else {
        setData1()
        setSkalto1(false);
      }
    }
    catch (error) {
      console.error('Error:', error);
    }
  }



  const apointdeatsho = (payment_method, date, status, day, time, doctor_name, category, doctor_address, doctor_profile, fee, doc_insurance_fee, user_name, doctor_experience, id, type, user_type, doctor_id, doctor_email, fcm_token, web_fcm, valuiii) => {
    document.body.style.overflow = 'hidden';

    setPayment_method(payment_method)
    setDate(date)
    setStatus(status)
    setDay(day)
    setTime(time)
    setDoctor_name(doctor_name)
    setCategory(category)
    setDoctor_address(doctor_address)
    setDoctor_profile(doctor_profile)
    setFee(fee)
    setInsurance_fee(doc_insurance_fee)
    setUser_name(user_name)
    setDoctor_experience(doctor_experience)
    setAppointment_id(id)
    setApp_type(type)
    setUser_type(user_type)
    setDoctor_id(doctor_id)
    setDoctor_email(doctor_email)
    setFcm_token(fcm_token)
    setWeb_fcm(web_fcm)

    if (valuiii == 'payment') {
      setPaymentOnn(true);
    }
    else {
      setApointdetail(true);
    }
  };

  const handleapaymentclos = () => {
    document.body.style.overflow = 'auto';
    setPaymentOnn(false);
  };

  const handleapontdetalClose = () => {
    document.body.style.overflow = 'auto';
    setApointdetail(false);
  };

  // const handleCategoryClick = async (category) => {
  //   const catrgoty = category['category']
  //   const appointments_type = 'appointment'

  //   // const url = `/Doctor_by_category/${catrgoty}?value1=${appointments_type}`;
  //   // navigate(url)
  // };

  // const handleDoctorClick = (id, value) => {
  //   console.log("id =>", id)
  //   console.log("value =>", value)

  //   setUsertype(usertype)
  //   setDoctorId(id)
  //   setTypemodal(true);
  //   // const appointments_type = 'appointment'
  //   // const url = `/Doctor_Details/${id}?value1=${appointments_type}`;
  //   // navigate(url)
  // }

  const TypeModalShow = (value) => {
    const id = value['id'];
    const category = value['category'];
    const user_type = value['usertype']

    setUsertype(user_type)
    setDoctorId(id)
    setDoctorcategory(category)

    if (user_type == 'hisd') {
      document.body.style.overflow = 'hidden';
      setTypemodal(true);
    }
    else if (category) {
      const type__of = 'online'
      const url = `/Doctor_by_category/${category}?value1=${type__of}&value2=${user_type}`;
      navigate(url);
    }
    else {
      const type__of = 'online'
      const url = `/Doctor_Details/${id}?value1=${type__of}&value2=${user_type}`;
      navigate(url);
    }
  };

  const typeModalclose = () => {
    document.body.style.overflow = 'auto';
    setTypemodal(false);
  };


  const workidalert = () => {
    alert("Your employee ID is under review.")
  }



  return (
    <div className="medical_website">
      <header className='top_section'>
        <div className='logo_nd_name'>
          <div className='circle_logo'>
            <img className="image_logo" src={mass_1} />
          </div>

          <h4>Hello, {user_nme}!</h4>
        </div>

        <h3>Find the Best Doctor Near You</h3>

        <div className='search_section'>
          <div className='both_join'>
            {/* <div className='pahli_input'>
              <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                 <LocationOnIcon style={{fontSize:'20',color:'#874daf'}} />
                 <input type="location" className="location_inpu" placeholder="Enter City" required/>
              </div>
              <KeyboardArrowDownOutlinedIcon/>
            </div> */}

            <div onClick={() => goondoctorall()} className='dusri_inpu'>
              <input type="Search" className="search_inpu" placeholder="Search by Doctor" required readOnly />
              <div className='searching_hol'>
                <SearchIcon style={{ color: '#874daf' }} />
              </div>
            </div>
          </div>
        </div>
      </header>


      {data2.length === 0 ?
        <></>
        :
        <div className='allpendingappointm'>
          <h3>Insurance pending payment</h3>

          <div className='maindivpendingappo'>
            <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
              {data2.map((appointment) => (
                <div
                  onClick={() => apointdeatsho(
                    appointment.payment_method,
                    appointment.date,
                    appointment.status,
                    appointment.day,
                    appointment.time,
                    appointment.doctor_name,
                    appointment.category,
                    appointment.doctor_address,
                    appointment.doctor_profile,
                    appointment.fee,
                    appointment.doc_insurance_fee,
                    appointment.user_name,
                    appointment.doctor_experience,
                    appointment.id,
                    appointment.type,
                    appointment.user_type,
                    appointment.doctor_id,
                    appointment.doctor_email,
                    appointment.doc_fcm_token,
                    appointment.doc_web_fcm,
                    'payment'
                  )}
                  key={appointment.appointment_id}
                  className='upcomngmain'
                >
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {appointment.doctor_profile != null ? (
                        <img
                          className='upcomimge'
                          src={Image_connection + appointment.doctor_profile}
                          alt="profile"
                        />
                      ) : (
                        <img
                          className='upcomimge'
                          src={require('../../assets/empty.png')}
                          alt="empty"
                        />
                      )}
                      <div className='upcmnginsde'>
                        <p className='poftext'>
                          {appointment.doctor_name}
                          {appointment.user_type === 'hisd' && (
                            <span className='spanoftet'>(FREE HISD)</span>
                          )}
                        </p>
                        {appointment.type == 'urgent' &&
                          <p className='clicoronlne'>Urgent Care</p>
                        }
                        {appointment.type === 'offline' && (
                          <p className='clicoronlne'>Clinic Appointment</p>
                        )}
                        {appointment.type === 'online' && (
                          <p className='clicoronlne'>Schedule Appointment</p>
                        )}
                      </div>
                    </div>
                    <p className='feofupcom'>${appointment.fee}</p>
                  </div>
                  <p className='datofupcome'>{appointment.date}</p>
                  <div className='mainoflastupco'>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      {appointment.type != 'urgent' &&
                        <AccessTimeIcon className='timeicon' />
                      }
                      <p className='timeoftextin'>{appointment.time}</p>
                    </div>

                    <p className='statusof'>● Wait for payment</p>
                  </div>

                  {appointment.payment_method == 'Insurance' && <p style={{ color: '#00c480', fontSize: 15, marginHorizontal: 6, marginBottom: 2 }}>Insurance Appointment</p>}
                </div>
              ))}
            </div>

          </div>
        </div>
      }


      <div className='allpendingappointm'>
        <h3>Upcoming Appointment</h3>

        <div className='maindivpendingappo'>
          {skalto1 ? (
            <SkeletonTheme baseColor="lightgray" highlightColor="#bebebe">
              <div className='pending_appoi'>
                <Skeleton className='skeltofpendngupcm' />
                <Skeleton className='skeltofpendngupcm' />
                <Skeleton className='skeltofpendngupcm' />
                <Skeleton className='skeltofpendngupcm' />
              </div>
            </SkeletonTheme>
          ) : (
            <div style={{ width: '100%' }}>
              {data1.length === 0 ? (
                <div className='nopenrecorddiv'>
                  <h4>No Record Found</h4>
                </div>
              ) : (
                <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                  {data1.map((appointment) => (
                    <div
                      onClick={() => apointdeatsho(
                        appointment.payment_method,
                        appointment.date,
                        appointment.status,
                        appointment.day,
                        appointment.time,
                        appointment.doctor_name,
                        appointment.category,
                        appointment.doctor_address,
                        appointment.doctor_profile,
                        appointment.fee,
                        appointment.doc_insurance_fee,
                        appointment.user_name,
                        appointment.doctor_experience,
                        appointment.id,
                        appointment.type,
                        appointment.user_type,
                        appointment.doctor_id,
                        appointment.doctor_email,
                        appointment.doc_fcm_token,
                        appointment.doc_web_fcm,
                        'detail'
                      )}
                      key={appointment.appointment_id}
                      className='upcomngmain'
                    >
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {appointment.doctor_profile != null ? (
                            <img
                              className='upcomimge'
                              src={Image_connection + appointment.doctor_profile}
                              alt="profile"
                            />
                          ) : (
                            <img
                              className='upcomimge'
                              src={require('../../assets/empty.png')}
                              alt="empty"
                            />
                          )}
                          <div className='upcmnginsde'>
                            <p className='poftext'>
                              {appointment.doctor_name}
                              {appointment.user_type === 'hisd' && (
                                <span className='spanoftet'>(FREE HISD)</span>
                              )}
                            </p>
                            {appointment.type == 'urgent' &&
                              <p className='clicoronlne'>Urgent Care</p>
                            }
                            {appointment.type === 'offline' && (
                              <p className='clicoronlne'>Clinic Appointment</p>
                            )}
                            {appointment.type === 'online' && (
                              <p className='clicoronlne'>Schedule Appointment</p>
                            )}
                          </div>
                        </div>
                        <p className='feofupcom'>${appointment.fee}</p>
                      </div>
                      <p className='datofupcome'>{appointment.date}</p>
                      <div className='mainoflastupco'>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          {appointment.type != 'urgent' &&
                            <AccessTimeIcon className='timeicon' />
                          }
                          <p className='timeoftextin'>{appointment.time}</p>
                        </div>
                        {appointment.status === 'pending' ? (
                          <p className='statusof'>● In-Process</p>
                        ) : (
                          <p className='statusof'>● Requested</p>
                        )}
                      </div>

                      {appointment.payment_method == 'Insurance' && <p style={{ color: '#00c480', fontSize: 15, marginHorizontal: 6, marginBottom: 2 }}>Insurance Appointment</p>}
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>


      <h4 className='howhelpdesgin'>How can we help you today?</h4>

      <header className='headr_second'>
        <div onClick={() => handleShow({ type_of: 'online', usertype: 'normal' })} className='video_consultation'>
          <h4>Schedule Appointment</h4>
          <h5>Verified Doctors</h5>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '95%', height: '85%' }}>
            <img className="image_consult" src={Online_forrr} />
          </div>
        </div>

        <div onClick={() => handleShow({ type_of: 'urgent', usertype: 'normal' })} className='onlyurgentsingle'>
          <h4>Urgent Care</h4>
          <h5 style={{ display: 'flex', alignItems: 'center', marginTop: -2 }}>Doctors Online Now <p style={{ color: '#00c480', fontSize: 16, marginLeft: 2 }}>●</p></h5>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%', height: '80%', marginTop: 2 }}>
            <img className="image_consult" src={online_cons} />
          </div>
        </div>

        {/* <div className='next_two_btn'>
          <div onClick={() => handleShow({ type_of: 'offline', usertype: 'normal' })} className='book_appointm_btn'>
            <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <div style={{ width: '80%', display: 'flex', flexDirection: 'column', marginTop: 10, marginLeft: 10 }}>
                <h4>In Person Clinic Appointment</h4>
                <h5>In-clinic Visit</h5>
              </div>

              <img className="image_book" src={local_image_doc1} />
            </div>
          </div>


          <div onClick={() => handleShow({ type_of: 'urgent', usertype: 'normal' })} className='free_consult_btn'>
            <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10, marginLeft: 10 }}>
                <h4>Urgent Care (No Wait)</h4>
                <h5>Doctors Online Now</h5>
              </div>

              <img className="image_free" src={online_cons} />
            </div>
          </div>
        </div> */}

        <div className='last_two_btn'>
          {do_free_hisd == 'true' ?
            <>
              {do_status == 'pending' ?
                <div onClick={() => workidalert()} className='instant_btn'>
                  <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20, marginTop: 10 }}>
                      <h3>FREE HISD Select Doctors</h3>
                      <h5>Free Hisd</h5>
                      <img className="houstonisd" src={HoustonISD} />
                    </div>

                    <img className="image_instan" src={free_hisd} />
                  </div>
                </div>
                :
                <div onClick={() => TypeModalShow({ usertype: 'hisd' })} className='instant_btn'>
                  <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20, marginTop: 10 }}>
                      <h3>FREE HISD Select Doctors</h3>
                      <h5>Free Hisd</h5>
                      <img className="houstonisd" src={HoustonISD} />
                    </div>

                    <img className="image_instan" src={free_hisd} />
                  </div>
                </div>
              }
            </>
            :
            <div className='instant_btn'>
              <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <h4>Free Hisd is</h4>
                <h4>not Available</h4>
              </div>
            </div>
          }

          <div onClick={() => goonmyapppointment()} className='free_instant_btn'>
            <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20, marginLeft: 20 }}>
                <h4>My Appointment</h4>
                <h5>My Requested Appointment</h5>
              </div>

              <img className="image_fre_instn" src={Frame_2} />
            </div>
          </div>
        </div>
      </header >


      {show == true &&
        <Modal_categ show={show} handleClose={handleClose} type_of={type_of} usertype={usertype}>
          <p>Modal Content Goes Here</p>
        </Modal_categ>
      }


      {
        typemodal == true &&
        <Type_Selection typemodal={typemodal} typeModalclose={typeModalclose} usertype={usertype} doctorId={doctorId} doctorcategory={doctorcategory}>
          <p>Modal Content Goes Here</p>
        </Type_Selection>
      }


      <section className="categories">
        <h4>Choose a Category</h4>
        <div className="category_container">
          <div className="category_item" onClick={() => TypeModalShow({ category: 'General Clinic', usertype: 'normal' })}>
            <div className='imageKo_circlw'>
              <img className="Icon" src={Physician1} alt={"Category"} />
            </div>
            <h3>General Clinic</h3>
          </div>

          <div className="category_item" onClick={() => TypeModalShow({ category: 'Dentist', usertype: 'normal' })}>
            <div className='imageKo_circlw'>
              <img className="Icon" src={oral} alt={"Category"} />
            </div>
            <h3>Dentist</h3>
          </div>

          <div className="category_item" onClick={() => TypeModalShow({ category: 'Cardiology', usertype: 'normal' })}>
            <div className='imageKo_circlw'>
              <img className="Icon" src={heart} alt={"Category"} />
            </div>
            <h3>Cardiology</h3>
          </div>

          <div className="category_item" onClick={() => TypeModalShow({ category: 'Pediatric', usertype: 'normal' })}>
            <div className='imageKo_circlw'>
              <img className="Icon" src={Pediatric1} alt={"Category"} />
            </div>
            <h3>Pediatric</h3>
          </div>

          <div className="category_item" onClick={() => TypeModalShow({ category: 'Dermatology', usertype: 'normal' })}>
            <div className='imageKo_circlw'>
              <img className="Icon" src={Dermotoglist1} alt={"Category"} />
            </div>
            <h3>Dermatology</h3>
          </div>

          <div className="category_item" onClick={() => TypeModalShow({ category: 'Orthopedician', usertype: 'normal' })}>
            <div className='imageKo_circlw'>
              <img className="Icon" src={ortho} alt={"Category"} />
            </div>
            <h3>Orthopedician</h3>
          </div>

          <div className="category_item" onClick={() => TypeModalShow({ category: 'Neurologists', usertype: 'normal' })}>
            <div className='imageKo_circlw'>
              <img className="Icon" src={brain} alt={"Category"} />
            </div>
            <h3>Neurologists</h3>
          </div>

          <div className="category_item" onClick={() => TypeModalShow({ category: 'Mental Health', usertype: 'normal' })}>
            <div className='imageKo_circlw'>
              <img className="Icon" src={Mental_Health} alt={"Category"} />
            </div>
            <h3>Mental Health</h3>
          </div>

          <div className="category_item" onClick={() => TypeModalShow({ category: 'Therapist', usertype: 'normal' })}>
            <div className='imageKo_circlw'>
              <img className="Icon" src={terapist} alt={"Category"} />
            </div>
            <h3>Therapist</h3>
          </div>

        </div>
      </section>



      <div className='new_doc_section'>
        <h3>All Sponsored Clinics</h3>

        <div className='all_doc_card'>

          {skalton == true ?
            <SkeletonTheme baseColor="lightgray" highlightColor="#bebebe">
              <div className='all_doc_card'>
                <Skeleton className='singleskelsame' />
                <Skeleton className='singleskelsame' />
                <Skeleton className='singleskelsame' />
              </div>
            </SkeletonTheme>
            :
            <>
              {doctor != '' ?
                <>
                  {doctor.map((row) => {
                    return (
                      <div key={row.id}>
                        <div className='one_docctor' onClick={() => TypeModalShow({ id: row.id, usertype: 'normal' })}>
                          <div className='img_nd_name'>
                            {row.profile == null ?
                              <div className='onedocimagemain'>
                                <img className="image_doctors" src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIEFLVeftvEbJfqWQ2hnRMSPuF_b8VMn4PdPhXOJdkPm-bfdpWTZZYAUJyHVXS4wrsDAE&usqp=CAU"} alt={"Profile"} />
                              </div>
                              :
                              <div className='onedocimagemain'>
                                <img className="image_doctors" src={Image_connection + row.profile} />
                              </div>
                            }

                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 15 }}>
                              <h4>Dr. {row.name}</h4>
                              <h5>{row.category}</h5>
                              <h6>{row.experience} years experience</h6>
                            </div>
                          </div>

                          <div className='reviw_nd_fee'>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#ffd700' }}>
                              <StarIcon color='#ffd700' />
                              <h4>4.9/5</h4>
                            </div>

                            <h4>$ {row.fee}</h4>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </>
                :
                <div style={{ width: '100%', textAlign: 'center', marginTop: 10, marginBottom: 30 }}>
                  <p>No Record Found</p>
                </div>
              }
            </>

          }

        </div>
      </div>


      {/* <hr style={{width:'100%',marginTop:60,marginBottom:30}}/> */}

      {/* Doctor Section */}
      <div className='doc_text_sect'>
        <h4>All Doctors</h4>
      </div>
      {skalton == true ?
        <SkeletonTheme baseColor="lightgray" highlightColor="#bebebe">
          <div className='home_doctor_skelton_div'>
            <Skeleton className='singleskeltfdc' />
            <Skeleton className='singleskeltfdc' />
            <Skeleton className='singleskeltfdc' />
            <Skeleton className='singleskeltfdc' />
          </div>
        </SkeletonTheme>
        :
        <>

          {doctor != '' ?
            <div className="topdivhead">
              {doctor.map((row) => {

                // onClick={() => handleShow(row.id,row.name,row.fee,category)}
                return (
                  <div key={row.id} className="doctor-card" onClick={() => TypeModalShow({ id: row.id, usertype: 'normal' })}>
                    {row.profile == null ?
                      <div className="doctor-card-image-container">
                        <img className="doctor-card-image" src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIEFLVeftvEbJfqWQ2hnRMSPuF_b8VMn4PdPhXOJdkPm-bfdpWTZZYAUJyHVXS4wrsDAE&usqp=CAU"} alt={"HAMZA"} />
                      </div>
                      :
                      <div className="doctor-card-image-container">
                        <img className="doctor-card-image" src={Image_connection + row.profile} alt={"Profile"} />
                      </div>
                    }

                    <div className="doctor-card-details">
                      <h3 className="doctor-card-name">{row.name}</h3>
                      <p className="doctor-card-specialty">{row.category}</p>
                    </div>
                  </div>
                )
              })}
            </div>
            :
            <div style={{ marginTop: 40 }}>
              <p>No Record Found</p>
            </div>
          }
        </>
      }


      <hr style={{ width: '100%', marginTop: 90 }} />

      <div className="services-section">
        <div className="service">
          <i className="fa fa-stethoscope"></i>
          <h2>Doctors & Specialists</h2>
          <p>Find the best doctors and specialists in your area.</p>
        </div>
        <div className="service">
          <i className="fa fa-heartbeat"></i>
          <h2>Health Records & History</h2>
          <p>Keep track of your health records and history.</p>
        </div>
        <div className="service">
          <i className="fa fa-medkit"></i>
          <h2>Medications & Prescriptions</h2>
          <p>Manage your medications and prescriptions.</p>
        </div>
      </div>


      <hr style={{ width: '100%', marginTop: 30, marginBottom: 50 }} />


      <section className='playstore_appsign'>
        <img className="image_playstore" src={mobile_screen_shot} />

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <div className='name_ty_logo'>
            <div className='circule_for_logo'>
              <img className="imf_log" src={mass_1} />
            </div>
            <h4>MAS CLINICAS</h4>
          </div>

          <div className='twoo_three_divs'>
            <CheckIcon style={{ color: '#014e78', fontSize: 15 }} />
            <h5>Download the app</h5>
          </div>

          <div className='twoo_three_divs'>
            <CheckIcon style={{ color: '#014e78', fontSize: 15 }} />
            <h5>Book Appointment</h5>
          </div>

          <div className='twoo_three_divs'>
            <CheckIcon style={{ color: '#014e78', fontSize: 15 }} />
            <h5>Consult with Doctor</h5>
          </div>

          <div className='two_btn_goplay'>
            <div onClick={() => goonlinkapp("https://play.google.com/store/apps/details?id=com.masclinicas")} className='PlayStore'>
              <img className="playstore_img" src={playstore_icon} />
              <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10 }}>
                <h6>GET IT ON</h6>
                <h4>Google Play</h4>
              </div>
            </div>

            <div onClick={() => goonlinkapp("https://apps.apple.com/pk/app/mas-clinicas/id1631688867")} className='PlayStore'>
              <img className="playstore_img" src={Appstore_icon} />
              <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10 }}>
                <h6>Available on the</h6>
                <h4>App Store</h4>
              </div>
            </div>
          </div>

        </div>
      </section>


      <section className='footer_top'></section>
      <div className="footer_second_end">
        <div className="col-12 col-sm-12 col-lg-6 mb-4 order-0 order-sm-0"><a className="text-decoration-none" href="#"><img src="assets/img/gallery/footer-logo.png" height="51" alt="" /></a>
          <p className="p_app_name">Mas Clinicas</p>
          <p className="text_light">The world's most trusted <br />telehealth company.</p>
        </div>

        <div className='footor_inside'>
          <div className="col-6 col-sm-4 col-lg-2 mb-3 order-2 order-sm-1">
            <h5 className="Departments">Departments</h5>
            <ul className="list-unstyled mb-md-4 mb-lg-0">
              <li className="lh-lg"><a className="footer_link" href="#!">Eye care</a></li>
              <li className="lh-lg"><a className="footer_link" href="#!">Cardiac are</a></li>
              <li className="lh-lg"><a className="footer_link" href="#!">Heart care</a></li>
            </ul>
          </div>

          <div className="col-6 col-sm-4 col-lg-2 mb-3 order-3 order-sm-2">
            <h5 className="Departments">Membership</h5>
            <ul className="list-unstyled mb-md-4 mb-lg-0">
              <li className="lh-lg"><a className="footer_link" href="#!">Free trial</a></li>
              <li className="lh-lg"><a className="footer_link" href="#!">Silver</a></li>
              <li className="lh-lg"><a className="footer_link" href="#!">Premium</a></li>
            </ul>
          </div>

          <div className="col-6 col-sm-4 col-lg-2 mb-3 order-3 order-sm-2">
            <h5 className="Departments"> Customer Care</h5>
            <ul className="list-unstyled mb-md-4 mb-lg-0">
              <li className="lh-lg"><a className="footer_link" href="#!">About Us</a></li>
              <li className="lh-lg"><a className="footer_link" href="#!">Contact US</a></li>
              <li className="lh-lg"><a className="footer_link" href="#!">Get Update</a></li>
            </ul>
          </div>
        </div>

      </div>


      <section className='footer_end'>
        <p>All rights Reserved &copy; Your Company, 2021</p>

        {/* <div class="col-12 col-sm-8 col-md-6">
            <p class="fs--1 my-2 text-center text-md-end text-200"> Made with&nbsp;
              <svg class="bi bi-suit-heart-fill" xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="#F95C19" viewBox="0 0 16 16">
                <path d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z"></path>
              </svg>&nbsp;by&nbsp;<a class="fw-bold text-info" href="https://themewagon.com/" target="_blank">ThemeWagon </a>
            </p>
         </div> */}
      </section>

      {
        showScrollButton && (
          <button onClick={scrollToTop} className="scroll_top_button">
            <ArrowUpwardIcon />
          </button>
        )
      }


      {apointdetail == true &&
        <Modal_detail
          show={apointdetail}
          handleClose={handleapontdetalClose}
          payment_method={payment_method}
          date={date}
          status={status}
          day={day}
          time={time}
          doctor_name={doctor_name}
          category={category}
          doctor_address={doctor_address}
          doctor_profile={doctor_profile}
          fee={fee}
          user_name={user_name}
          doctor_experience={doctor_experience}
          appointment_id={appointment_id}
          app_type={app_type}
          user_type={user_type}
          doctor_id={doctor_id}
          doctor_email={doctor_email}
          fcm_token={fcm_token}
          web_fcm={web_fcm}
        >
          <p>Modal Content Goes Here</p>
        </Modal_detail>
      }

      {paymentOnn == true &&
        <Pay_insurance_optin
          show={paymentOnn}
          handleClose={handleapaymentclos}
          callfunction={display_appointments_patient_all}
          payment_method={payment_method}
          date={date}
          status={status}
          day={day}
          time={time}
          doctor_name={doctor_name}
          category={category}
          doctor_address={doctor_address}
          doctor_profile={doctor_profile}
          fee={fee}
          insurance_fee={insurance_fee}
          user_name={user_name}
          doctor_experience={doctor_experience}
          appointment_id={appointment_id}
          app_type={app_type}
          user_type={user_type}
          doctor_id={doctor_id}
          doctor_email={doctor_email}
          fcm_token={fcm_token}
          web_fcm={web_fcm}
        >
          <p>Modal Content Goes Here</p>
        </Pay_insurance_optin>
      }

    </div >
  );
}



const useStyles = makeStyles({
  topdivhead: {
    display: 'flex',
    width: '100%',
    gap: '15px',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    maxWidth: '100%',
    padding: '20px 55px 0px 55px'
  },
});
export default Home;