// import React, { useEffect, useState } from "react";
// import { Link, parsePath, useParams, useNavigate, useLocation } from "react-router-dom"
// import {
//   AgoraVideoPlayer,
//   createClient,
//   createMicrophoneAndCameraTracks,
// } from "agora-rtc-react";
// import './AgoraCall.css';
// import Connection from "../../connection";
// import PulseLoader from "react-spinners/PulseLoader";
// import Image_connection from "../../Image_connection";

// import CallEndIcon from '@mui/icons-material/CallEnd';
// import VolumeOffIcon from '@mui/icons-material/VolumeOff';
// import VolumeMuteIcon from '@mui/icons-material/VolumeMute';
// import VideocamOffIcon from '@mui/icons-material/VideocamOff';
// import VideocamIcon from '@mui/icons-material/Videocam';

// const config = {
//   mode: "rtc",
//   codec: "vp8",
// };

// const appId = "594113f10aac4bd2b7b66ac07ccad834";
// // const appId = "2c128d5ef8d7446dbf0c7ac51f5c2ae1"
// // const token = '006594113f10aac4bd2b7b66ac07ccad834IABr9aop3EhqHtou3Pq49KCNi7dDdPCljccr8rQwCAu0NxS9BBcAAAAAIgAUaV/ur3o2ZQQAAQA/NzVlAgA/NzVlAwA/NzVlBAA/NzVl';

// const AgoraCall = () => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const searchParams = new URLSearchParams(location.search);
//   const call_check = searchParams.get('call_check');
//   const fcm_token = searchParams.get('fcm_token');
//   const callertoken = searchParams.get('callertoken');

//   const [inCall, setInCall] = useState(false);
//   const [spinner, setSpinner] = useState(true);
//   const [token, setToken] = useState("");

//   const [channelName, setChannelName] = useState("channel_sit");

//   const useClient = createClient(config);
//   const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks();

//   const VideoCall = (props) => {
//     const { setInCall, channelName } = props;
//     const [users, setUsers] = useState([]);
//     const [start, setStart] = useState(false);
//     const client = useClient();
//     const { ready, tracks } = useMicrophoneAndCameraTracks();

//     useEffect(() => {
//       console.log("Debug: channelName, ready, tracks, start", channelName, ready, tracks, start);
//       const init = async (name) => {
//         client.on("user-published", async (user, mediaType) => {
//           await client.subscribe(user, mediaType);
//           console.log("subscribe success");
//           if (mediaType === "video") {
//             setUsers((prevUsers) => [...prevUsers, user]);
//           }
//           if (mediaType === "audio") {
//             user.audioTrack.play();
//           }
//         });

//         client.on("user-unpublished", (user, type) => {
//           console.log("unpublished", user, type);
//           if (type === "audio") {
//             user.audioTrack.stop();
//           }
//           if (type === "video") {
//             setUsers((prevUsers) =>
//               prevUsers.filter((User) => User.uid !== user.uid)
//             );
//           }
//         });

//         client.on("user-left", (user) => {
//           console.log("leaving", user);
//           setUsers((prevUsers) =>
//             prevUsers.filter((User) => User.uid !== user.uid)
//           );
//         });

//         await client.join(appId, name, token, null);
//         if (tracks) {
//           await client.publish([tracks[0], tracks[1]]);
//         }
//         setStart(true);
//       };

//       if (ready && tracks && !start) {
//         setTimeout(() => {
//           init(channelName);
//         }, 1000); // Delay execution by 1 second
//       }
//     }, [channelName, client, ready, tracks, start, token]);

//     return (
//       <div className="App">
//         {ready && tracks && (
//           <Controls tracks={tracks} setStart={setStart} setInCall={setInCall} />
//         )}
//         {start && tracks && <Videos users={users} tracks={tracks} />}
//       </div>
//     );
//   };

//   const Videos = (props) => {
//     const { users, tracks } = props;

//     return (
//       <div id="videos">
//         <AgoraVideoPlayer className="vid" videoTrack={tracks[1]} />
//         {users.length > 0 &&
//           users.map((user) => {
//             if (user.videoTrack) {
//               return (
//                 <AgoraVideoPlayer
//                   className="vid"
//                   videoTrack={user.videoTrack}
//                   key={user.uid}
//                 />
//               );
//             } else return null;
//           })}
//       </div>
//     );
//   };

//   const Controls = (props) => {
//     const client = useClient();
//     const { tracks, setStart, setInCall } = props;
//     const [trackState, setTrackState] = useState({ video: true, audio: true });

//     const mute = async (type) => {
//       if (type === "audio") {
//         await tracks[0].setEnabled(!trackState.audio);
//         setTrackState((ps) => ({
//           ...ps,
//           audio: !ps.audio,
//         }));
//       } else if (type === "video") {
//         await tracks[1].setEnabled(!trackState.video);
//         setTrackState((ps) => ({
//           ...ps,
//           video: !ps.video,
//         }));
//       }
//     };

//     const leaveChannel = async () => {
//       try {
//         if (client) {
//           await client.leave();
//           client.removeAllListeners();
//         }

//         if (tracks[0]) {
//           await tracks[0].setEnabled(false);
//           await tracks[0].stop(); // Stop the audio track
//           tracks[0].close();
//         }

//         if (tracks[1]) {
//           await tracks[1].setEnabled(false);
//           await tracks[1].stop(); // Stop the video track
//           tracks[1].close();
//         }

//         setStart(false);
//         setInCall(false);
//         navigate("/")
//         window.location.reload(true);
//       } catch (error) {
//         console.log("Error leaving channel:", error);
//       }
//     };
//     // const leavedChannel = async () => {
//     //   await client.leave();
//     //   client.removeAllListeners();

//     //   if (tracks[0]) {
//     //     await tracks[0].setEnabled(false); // Mute the audio before closing
//     //     tracks[0].close();
//     //   }

//     //   if (tracks[1]) {
//     //     await tracks[1].setEnabled(false); // Mute the video before closing
//     //     tracks[1].close();
//     //   }

//     //   setStart(false);
//     //   setInCall(false);
//     // };


//     return (
//       <div className="controls">
//         <p className={trackState.audio ? "on" : ""} onClick={() => mute("audio")}>
//           {trackState.audio ?
//             <VolumeOffIcon />
//             :
//             <VolumeMuteIcon />
//           }
//         </p>

//         {<button className="callendbutton" onClick={() => leaveChannel()}><CallEndIcon style={{ color: 'white' }} /></button>}

//         <p className={trackState.video ? "on" : ""} onClick={() => mute("video")}>
//           {trackState.video ?
//             <VideocamOffIcon />
//             :
//             <VideocamIcon />
//           }
//         </p>
//       </div>
//     );
//   };

//   const ChannelForm = (props) => {
//     const { setInCall } = props;
//     const [loading, setLoading] = useState(false);

//     useEffect(() => {
//       const auth = localStorage.getItem("customer");
//       const parsed = JSON.parse(auth);
//       const sender_name = parsed[0].name;

//       if (call_check === 'do_call') {
//         get_token(sender_name);
//       }
//       else {
//         setToken(callertoken);
//         setInCall(true);
//       }
//     }, []);

//     const get_token = async (sender_name) => {
//       setLoading(true)

//       let channel_Name = sender_name + '123'
//       console.log('channel_Name =>', channel_Name);

//       let result = await fetch(Connection + "rtcctoken", {
//         method: 'post',
//         body: JSON.stringify({ channel_Name }),
//         headers: {
//           "Content-Type": 'application/json'
//         }
//       })
//       const response = await result.json();
//       console.log('Token =>', response.tokenA);

//       setToken(response.tokenA);
//       setLoading(false);
//       setInCall(true);

//       agora_notification(sender_name, response.tokenA)
//     };


//     const agora_notification = async (sender_name, tokenA) => {
//       let to = fcm_token
//       console.log("fcm_token on callscreen =>", to)
//       // let to = "eQ8cD0iPh7y1lq0elvMbrh:APA91bEohvAZa-8CC2jU2WN_B12RmVr_wkTdzzm4wVrJLBOvPK6kJc5sT1zfPaL3tsYP6Jc0br4wuX0JP4xR1x3aKwC4-tDcW6bUZc5Fpeb6keaxeYE2OkRe_Rg---3AOJUvBtmI9MZh";

//       // this.timerfunction()

//       fetch('https://fcm.googleapis.com/fcm/send', {
//         method: 'POST',
//         headers: {
//           Accept: 'application/json',
//           'Content-Type': 'application/json',
//           'Authorization': 'key = AAAAPzWsmh8:APA91bGwGLlejpat2ndQPc3708f5KqvTPRFm9oZ7RQyBB9khWgUn_2JFZJdIf5Lta7dS2M9wTj_OYaxxmZ3FRVckZGoDJpEWyUC81G1hJliAhiKUGaZJS0P3cP-T_Z5VwGKPQzNsk_75'//cloud server key
//         },
//         body: JSON.stringify({
//           to: to,
//           priority: 'high',
//           content_available: true,
//           mutable_content: true,
//           apns: {
//             payload: {
//               aps: {
//                 'content-available': 1,
//               },
//             },
//             headers: {
//               'apns-push-type': 'voip',
//               'apns-priority': '5',
//               'apns-topic': 'your.bundle.identifier',
//             },
//           },
//           data: {
//             title: "Incoming Call",
//             body: "Video Call...",
//             sound: 'default',
//             icon: 'myicon',
//             "username": sender_name,
//             "userprofile": 'image',
//             "tokenA": tokenA,
//           },
//         }),
//       })
//         .then(res => res.json())
//         .then(resjson => console.log("test", resjson))
//         .catch(err => console.log("error =>", err));
//     };

//     const send_notification = async () => {
//       let to = 'eQ8cD0iPh7y1lq0elvMbrh:APA91bEohvAZa-8CC2jU2WN_B12RmVr_wkTdzzm4wVrJLBOvPK6kJc5sT1zfPaL3tsYP6Jc0br4wuX0JP4xR1x3aKwC4-tDcW6bUZc5Fpeb6keaxeYE2OkRe_Rg---3AOJUvBtmI9MZh';
//       console.log("To is => ", to)

//       fetch('https://fcm.googleapis.com/fcm/send', {
//         method: 'POST',
//         headers: {
//           Accept: 'application/json',
//           'Content-Type': 'application/json',
//           'Authorization': 'key= AAAAPzWsmh8:APA91bGwGLlejpat2ndQPc3708f5KqvTPRFm9oZ7RQyBB9khWgUn_2JFZJdIf5Lta7dS2M9wTj_OYaxxmZ3FRVckZGoDJpEWyUC81G1hJliAhiKUGaZJS0P3cP-T_Z5VwGKPQzNsk_75'//cloud server key
//         },
//         body: JSON.stringify({
//           "to": to,
//           "priority": "high",
//           "notification": {
//             "title": "Mas Clinicas",
//             "body": "Dr." + " is complete appointment with you.",
//             "sound": 'default',
//             "icon": "myicon",
//           }
//         }
//         )
//       }).then(res => res.json())
//         .then(resjson => console.log("test", resjson))
//         .catch(err => console.log("error =>", err))
//     }


//     const joinCall = () => {
//       if (!loading && token) {
//         setInCall(true);
//       }
//     };

//     return (
//       <form className="join">
//         {appId === "" && (
//           <p style={{ color: "red" }}>
//             Please enter your Agora App ID in App.js and refresh the page
//           </p>
//         )}

//         <div>
//           <button disabled={loading}>
//             <PulseLoader
//               color={"white"}
//               loading={loading}
//               aria-label="Loading Spinner"
//               data-testid="loader"
//             />
//           </button>
//         </div>
//       </form>
//     );
//   };

//   return (
//     <div>
//       {inCall ? (
//         <VideoCall setInCall={setInCall} channelName={channelName} />
//       ) : (
//         <ChannelForm setInCall={setInCall} setChannelName={setChannelName} />
//       )}
//     </div>
//   );
// };

// export default AgoraCall;












import React, { useEffect, useState, useRef } from "react";
import { Link, parsePath, useParams, useNavigate, useLocation } from "react-router-dom"
import {
  AgoraVideoPlayer,
  createClient,
  createMicrophoneAndCameraTracks,
} from "agora-rtc-react";
import './AgoraCall.css';
import Connection from "../../connection";
import Image_connection from "../../Image_connection";
import PulseLoader from "react-spinners/PulseLoader";

import CallEndIcon from '@mui/icons-material/CallEnd';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeMuteIcon from '@mui/icons-material/VolumeMute';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import VideocamIcon from '@mui/icons-material/Videocam';

const config = {
  mode: "rtc",
  codec: "vp8",
};

const appId = "594113f10aac4bd2b7b66ac07ccad834";
let intervalId;
// const appId = "2c128d5ef8d7446dbf0c7ac51f5c2ae1"
// const token = '006594113f10aac4bd2b7b66ac07ccad834IABr9aop3EhqHtou3Pq49KCNi7dDdPCljccr8rQwCAu0NxS9BBcAAAAAIgAUaV/ur3o2ZQQAAQA/NzVlAgA/NzVlAwA/NzVlBAA/NzVl';
export default function AgoraCall() {

  const usersRef = useRef([]);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const call_check = searchParams.get('call_check');
  const fcm_token = searchParams.get('fcm_token');
  const web_fcm = searchParams.get('web_fcm');
  const callertoken = searchParams.get('callertoken');

  const [loading, setLoading] = useState(false);
  const [inCall, setInCall] = useState(false);
  const [token, setToken] = useState("");

  const [channelName, setChannelName] = useState("channel_sit");

  const useClient = createClient(config);
  const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks();

  const [users, setUsers] = useState([]);
  const [start, setStart] = useState(false);
  const client = useClient();
  const { ready, tracks } = useMicrophoneAndCameraTracks();

  const [trackState, setTrackState] = useState({ video: true, audio: true });

  useEffect(() => {
    const auth = localStorage.getItem("customer");
    const parsed = JSON.parse(auth);
    const sender_name = parsed[0].name;

    let profile1 = parsed[0].profile;
    let image1;
    if (profile1 == null) {
      image1 = null
    }
    else {
      image1 = Image_connection + profile1
    }

    // if (call_check === 'do_call') {
    get_token(sender_name, image1);
    // }
    // else {
    //   User_accept()
    // }
  }, [call_check]);


  useEffect(() => {
    console.log("Debug: channelName, ready, tracks, start", channelName, ready, tracks, start);
    const init = async (name) => {
      client.on("user-published", async (user, mediaType) => {
        await client.subscribe(user, mediaType);
        console.log("subscribe success");
        if (mediaType === "video") {
          setUsers((prevUsers) => [...prevUsers, user]);
          usersRef.current = [...usersRef.current, user];
        }
        if (mediaType === "audio") {
          user.audioTrack.play();
        }
      });

      client.on("user-unpublished", (user, type) => {
        console.log("unpublished", user, type);
        if (type === "audio") {
          user.audioTrack.stop();
        }
        if (type === "video") {
          setUsers((prevUsers) =>
            prevUsers.filter((User) => User.uid !== user.uid)
          );
        }
      });

      client.on("user-left", (user) => {
        console.log("leaving", user);
        setUsers((prevUsers) =>
          prevUsers.filter((User) => User.uid !== user.uid)
        );
      });

      await client.join(appId, name, token, null);
      if (tracks) {
        await client.publish([tracks[0], tracks[1]]);
      }
      setStart(true);
    };

    if (token && ready && tracks && !start) {
      init(channelName);
    }
  }, [channelName, client, ready, tracks, start, token]);

  const User_accept = async () => {
    setLoading(true)
    setToken(callertoken);

    setTimeout(() => {
      setLoading(false);
      setInCall(true);
      console.log('channel_Name =>', callertoken);
    }, 1000);
  }

  const get_token = async (sender_name, image1) => {
    setLoading(true)

    let channel_Name = sender_name + '123'
    console.log('channel_Name =>', channel_Name);

    let result = await fetch(Connection + "rtcctoken", {
      method: 'post',
      body: JSON.stringify({ channel_Name }),
      headers: {
        "Content-Type": 'application/json'
      }
    })
    const response = await result.json();
    console.log('Token =>', response.tokenA);

    if (response.tokenA) {
      setToken(response.tokenA);
      setLoading(false);
      setInCall(true);

      if (call_check === 'do_call') {
        timerfunction()
        web_agora_notification(sender_name, image1, response.tokenA)
        app_agora_notification(sender_name, image1, response.tokenA)
      }
    }

  };


  const web_agora_notification = async (sender_name, image1, tokenA) => {
    let to = web_fcm

    fetch('https://fcm.googleapis.com/fcm/send', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'key = AAAAPzWsmh8:APA91bGwGLlejpat2ndQPc3708f5KqvTPRFm9oZ7RQyBB9khWgUn_2JFZJdIf5Lta7dS2M9wTj_OYaxxmZ3FRVckZGoDJpEWyUC81G1hJliAhiKUGaZJS0P3cP-T_Z5VwGKPQzNsk_75'//cloud server key
      },
      body: JSON.stringify({
        to: to,
        priority: 'high',
        content_available: true,
        mutable_content: true,
        apns: {
          payload: {
            aps: {
              'content-available': 1,
            },
          },
          headers: {
            'apns-push-type': 'voip',
            'apns-priority': '5',
            'apns-topic': 'your.bundle.identifier',
          },
        },
        data: {
          title: "Incoming Call",
          body: "Video Call...",
          sound: 'default',
          icon: 'myicon',
          "username": sender_name,
          "userprofile": image1,
          "tokenA": tokenA,
        },
      }),
    })
      .then(res => res.json())
      .then(resjson => console.log("test", resjson))
      .catch(err => console.log("error =>", err));
  };


  const app_agora_notification = async (sender_name, image1, tokenA) => {
    let to = fcm_token;

    fetch('https://fcm.googleapis.com/fcm/send', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'key = AAAAaCD4Bcc:APA91bETamRJX5KYdE6sCHUzquj_DpmfqiqH3ogXH1K1m1SH5U5_60OPWrPP5hHMyq_1pQcUN2WMWAPpCOzFDJJ4lDt6X3IDNwLuohzTB8ja3EDGM-FDTePfmCEYpFTsUoH-wguvF6Ux'//cloud server key
      },
      body: JSON.stringify({
        to: to,
        priority: 'high',
        content_available: true,
        mutable_content: true,
        apns: {
          payload: {
            aps: {
              'content-available': 1,
            },
          },
          headers: {
            'apns-push-type': 'voip',
            'apns-priority': '5',
            'apns-topic': 'your.bundle.identifier',
          },
        },
        data: {
          title: "Incoming Call",
          body: "Video Call...",
          sound: 'default',
          icon: 'myicon',
          "username": sender_name,
          "userprofile": image1,
          "tokenA": tokenA,
        },
      }),
    })
      .then(res => res.json())
      .then(resjson => console.log("test", resjson))
      .catch(err => console.log("error =>", err));
  };


  const timerfunction = () => {
    const startTime = Date.now();

    intervalId = setInterval(() => {
      const elapsedTime = Date.now() - startTime;

      if (elapsedTime >= 30000) {
        console.log("User =>", usersRef.current);
        if (usersRef.current.length === 0) {
          leaveChannel()
        }
        else { }

        clearInterval(intervalId);
        console.log("timer close")
      }

      console.log("timer start")
    }, 10000);
  }

  const VideoCall = () => {
    return (
      <div className="App">
        {start && tracks &&
          <div id="videos">
            <AgoraVideoPlayer className="vid" videoTrack={tracks[1]} />
            {users.length > 0 &&
              users.map((user) => {
                if (user.videoTrack) {
                  return (
                    <AgoraVideoPlayer
                      className="vid"
                      videoTrack={user.videoTrack}
                      key={user.uid}
                    />
                  );
                } else return null;
              })}
          </div>
        }


        {ready && tracks && (
          <div className="controls">
            <p className={trackState.audio ? "on" : ""} onClick={() => mute("audio")}>
              {trackState.audio ?
                <VolumeOffIcon />
                :
                <VolumeMuteIcon />
              }
            </p>

            {<button className="callendbutton" onClick={() => leaveChannel()}><CallEndIcon style={{ color: 'white' }} /></button>}

            <p className={trackState.video ? "on" : ""} onClick={() => mute("video")}>
              {trackState.video ?
                <VideocamOffIcon />
                :
                <VideocamIcon />
              }
            </p>
          </div>
        )}
      </div>
    );
  };



  const mute = async (type) => {
    if (type === "audio") {
      await tracks[0].setEnabled(!trackState.audio);
      setTrackState((ps) => ({
        ...ps,
        audio: !ps.audio,
      }));
    } else if (type === "video") {
      await tracks[1].setEnabled(!trackState.video);
      setTrackState((ps) => ({
        ...ps,
        video: !ps.video,
      }));
    }
  };

  const leaveChannel = async () => {
    try {
      if (client) {
        await client.leave();
        client.removeAllListeners();
      }

      if (tracks && tracks[0]) {
        await tracks[0].setEnabled(false);
        await tracks[0].stop(); // Stop the audio track
        tracks[0].close();
      }

      if (tracks && tracks[1]) {
        await tracks[1].setEnabled(false);
        await tracks[1].stop(); // Stop the video track
        tracks[1].close();
      }

      clearInterval(intervalId);
      setStart(false);
      setInCall(false);
      navigate("/")
      window.location.reload(true);
    } catch (error) {
      console.log("Error leaving channel:", error);
    }
  };



  return (
    <div>
      {loading == true ?
        <form className="join">
          {appId === "" && (
            <p style={{ color: "red" }}>
              Please enter your Agora App ID in App.js and refresh the page
            </p>
          )}

          <div>
            <button disabled={loading}>
              <PulseLoader
                color={"white"}
                loading={loading}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </button>
          </div>
        </form>
        :
        <>
          {inCall &&
            <>
              {VideoCall()}
            </>
          }
        </>
      }
    </div>
  );
};