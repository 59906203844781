import React, { useState, useEffect } from 'react'
import Connection from "../../connection";
import Image_connection from "../../Image_connection";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import Doctor_consult_details from '../../MyComponent/Doctor_consult_details/Doctor_consult_details';
import 'react-drawer/lib/react-drawer.css';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import moment from 'moment';

export default function All_consult_doctor_site() {

    const [skalton, setSkalton] = React.useState(true);
    const [data1, setData1] = useState([]);
    const [pen_arr, setPen_arr] = useState([]);
    const [com_arr, setCom_arr] = useState([]);

    const [show, setShow] = useState(false);


    const [sender_id, setSender_id] = React.useState('');
    const [fee, setFee] = React.useState('');
    const [status, setStatus] = React.useState('');
    const [user_address, setUser_address] = React.useState('');
    const [meeting_id, setMeeting_id] = React.useState('');
    const [receiver_id, setReceiver_id] = React.useState('')
    const [receiver_name, setReceiver_name] = React.useState('');
    const [user_email, setUser_email] = React.useState('');
    const [user_mobile, setUser_mobile] = React.useState('');
    const [sender_name, setSender_name] = React.useState('');
    const [receiver_image, setReceiver_image] = React.useState('');
    const [sender_image, setSender_image] = React.useState('');
    const [fcm_token, setFcm_token] = React.useState('');
    const [web_fcm, setWeb_fcm] = React.useState('');
    const [user_online, setUser_online] = React.useState('');
    const [date2, setDate2] = React.useState('');
    const [app_type, setApp_type] = React.useState('');
    const [user_type, setUser_type] = React.useState('');
    const [active, setActive] = React.useState('');
    const [payment_method, setPayment_method] = React.useState('');

    // Change Btn
    const [text1, setText1] = useState(2);
    const [text2, setText2] = useState(1);
    const [checkDesign, setCheckDesign] = useState('pending');

    const changeBtn = (text, val) => {
        setText1(1);
        setText2(1);

        if (text === "text1") {
            setText1(2);
        }
        else if (text === "text2") {
            setText2(2);
        }

        setCheckDesign(val);

        if (val == "pending") {
            setData1(pen_arr)
        }
        else if (val == "complete") {
            setData1(com_arr)
        }
    };
    /////////////////////////



    useEffect(() => {
        window.scrollTo(0, 0);

        const auth = localStorage.getItem("customer");
        const parsed = JSON.parse(auth);
        const id = parsed[0].id;

        setSender_id(id)
        getProducts(id)
    }, [])

    const getProducts = async (id) => {
        let result = await fetch(Connection + `requested_appointments_to_doctor_all_p/${id}`, {

        })
        result = await result.json()
        const record4 = result.response;
        let len = record4.length

        console.warn(record4)

        if (record4) {
            const data_1 = [];

            for (let i = 0; i < len; i++) {
                data_1.push(record4[i]);
            }


            setData1(data_1.filter((record) => record.status === 'pending' && record.type == 'urgent' && record.u_user_id !== null));
            setPen_arr(data_1.filter((record) => record.status === 'pending' && record.type == 'urgent' && record.u_user_id !== null));
            setCom_arr(data_1.filter((record) => record.status === 'complete' && record.type == 'urgent' && record.u_user_id !== null));

            setSkalton(false);
        }
        else {
            setSkalton(false);
            setData1([]);
        }
    }


    const handleButtonClick = (fee, status, user_address, id, user_id, user_name, user_email, user_mobile, doctor_name, user_profile, doctor_profile, user_fcm_token, user_web_fcm, user_online, date2, type, user_type, active, paymentmethod) => {
        document.body.style.overflow = 'hidden';

        setFee(fee)
        setStatus(status)
        setUser_address(user_address)
        setMeeting_id(id)
        setReceiver_id(user_id)
        setReceiver_name(user_name)
        setUser_email(user_email)
        setUser_mobile(user_mobile)
        setSender_name(doctor_name)
        setReceiver_image(user_profile)
        setSender_image(doctor_profile)
        setFcm_token(user_fcm_token)
        setWeb_fcm(user_web_fcm)
        setUser_online(user_online)
        setDate2(date2)
        setApp_type(type)
        setUser_type(user_type)
        setActive(active)
        setPayment_method(paymentmethod)

        setShow(true);
    };

    const handleClose = () => {
        document.body.style.overflow = 'auto';
        setShow(false);
    };


    return (

        <div className='consult_patient_startup'>

            <div className='consult_toptwo_tutton'>
                <button onClick={() => changeBtn('text1', 'pending')} className={text1 === 1 ? 'inactiveProcess' : 'activeProcess'}>
                    <span className={text1 === 1 ? 'inprocesstext' : 'processtext'}>{'In-Process'}</span>
                </button>

                <button onClick={() => changeBtn('text2', 'complete')} className={text2 === 1 ? 'inactiveProcess' : 'activeProcess'}>
                    <span className={text2 === 1 ? 'inprocesstext' : 'processtext'}>{'Completed'}</span>
                </button>
            </div>

            {skalton == true ?
                <SkeletonTheme baseColor="lightgray" highlightColor="#bebebe">
                    <div className='skelton_appoint_div'>
                        <Skeleton count={2} className='sklnconsltheight' />
                    </div>
                </SkeletonTheme>
                :
                <>
                    {data1 != '' ?
                        <>
                            {data1.map((row) => {
                                const appointment_id = row.id;

                                let date = row.auto_date
                                const formattedDateTime = moment(date).format('MMMM DD, YYYY, hh:mm A');
                                let date1 = date.split(" ")
                                let date2 = date1[0]
                                return (
                                    <>
                                        {checkDesign == row.status &&
                                            <div key={appointment_id} className='mainpatientinside'>
                                                <div className='firstrowContainer'>
                                                    <div className='headnameconst'>
                                                        <span id='userconsult'>Dr. {row.doctor_name}</span>
                                                        <span id='datee2'> {date2}</span>
                                                    </div>
                                                    <button className='borderedButton'
                                                        onClick={() => handleButtonClick(
                                                            row.fee,
                                                            row.status,
                                                            row.user_address,
                                                            row.id,
                                                            row.user_id,
                                                            row.user_name,
                                                            row.user_email,
                                                            row.user_mobile,
                                                            row.doctor_name,
                                                            row.user_profile,
                                                            row.doctor_profile,
                                                            row.user_fcm_token,
                                                            row.user_web_fcm,
                                                            row.user_online,
                                                            date2,
                                                            row.type,
                                                            row.user_type,
                                                            row.active,
                                                            row.payment_method
                                                        )}
                                                    >
                                                        {row.status === 'complete' ?
                                                            <span style={{ color: 'black', fontWeight: 'bold' }}>More Details</span>
                                                            :
                                                            <span style={{ color: 'black', fontWeight: 'bold' }}>START VISIT</span>
                                                        }
                                                    </button>
                                                </div>

                                                <div className='profileContainer'>
                                                    <div className='profileImageContainer'>
                                                        {row.user_profile !== null ? (
                                                            <img
                                                                className='imagemainin'
                                                                src={Image_connection + row.user_profile}
                                                                alt="Profile"
                                                            />
                                                        ) : (
                                                            <img
                                                                className='imagemainin'
                                                                src={require('../../assets/empty.png')}
                                                                alt="Empty"
                                                            />
                                                        )}
                                                    </div>

                                                    <div className='columnContainer'>
                                                        <span id='doctornaemconslt'>{row.user_name}</span>
                                                        <span id='docadrsconslt'>{row.user_address}</span>
                                                    </div>
                                                </div>

                                                <div className='dateTimeContainer'>
                                                    <span id='Calendarmonthcon'><CalendarMonthIcon /></span>
                                                    <span id='Calendarmonthcon'> {formattedDateTime}</span>
                                                </div>

                                                <div className='statusContainer'>
                                                    <span id='feeinconsult'>${row.fee}</span>
                                                    <span id='dowithstatus'>
                                                        <span style={{ color: '#00c480', fontSize: 15 }}>●</span>{' '}
                                                        {row.status === 'pending' ? 'In-Process' : 'Showed-up'}
                                                    </span>
                                                </div>

                                                {row.payment_method == 'Insurance' && <p style={{ color: '#00c480', fontSize: 14, fontWeight: 'bold' }}>Insurance Urgent Visit</p>}
                                            </div>
                                        }
                                    </>
                                )
                            })}
                        </>
                        :
                        <div style={{ width: '100%', height: '60vh', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                            <p>No Record Found</p>
                        </div>
                    }
                </>
            }




            {show == true &&
                <Doctor_consult_details show={show} handleClose={handleClose} getProducts={getProducts} fee={fee} status={status} user_address={user_address} meeting_id={meeting_id} receiver_id={receiver_id} receiver_name={receiver_name} user_email={user_email} user_mobile={user_mobile} sender_id={sender_id} sender_name={sender_name} receiver_image={receiver_image} sender_image={sender_image} fcm_token={fcm_token} web_fcm={web_fcm} user_online={user_online} date2={date2} app_type={app_type} user_type={user_type} active={active} payment_method={payment_method}>
                    <p>Modal Content Goes Here</p>
                </Doctor_consult_details>
            }

        </div>
    );
}