import React, { useState, useEffect, useContext } from 'react'
import { Link, useNavigate } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GlobalInfo } from "../../App"
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import Connection from '../../connection';
import Modal_Payment from '../Modal_Payment/Modal_Payment';
import PulseLoader from "react-spinners/PulseLoader";

function Pay_insurance_optin({ handleClose, day, date, time, doctor_id, doctor_name, insurance_fee, fee, category, city, reason, symptoms, issue, allergic, all_promos, email, fcm_token, web_fcm, type_of, usertype, appointment_id, callfunction }) {
    const { insure_check } = useContext(GlobalInfo)
    console.log("insure_check", insure_check)

    const navigate = useNavigate();
    const [showpayment, setShowpayment] = useState(false);
    const [ad_insurace, setAd_insurace] = useState(false);

    const [spinner, setSpinner] = useState(false);
    const [p_code, setP_code] = useState("");

    const [user_id, setUser_id] = useState("");
    const [balance, setBalance] = useState("");
    const [total_fee, setTotal_fee] = useState("");
    const [method, setMethod] = useState("");
    const [promo_add, setPromo_add] = useState('');
    const [promo_text, setPromo_text] = useState(false);
    const [percentage, setPercentage] = useState("");
    const [promo_end, setPromo_end] = useState('false');


    // Dialog PopUp
    const [showDialog, setShowDialog] = useState(false);
    const handleShowDialog = () => {
        setShowDialog(true);
    };
    const handleCloseDialog = () => {
        setShowDialog(false);
    };
    /////////////


    useEffect(() => {
        const auth = localStorage.getItem("customer");
        const parsed = JSON.parse(auth);
        const id = parsed[0].id;
        const promo = parsed[0].promo;
        const balance = parsed[0].balance;

        console.log("promo", promo)

        setUser_id(id)
        setPromo_add(promo)
        setBalance(balance)

        let aa = insurance_fee
        let bb = Number(aa).toFixed(2);
        setTotal_fee(bb)
    }, [])


    const check_promo = () => {
        setShowDialog(false);

        setTimeout(() => {
            if (p_code == "") {
                alert("Please enter promo code.")
            }
            else {

                let record = all_promos
                if (record == "") {
                    setP_code("")
                    alert("Invalid Promo Code.")
                }
                else {
                    const codeExists = record.some(item => item.code === p_code);

                    if (codeExists == true) {
                        let percentage = record[0].percentage
                        let tax = Number((percentage / 100) * total_fee).toFixed(2);
                        let total_feee = Number(total_fee - tax).toFixed(2);

                        setTotal_fee(total_feee)
                        setPromo_add('true')
                        setPromo_text(true)
                        setPercentage(percentage)
                        setPromo_end('true')

                        toast.success('You have successfully got discount for entering promo code!');
                        console.log("sueecess")
                    }
                    else {
                        setP_code("")
                        alert("Invalid Promo Code.")
                    }
                }
            }

        }, 700);
    }




    const Update_payment = async () => {

        console.log('total_fee', total_fee)
        console.log('user_iid', user_id)
        console.log('balances', balance)

        if (Number(balance) < Number(total_fee)) {
            alert("You don't have sufficient balance in your wallet for booking this appointment.")
        }
        else {
            setSpinner(true)

            const amount = total_fee

            let result = await fetch(Connection + "update_payment_wallet_a", {
                method: 'post',
                body: JSON.stringify({ amount, user_id }),
                headers: {
                    "Content-Type": 'application/json'
                }
            })
            result = await result.json()
            let record = result
            console.log("update_payment => ", record)

            if (record == "fail") {
                setSpinner(false)
                alert("Something went wrong try again later");
            }
            else {
                const user = localStorage.getItem("customer");
                const parsed = JSON.parse(user);
                const balance = parsed[0].balance

                if (balance != '') {
                    const get_balance = parseFloat(parsed[0].balance);
                    const total_fe = parseFloat(total_fee);
                    parsed[0].balance = get_balance - total_fe;
                    localStorage.setItem("customer", JSON.stringify(parsed))
                }
                else { }

                statuschaneafterpay()
                toast.success('Your insurance payment successfully done.');
            }
        }
    }


    const statuschaneafterpay = async () => {
        let status = 'pending'
        let result = await fetch(Connection + "do_it_pending", {
            method: 'post',
            body: JSON.stringify({ appointment_id, status }),
            headers: {
                "Content-Type": 'application/json'
            }
        })
        const response = await result.json();
        console.log('accept appointment', response.response);

        if (response.response == 'success') {

            setTimeout(() => {
                setSpinner(false)
                handleClose(false)
                callfunction(user_id)
            }, 500);
        }
        else {
            setSpinner(false)
            alert("Please try again later");
        }
    };


    const alertforpaypal = () => {
        alert("Paypal method will be add in future")
    }


    const handlepaymentClose = () => {
        setShowpayment(false);
    };


    return (
        <div className="modal_paymentoptionground">

            <div className="modalpaymentoptionContainer">
                <div className="titlepaymentCloseBtn">
                    <button onClick={() => { handleClose(false); }}>X</button>
                    <h2>Current Fee: ${total_fee}</h2>
                    <h2>    </h2>
                </div>

                <hr style={{ marginTop: 15, marginBottom: 15 }} />

                <div onClick={() => setShowpayment(true)} className='pay_wih_card'>
                    <CreditCardIcon />
                    <div className='upr_nic_nam'>
                        <h4>Pay with Card</h4>
                        <h5>All Credit / Depit Cards</h5>
                    </div>
                </div>
                <hr className="line"></hr>


                <div onClick={alertforpaypal} className='pay_wih_card'>
                    <CurrencyRubleIcon style={{ color: '#02a3ed' }} />
                    <div className='upr_nic_nam'>
                        <h4>Pay with Paypal</h4>
                        <h5>Log into Paypal</h5>
                    </div>
                </div>
                <hr className="line"></hr>


                <div onClick={Update_payment} className='pay_wih_card'>
                    <AccountBalanceWalletOutlinedIcon />
                    <div className='upr_nic_nam'>
                        <h4>Pay with Wallet</h4>
                        <h5>Book quick appointment with Wallet</h5>
                    </div>
                </div>
                <hr className="line"></hr>
            </div>


            {showpayment &&
                <Modal_Payment showpayment={true} handlepaymentClose={handlepaymentClose} day={day} date={date} time={time} appointment_price={total_fee} category={category} doctor_name={doctor_name} city={city} doctor_id={doctor_id} reason={reason} symptoms={symptoms} issue={issue} allergic={allergic} promo_end={promo_end} method={"Card"} email={email} fcm_token={fcm_token} web_fcm={web_fcm} type_of={type_of} usertype={usertype} appointment_id={appointment_id} callfunction={callfunction} insuranceoayment={true}>
                    <p>Modal Payment Goes Here</p>
                </Modal_Payment>
            }


            {spinner == true &&
                <div className='spinner_main_loader'>
                    <div className='spinner_inside'>
                        <PulseLoader
                            color={"#874DAF"}
                            loading={spinner}
                            //  size={5}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                        {/* <h4>Progressing your request</h4> */}
                    </div>
                </div>
            }


            <ToastContainer />
        </div>
    );
}

export default Pay_insurance_optin;
