import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Image_connection from "../../Image_connection";
import "./Doctor_site_appointment_detail.css";
import DoneIcon from '@mui/icons-material/Done';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Connection from '../../connection';
import moment from 'moment';
import Modal_cancel_appoint from '../../MyComponent/Modal_cancel_appoint/Modal_cancel_appoint';
import Modal_reschedual_appoint from '../../MyComponent/Modal_reschedual_appoint/Modal_reschedual_appoint';

import PulseLoader from "react-spinners/PulseLoader";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import VideocamIcon from '@mui/icons-material/Videocam';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import html2canvas from 'html2canvas';

function Doctor_site_appointment_detail({ handleClose, getProducts, getPrducts2, payment_method, user_name, date, day, time, user_profile, user_address, user_email, user_mobile, user_id, appointment_id, fee, user_fcm_token, user_web_fcm, status, active, device_token, user_gender, category, app_type, user_type, doctor_name, u_user_id }) {

    const navigate = useNavigate();

    const [spinner, setSpinner] = useState(false);
    const [skalton, setSkalton] = useState(true);

    const [front_image, setFront_image] = useState(null);
    const [back_image, setBack_image] = useState(null);

    const [data, setData] = useState([]);
    const [reason, setReason] = useState('');
    const [symptoms, setSymptoms] = useState('');
    const [issue, setIssue] = useState('');
    const [allergic, setAllergic] = useState('');
    const [medication, setMedication] = useState('');

    const [doctor_id, setDoctor_id] = useState('');
    const [doctorname, setDoctorname] = useState('');
    const [image1, setImage1] = useState('');

    const [doctor_address, setDoctor_address] = useState('');

    const [cancelapoinSho, setCancelapoinSho] = useState(false);
    const [reschedualapoinSho, setReschedualapoinSho] = useState(false);


    useEffect(() => {
        const auth = localStorage.getItem("customer");
        const parsed = JSON.parse(auth);
        const id = parsed[0].id;
        const doctorname = parsed[0].name;
        const doctor_address = parsed[0].address;
        setDoctor_id(id)
        setDoctorname(doctorname)
        setDoctor_address(doctor_address)

        let profile1 = parsed[0].profile;
        if (profile1 == null) {
            setImage1(null)
        }
        else {
            let profile = Image_connection + profile1
            setImage1(profile)
        }

        if (payment_method == 'Insurance') {
            checkinsurancehaveornot()
        }
        getIssueAppointment(id);
    }, [])


    const checkinsurancehaveornot = async () => {
        let result = await fetch(Connection + "check_insurance", {
            method: 'post',
            body: JSON.stringify({ user_id }),
            headers: {
                "Content-Type": 'application/json'
            }
        })
        result = await result.json()
        const record4 = result.response
        console.log(".,.,.CHECK INSURANCE,.,.", record4)

        if (record4 == 'success') {
            const reco = result.data

            let frnt_age = reco[0].front_image
            let front_image = Image_connection + frnt_age

            let bac_imag = reco[0].back_image
            let back_image = Image_connection + bac_imag

            setFront_image(front_image)
            setBack_image(back_image)
        }
        else { }
    };


    const getIssueAppointment = async (id) => {
        setSkalton(true);

        let result = await fetch(Connection + "getissue_appointment_for_doctor", {
            method: 'post',
            body: JSON.stringify({ id, appointment_id }),
            headers: {
                "Content-Type": 'application/json'
            }
        })
        const response = await result.json();
        let response1 = response.response;
        console.log("response .........", response1);

        if (response1 !== 'fail') {
            let reason = response1[0].reason;
            let symptoms = response1[0].symptoms;
            let issue = response1[0].issue;
            let allergic = response1[0].allergic;
            let medication = response1[0].medication;

            setData(response1);
            setReason(reason);
            setSymptoms(symptoms);
            setIssue(issue);
            setAllergic(allergic);
            setMedication(medication);
            setSkalton(false);
        }
        else {
            setData([]);
            setSkalton(false);
        }
    };



    const complete_appointment = async () => {
        if (!medication) {
            alert("Please enter Physician Solution")
        }
        else {
            setSpinner(true);

            // Date And Time For Notification Date 
            const aa = moment(new Date()).format("YYYY-MM-DD hh:mm A");
            const split = aa.split(' ')
            const notification_date = split[0]
            const n_time = split[1]
            const am_pm = split[2]
            const notification_time = n_time + "" + am_pm
            //////////////////////////////

            let tax = 6
            let d_earning = Number(fee - tax).toFixed(2);
            let type = 'complete'

            let amount;
            if (user_type === "hisd") {
                amount = '0'
            }
            else {
                amount = d_earning;
            }

            let response = await fetch(Connection + "complete_doc_appointment_a", {
                method: 'post',
                body: JSON.stringify({ doctor_id, user_id, appointment_id, medication, amount, notification_date, notification_time, type, user_name, doctor_name }),
                headers: {
                    "Content-Type": 'application/json'
                }
            })
            const result = await response.json();
            console.log('accept appointment', result.response);


            if (result.response === "success4") {
                toast.success('You have successfully completed this appointment.');

                if (user_type !== "hisd") {
                    const user = localStorage.getItem("customer");
                    let parsed = JSON.parse(user);
                    const balance = parseFloat(parsed[0].balance);

                    if (balance !== '') {
                        const get_balance = parseFloat(parsed[0].balance);
                        const dd_earning = parseFloat(d_earning);
                        parsed[0].balance = get_balance + dd_earning;
                        localStorage.setItem("customer", JSON.stringify(parsed))
                    }
                    else {
                        const dd_earning = parseFloat(d_earning);
                        parsed[0].balance = balance + dd_earning;
                        localStorage.setItem("customer", JSON.stringify(parsed))
                    }
                }

                setSpinner(false);
                handleClose(false)
                app_notification()
                web_notification()
                getProducts(doctor_id)
            }
            else {
                setSpinner(false);
                alert("Something went wrong try again later");
            }
        }
    };


    const app_notification = async () => {
        let to = user_fcm_token;
        console.log("To is => ", to)

        fetch('https://fcm.googleapis.com/fcm/send', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'key= AAAAaCD4Bcc:APA91bETamRJX5KYdE6sCHUzquj_DpmfqiqH3ogXH1K1m1SH5U5_60OPWrPP5hHMyq_1pQcUN2WMWAPpCOzFDJJ4lDt6X3IDNwLuohzTB8ja3EDGM-FDTePfmCEYpFTsUoH-wguvF6Ux'//cloud server key
            },
            body: JSON.stringify({
                "to": to,
                "priority": "high",
                "notification": {
                    "title": "Mas Clinicas",
                    "body": "Dr." + doctorname + " is complete appointment with you.",
                    "sound": 'default',
                    "icon": "myicon",
                }
            }
            )
        }).then(res => res.json())
            .then(resjson => console.log("test", resjson))
            .catch(err => console.log("error =>", err))
    }


    const web_notification = async () => {
        let to = user_web_fcm;
        console.log("To is => ", to)

        fetch('https://fcm.googleapis.com/fcm/send', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'key= AAAAPzWsmh8:APA91bGwGLlejpat2ndQPc3708f5KqvTPRFm9oZ7RQyBB9khWgUn_2JFZJdIf5Lta7dS2M9wTj_OYaxxmZ3FRVckZGoDJpEWyUC81G1hJliAhiKUGaZJS0P3cP-T_Z5VwGKPQzNsk_75'//cloud server key
            },
            body: JSON.stringify({
                "to": to,
                "priority": "high",
                "notification": {
                    "title": "Mas Clinicas",
                    "body": "Dr." + doctorname + " is complete appointment with you.",
                    "sound": 'default',
                    "icon": "myicon",
                }
            }
            )
        }).then(res => res.json())
            .then(resjson => console.log("test", resjson))
            .catch(err => console.log("error =>", err))
    }


    const handlecancelappoint = () => {
        setCancelapoinSho(false);
    };

    const handlerescheappoint = () => {
        setReschedualapoinSho(false);
    };


    const agora_notification = async () => {
        console.log("fcm_token", user_fcm_token)
        if ((user_fcm_token === null || user_fcm_token === '') && (user_web_fcm === null || user_web_fcm === '')) {
            alert("This user is currently unavailable.");
        }
        else {
            document.body.style.overflow = 'auto';

            const call_check = 'do_call'
            const url = `/AgoraCall?call_check=${call_check}&fcm_token=${user_fcm_token}&web_fcm=${user_web_fcm}`;
            navigate(url);
        }
    };


    const handleDownloadImage = () => {
        const prescriptionContainer = document.querySelector('.capture_image');

        if (prescriptionContainer) {
            html2canvas(prescriptionContainer)
                .then((canvas) => {
                    const url = canvas.toDataURL('image/png');

                    // Create an anchor element and trigger the download
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = `Prescription_${appointment_id}.png`; // You can customize the filename
                    document.body.appendChild(a);
                    a.click();

                    // Cleanup
                    document.body.removeChild(a);
                })
                .catch((error) => {
                    console.error('Error capturing image:', error);
                });
        }
    };


    const chat_romm = async () => {
        document.body.style.overflow = 'auto';

        const check_chat = 'do_chat'
        const userprofile = Image_connection + user_profile
        const url = `/Chat?check_chat=${check_chat}&receiver_image=${user_profile}&chat_image=${user_profile}&chat_name=${user_name}&receiver_id=${user_id}&fcm_token=${user_fcm_token}&web_fcm=${user_web_fcm}&active=${active}&sender_name=${doctor_name}&receiver_name=${user_name}&chat_name_disable=${user_name}`;
        navigate(url);
    }


    const Accept_appointment = async () => {
        setSpinner(true)

        let status;
        if (payment_method == 'Insurance') {
            status = 'wait_for_pay'
        }
        else {
            status = 'pending'
        }

        let result = await fetch(Connection + "do_it_pending", {
            method: 'post',
            body: JSON.stringify({ appointment_id, status }),
            headers: {
                "Content-Type": 'application/json'
            }
        })
        const response = await result.json();
        console.log('accept appointment', response.response);

        if (response.response == 'success') {
            toast.success('Please be available on appointment time');

            setSpinner(false);
            handleClose(false)
            getProducts(doctor_id)
            getPrducts2(doctor_id)
        }
        else {
            setSpinner(false)
            alert("Please try again later");
        }
    };

    return (
        <div className="Doctor_sideBackground">

            <div className="doctorsideContainer">
                <div className="titledoctorsideCloseBtn">
                    <button onClick={() => { handleClose(false); }}>
                        <ArrowBackIcon />
                    </button>
                    <h3>Appointment Details</h3>
                    <h1></h1>
                </div>

                <hr style={{ marginTop: 15, marginBottom: 3 }} />


                <div className="profile_container">
                    <div className="profile_image_container">
                        {user_profile !== null ? (
                            <img className="profile_image" src={Image_connection + user_profile} alt="doctor-image" />
                        ) : (
                            <img
                                className="profile_image"
                                src={require('../../assets/empty.png')}
                                alt="Empty"
                            />
                        )}
                    </div>

                    <div className="profile_info">
                        <p className="user_name">{user_name}</p>
                        <p className="gender">{user_gender}</p>
                    </div>
                </div>

                <div className="info_container">
                    <div className="info_row">
                        <i className="material-icons icon"><EmailOutlinedIcon /></i>
                        <p className="info-text">{user_email}</p>
                    </div>

                    <div className="info_row">
                        <i className="ionicons icon"><CallOutlinedIcon /></i>
                        <p className="info-text">{user_mobile}</p>
                    </div>

                    <div className="info_row">
                        <i className="ionicons icon"><LocationOnOutlinedIcon /></i>
                        <p className="info-text">{user_address}</p>
                    </div>
                </div>


                {app_type !== 'offline' && (
                    <>
                        {status === 'pending' && (
                            <div className="button_container">
                                <button className="chat_button" onClick={() => chat_romm()}>
                                    <QuestionAnswerIcon style={{ color: '#874DAF', fontSize: '18px' }} />
                                    <span>Chat with Patient</span>
                                </button>

                                <button className="video_main_div">
                                    <div onClick={() => agora_notification()} className="video_button">
                                        <VideocamIcon style={{ color: '#0751fc', fontSize: '25px' }} />
                                    </div>
                                </button>
                            </div>
                        )}
                    </>
                )}

                {app_type != 'urgent' &&
                    <>
                        <div className="divider"></div>
                        <div className="date-time-container">
                            <p className="gray-text">Appointment Date & Time</p>
                            <p className="bold-text">{`${day} ${date} | ${time}`}</p>
                        </div>
                    </>
                }

                <div className="divider"></div>

                {app_type !== 'offline' && (
                    <>
                        {status === 'pending' && (
                            <div className="appointment-buttons">
                                <button
                                    onClick={() => { setCancelapoinSho(true) }}
                                    className="cancel-button"
                                >
                                    Cancel
                                </button>
                                <div style={{ width: 1, backgroundColor: 'lightgray', height: '100%' }}></div>
                                <button
                                    onClick={() => { setReschedualapoinSho(true) }}
                                    className="reschedule-button"
                                >
                                    Reschedule
                                </button>
                            </div>
                        )}
                    </>
                )}

                <div className="divider"></div>

                <div className="payment-info">
                    <div className="payment-header">
                        <p className="payment-title">Payment Method</p>
                        <div className="payment-status">
                            <CheckOutlinedIcon style={{ color: '#874DAF' }} />
                            <span className="status-text">{payment_method}</span>
                        </div>
                    </div>
                </div>


                <div className="your-component-container">
                    {skalton ? (
                        <div className="skalton-container">
                            <PulseLoader
                                color={"gray"}
                                loading={skalton}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />
                        </div>
                    ) : (
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            {payment_method == 'Insurance' &&
                                <>
                                    <div className="data-container" style={{ marginBottom: 10 }}>
                                        <p style={{ color: "#874DAF", fontWeight: 'bold', fontSize: 14 }}>Front Image</p>

                                        <hr className="divider" />

                                        <img src={front_image} alt="" className="imageshowindetail" />
                                    </div>

                                    <div className="data-container" style={{ marginBottom: 10 }}>
                                        <p style={{ color: "#874DAF", fontWeight: 'bold', fontSize: 14 }}>Front Image</p>

                                        <hr className="divider" />

                                        <img src={back_image} alt="" className="imageshowindetail" />
                                    </div>
                                </>
                            }

                            {data.length === 0 ? (
                                <div className="no-data-container">
                                    <p style={{ color: 'black' }}>No treatment result</p>
                                </div>
                            ) : (
                                <div className='capture_image' style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    <div className="data-container">
                                        <p style={{ color: "#874DAF", fontWeight: 'bold', fontSize: 14 }}>More Details</p>

                                        <hr className="divider" />

                                        <div className="detail-row">
                                            <p style={{ color: 'black', fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>Reason:</p>
                                            <p style={{ color: 'black', fontSize: 12, maxWidth: '90%' }}>{reason}</p>
                                        </div>

                                        <div className="detail-row">
                                            <p style={{ color: 'black', fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>Symptoms:</p>
                                            <p style={{ color: 'black', fontSize: 12, maxWidth: '90%' }}>{symptoms}</p>
                                        </div>

                                        <div className="detail-row">
                                            <p style={{ color: 'black', fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>Issues:</p>
                                            <p style={{ color: 'black', fontSize: 12, maxWidth: '90%' }}>{issue}</p>
                                        </div>

                                        <div className="detail-row">
                                            <p style={{ color: 'black', fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>Allergic:</p>
                                            <p style={{ color: 'black', fontSize: 12, maxWidth: '90%' }}>{allergic}</p>
                                        </div>
                                    </div>

                                    {status === 'complete' && (
                                        <div className="prescription-container">
                                            <p className="prescription-title">Prescription</p>
                                            <div className="divider"></div>
                                            <p className="prescription-text">{medication}</p>
                                        </div>
                                    )}
                                </div>
                            )}


                            {app_type !== 'offline' && (
                                <>
                                    {status === 'pending' && (
                                        <>
                                            <p className="physician-solution">Physician Solution</p>
                                            <textarea
                                                onChange={(e) => setMedication(e.target.value)}
                                                value={medication}
                                                className="text-input"
                                                placeholder={"Visit Summary"}
                                            ></textarea>
                                            <button onClick={() => complete_appointment()}
                                                className={'button-android'}
                                            >
                                                <p style={{ color: 'white', fontWeight: 'bold' }}>Complete</p>
                                            </button>
                                        </>
                                    )}
                                </>
                            )}

                            {status === 'complete' && (
                                <>
                                    <button onClick={handleDownloadImage}
                                        className="download-button"
                                    >
                                        <DownloadOutlinedIcon style={{ color: 'white', fontSize: 22, marginRight: 5 }} />
                                        <p style={{ color: 'white', fontWeight: 'bold' }}>Download</p>
                                    </button>
                                </>
                            )}


                            {payment_method == 'Insurance' &&
                                <>
                                    {status === 'request' &&
                                        <button onClick={Accept_appointment}
                                            className="download-button"
                                        >
                                            <p style={{ color: 'white', fontWeight: 'bold' }}>Accept</p>
                                        </button>
                                    }
                                </>
                            }
                        </div>
                    )}
                </div>

                <ToastContainer />

            </div>


            {cancelapoinSho == true &&
                <Modal_cancel_appoint handleClose={handleClose} handlecancelappoint={handlecancelappoint} get_apointments={getProducts} appointment_id={appointment_id} doctor_id={doctor_id} user_id={user_id} doctor_nam={doctor_name} user_nam={user_name} user_email={user_email} date={date} time={time} fee={fee} fcm_token={user_fcm_token} web_fcm={user_web_fcm}>
                    <p>Modal Content Goes Here</p>
                </Modal_cancel_appoint>
            }


            {reschedualapoinSho == true &&
                <Modal_reschedual_appoint handleClose={handleClose} handlerescheappoint={handlerescheappoint} get_apointments={getProducts} appointment_id={appointment_id} doctor_id={doctor_id} user_id={user_id} doctor_profile={image1} doctor_nam={doctor_name} user_nam={user_name} user_email={user_email} category={category} appointme_date={date} time={time} fcm_token={user_fcm_token} web_fcm={user_web_fcm} type_of={app_type}>
                    <p>Modal Content Goes Here</p>
                </Modal_reschedual_appoint>
            }


            {spinner && (
                <div style={{ width: '100%', height: '100%', backgroundColor: 'rgba(2, 2, 2, 0.8)', position: 'absolute', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <div style={{ display: 'flex', width: '30%', backgroundColor: 'white', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', padding: '30px', boxShadow: '0 1px 5px rgba(0, 0, 0, 0.2)', borderRadius: '6px', }}>
                        <PulseLoader
                            color={"#874DAF"}
                            loading={spinner}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                        <p style={{ fontSize: '16px', color: '#874DAF', fontWeight: 'bold', marginLeft: '15px' }}>
                            Progressing your request
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Doctor_site_appointment_detail;
