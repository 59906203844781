import React, { useState, useEffect } from 'react'
import Connection from "../../connection";
import FadeLoader from "react-spinners/FadeLoader";

export default function Refund() {

    const [skalton, setSkalton] = React.useState(true);
    const [user_data, setUser_data] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);

        const auth = localStorage.getItem("customer");
        const parsed = JSON.parse(auth);
        const id = parsed[0].id;

        getProducts(id)
    }, [])

    const getProducts = async (id) => {
        let result = await fetch(Connection + "display_all_refunds", {
            method: 'post',
            body: JSON.stringify({ id }),
            headers: {
                "Content-Type": 'application/json'
            }
        })
        result = await result.json()
        const record4 = result.response
        console.log("Data user refund =>", record4)

        if (record4) {
            setUser_data(record4)
            setSkalton(false);
        }
        else {
            setSkalton(false);
        }
    }




    return (

        <div className='appointment_startup'>

            <div className='refund_history'>
                <p>Refunds History</p>
            </div>

            {skalton == true ?
                <div style={{ width: '100%', height: '70vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <FadeLoader
                        color={"#874DAF"}
                        loading={skalton}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                </div>
                :
                <div className='mainofwallet'>
                    {user_data != '' ?
                        <>
                            {user_data.map((row) => {
                                return (
                                    <div key={row.appointment_id} style={{ borderBottom: '1px solid gray', paddingBottom: '10px' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px' }}>
                                            <span style={{ fontSize: '14px', color: 'black' }}>Appointment ID : {row.appointment_id}</span>

                                            {row.status === 'pending' ? (
                                                <span style={{ fontSize: '12px', color: 'red' }}>{row.status}</span>
                                            ) : (
                                                <span style={{ fontSize: '12px', color: 'green' }}>{row.status}</span>
                                            )}
                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px' }}>
                                            <span style={{ fontSize: '12px', color: 'gray', maxWidth: '80%' }}>Reason: {row.reason}</span>

                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <span style={{ fontSize: '14px', color: 'black', marginRight: '4px' }}>${row.total_fee}</span>
                                                <i className="fas fa-money-bill-wave" style={{ color: '#24AE34', fontSize: 14, marginLeft: 4 }}></i>
                                            </div>
                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px' }}>
                                            <span style={{ fontSize: '12px', color: 'gray' }}>Refund Amount</span>

                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <span style={{ fontSize: '14px', color: 'black', marginRight: '4px' }}>${row.r_amount}</span>
                                                <i className="fas fa-money-bill-wave" style={{ color: '#24AE34', fontSize: 14, marginLeft: 4 }}></i>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </>
                        :
                        <div style={{ width: '100%', height: '70vh', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                            <p>No Record Found</p>
                        </div>
                    }
                </div>
            }
        </div>
    );
}