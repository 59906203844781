import React, { useState, useEffect } from 'react'
import Modal_Payment from '../Modal_Payment/Modal_Payment';
import PulseLoader from "react-spinners/PulseLoader";
import Connection from '../../connection';

import CreditCardIcon from '@mui/icons-material/CreditCard';
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import LockIcon from '@mui/icons-material/Lock';

function Paypal_Options_Wallet({ handleClose, handleClosadpay, fee, wallet }) {
    const [spinner, setSpinner] = useState(false);
    const [stripe_key, setStripe_key] = useState('');
    const [showpayment, setShowpayment] = useState(false);


    const get_key = async () => {
        setSpinner(true)
        let result = await fetch(Connection + "get_key", {

        })
        result = await result.json()
        const record4 = result.response;
        console.log('stripe =>', record4)

        if (record4) {
            const key_s = record4[0].key_s;

            setStripe_key(key_s)
            setSpinner(false)
            setShowpayment(true)
        }
        else {
            setSpinner(false)
            alert("Something went wrong try again later");
        }
    }

    const alertforpaypal = () => {
        alert("Paypal method will be add in future")
    }


    const handlepaymentClose = () => {
        setShowpayment(false);
    };


    return (
        <div className="modal_paymentoptionground">

            <div className="modalpaymentoptionContainer">
                <div className="titlepaymentCloseBtn">
                    <button onClick={() => { handleClosadpay(false); }}>X</button>
                    <h2>Payment Options</h2>
                    <h2>    </h2>
                </div>

                <hr style={{ marginTop: 15, marginBottom: 15 }} />

                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 5 }}>
                    <LockIcon style={{ color: 'gray', fontSize: 15 }} />
                    <h5 style={{ alignSelf: 'center', color: 'gray', fontWeight: 'normal' }}>Payment and Card details are Encrypted</h5>
                </div>

                <div onClick={() => setShowpayment(true)} className='pay_wih_card'>
                    <CreditCardIcon />
                    <div className='upr_nic_nam'>
                        <h4>Pay with Card</h4>
                        <h5>All Credit / Depit Cards</h5>
                    </div>
                </div>
                <hr className="line"></hr>


                <div onClick={alertforpaypal} className='pay_wih_card'>
                    <CurrencyRubleIcon style={{ color: '#02a3ed' }} />
                    <div className='upr_nic_nam'>
                        <h4>Pay with Paypal</h4>
                        <h5>Log into Paypal</h5>
                    </div>
                </div>
                <hr className="line"></hr>


            </div>



            {showpayment &&
                <Modal_Payment
                    showpayment={true}
                    handleClose={handleClose}
                    handlepaymentClose={handlepaymentClose}
                    appointment_price={fee}
                    wallet={wallet}
                >
                    <p>Modal Payment Goes Here</p>
                </Modal_Payment>
            }


            {spinner == true &&
                <div style={{ width: '100%', height: '100%', backgroundColor: 'rgba(2, 2, 2, 0.8)', position: 'absolute', top: 0, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <div style={{ display: 'flex', width: '30%', backgroundColor: 'white', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', padding: '30px', boxShadow: '0 1px 5px rgba(0, 0, 0, 0.2)', borderRadius: '6px', }}>
                        <PulseLoader
                            color={"#874DAF"}
                            loading={spinner}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                        <p style={{ fontSize: '16px', color: '#874DAF', fontWeight: 'bold', marginLeft: '15px' }}>
                            Progressing your request
                        </p>
                    </div>
                </div>
            }

        </div>
    );
}

export default Paypal_Options_Wallet;
