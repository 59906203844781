import React, { useState, useEffect, useContext } from 'react'
import Connection from "../../connection";
import './Add_Insurance.css';
import { GlobalInfo } from "../../App"
import Image_connection from "../../Image_connection";
import Modal_payment_done from '../../MyComponent/Modal_payment_done/Modal_payment_done';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import CameraAltIcon from '@mui/icons-material/CameraAlt';
import PulseLoader from "react-spinners/PulseLoader";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
export default function Add_Insurance({ handlepaymentClose, day, date, time, appointment_price, category, doctor_name, city, doctor_id, reason, symptoms, issue, allergic, promo_end, method, email, fcm_token, web_fcm, type_of, usertype }) {
    const { insure_check } = useContext(GlobalInfo)
    console.log("insure_check", insure_check)

    const [showpayment_done, setShowpayment_done] = useState(false);
    const [spinner, setSpinner] = useState(false);

    const [user_id, setUser_id] = useState('');
    const [profile, setProfile] = useState(null);

    const [frontjust_Show, setFrontust_Show] = useState(null);
    const [front_image, setFront_image] = useState(null);
    const [frnt_age, setFrnt_age] = useState(null);

    const [backjust_Show, setBackjust_Show] = useState(null);
    const [back_image, setBack_image] = useState(null);
    const [bac_imag, setBac_imag] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);

        const auth = localStorage.getItem("customer");
        const parsed = JSON.parse(auth);
        const id = parsed[0].id;
        setUser_id(id)

        let profile1 = parsed[0].profile;
        if (profile1 == null) {
            setProfile(null)
        }
        else {
            let profile = Image_connection + profile1
            setProfile(profile)
        }


        if (insure_check != '') {
            let record = insure_check

            let frnt_age = record[0].front_image
            let front_image = Image_connection + frnt_age

            let bac_imag = record[0].back_image
            let back_image = Image_connection + bac_imag

            setFrontust_Show(front_image)
            setBackjust_Show(back_image)

            setFrnt_age(frnt_age)
            setBac_imag(bac_imag)
        }
    }, [])


    function frontSelectedHandler(event) {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = function (event) {
            const imageUrl = event.target.result;
            setFrontust_Show(imageUrl);
        };
        reader.readAsDataURL(file);
        console.log("file", file)
        setTimeout(() => {
            setFront_image(file)
            setFrnt_age(file)
        }, 100);
    }

    function backSelectedHandler(event) {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = function (event) {
            const imageUrl = event.target.result;
            setBackjust_Show(imageUrl);
        };
        reader.readAsDataURL(file);
        console.log("file", file)
        setTimeout(() => {
            setBack_image(file)
            setBac_imag(file)
        }, 100);
    }


    const addInsuranceimage = async () => {
        if (front_image == null) {
            alert("Please Upload Your Insured Card Front Image")
        }
        else if (back_image == null) {
            alert("Please Upload Your Insured Card Back Image");
        }
        else {
            setSpinner(true);

            const form_data = new FormData();

            form_data.append("front_image", front_image);
            form_data.append("back_image", back_image);

            const api = Connection + `Add_Insurance/${user_id}`;

            try {
                const result = await fetch(api, {
                    method: "POST",
                    body: form_data,
                });

                if (result.ok) {
                    const data = await result.json();
                    console.log("result", data);
                    toast.success('You have successfully added your insurance.');
                    setSpinner(false);

                    // insure_check(result)
                    // if (method == 'Insurance') {
                    //     setShowpayment_done(true);
                    // }
                }
                else {
                    console.error("Error uploading images");
                    setSpinner(false);
                    alert("Something went wrong");
                }
            } catch (error) {
                console.error(error);
                setSpinner(false);
                alert("Something went wrong");
            }
            // setSpinner(true)

            // const form_data = new FormData();

            // form_data.append("front_image", front_image);
            // form_data.append("back_image", back_image);

            // const api = Connection + `Add_Insurance/${user_id}`
            // const result = await fetch(api, {
            //     method: "POST",
            //     body: form_data,
            //     headers: {
            //         'Content-Type': 'multipart/form-data',
            //     },
            // })
            //     .then((result) => result.json())
            //     .then((result) => {
            //         console.log("result", result);
            //         if (result == 'success') {
            //             toast.success('You have successfully add your insurance.');

            //             setSpinner(false);
            //             // insure_check(result)
            //             // setShowpayment_done(true);
            //         }
            //     })

            //     .catch((error) => {
            //         console.error(error);
            //         setSpinner(false);
            //         alert("Something went wrong")
            //     });

            // console.log("result123", result)
        }
    }


    const Update_Insurance = async () => {

        let record = insure_check
        let frnt_old_age = record[0].front_image
        let bac_old_imag = record[0].back_image

        if (frnt_old_age == frnt_age || bac_old_imag == bac_imag) {
            alert("No Change in Image")
        }
        else {
            if (front_image == null) {
                alert("Please Upload Your Insured Card Front Image")
            }
            else if (back_image == null) {
                alert("Please Upload Your Insured Card Back Image");
            }
            else {
                setSpinner(true)

                const form_data = new FormData();

                form_data.append("front_image", frnt_age);
                form_data.append("back_image", bac_imag);

                const api = Connection + `Update_Insurance/${user_id}`
                const result = await fetch(api, {
                    method: "POST",
                    body: form_data,
                })
                    .then((result) => result.json())
                    .then((result) => {
                        console.log("result", result);
                        toast.success('You have successfully update your insurance.');

                        setSpinner(false);
                        // insure_check(result)
                    })

                    .catch((error) => {
                        console.error(error);
                        setSpinner(false);
                        alert("Something went wrong")
                    });

                console.log("result123", result)
            }
        }
    }


    const handlepaymentdoneClose = () => {
        setShowpayment_done(false);
    };


    return (

        <div className={method == 'Insurance' ? 'addinsurehidemin' : 'addinsurstart'}>
            <div className="addinsurContainer">
                <div style={{ width: '100%', backgroundColor: '#874DAF', padding: '5px 10px', display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                    {method == 'Insurance' &&
                        <div onClick={() => { handlepaymentClose(false); }}>
                            <ArrowBackIosNewIcon className='backiconn' />
                        </div>
                    }
                    <img
                        style={{ width: '55px', height: '55px', borderRadius: '40px', objectFit: 'cover' }}
                        src={profile || 'https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG.png'}
                        alt="Profile"
                    />
                    <div style={{ marginLeft: '10px', justifyContent: 'center' }}>
                        <p className='adinsur_text'>Add Insurance</p>
                    </div>
                </div>

                <h4 style={{ marginTop: 20 }}>Insurance Id Card Front and Back Photo</h4>

                <div className='mainoffrontandback'>
                    {frontjust_Show == null ?
                        <div className='addimagefron'>
                            <input type="file" onChange={frontSelectedHandler} />
                            <CameraAltIcon style={{ color: 'white', fontSize: 25 }} />
                            <h4 style={{ color: 'white' }}>Upload Front Image.</h4>
                        </div>
                        :
                        <div className='addimagefron'>
                            <input type="file" onChange={frontSelectedHandler} />
                            <CameraAltIcon style={{ color: '#874DAF', fontSize: 25, position: 'absolute', right: 10, top: 10 }} />
                            <img src={frontjust_Show} alt="" className="imageafterselect" />
                        </div>
                    }

                    {backjust_Show == null ?
                        <div className='addimagefron'>
                            <input type="file" onChange={backSelectedHandler} />
                            <CameraAltIcon style={{ color: 'white', fontSize: 25 }} />
                            <h4 style={{ color: 'white' }}>Upload Back Image.</h4>
                        </div>
                        :
                        <div className='addimagefron'>
                            <input type="file" onChange={backSelectedHandler} />
                            <CameraAltIcon style={{ color: '#874DAF', fontSize: 25, position: 'absolute', right: 10, top: 10 }} />
                            <img src={backjust_Show} alt="" className="imageafterselect" />
                        </div>
                    }
                </div>


                {insure_check == '' ?
                    <div className="insurnbuttoContainer">
                        <button onClick={() => addInsuranceimage()} className="customButton">Add Insurance</button>
                    </div>
                    :
                    <div className="insurnbuttoContainer">
                        <button onClick={() => Update_Insurance()} className="customButton">Update Insurance</button>
                    </div>
                }
            </div>


            {showpayment_done &&
                <Modal_payment_done showpayment_done={true} handlepaymentdoneClose={handlepaymentdoneClose} day={day} date={date} time={time} appointment_price={appointment_price} category={category} doctor_name={doctor_name} city={city} doctor_id={doctor_id} reason={reason} symptoms={symptoms} issue={issue} allergic={allergic} promo_end={promo_end} method={method} email={email} fcm_token={fcm_token} web_fcm={web_fcm} type_of={type_of} usertype={usertype}>
                    <p>Modal Appointment Done Goes Here</p>
                </Modal_payment_done>
            }


            {spinner == true &&
                <div style={{ width: '100%', height: '100%', backgroundColor: 'rgba(2, 2, 2, 0.8)', position: 'absolute', top: 0, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <div style={{ display: 'flex', width: '30%', backgroundColor: 'white', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', padding: '30px', boxShadow: '0 1px 5px rgba(0, 0, 0, 0.2)', borderRadius: '6px', }}>
                        <PulseLoader
                            color={"#874DAF"}
                            loading={spinner}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                        <p style={{ fontSize: '16px', color: '#874DAF', fontWeight: 'bold', marginLeft: '15px' }}>
                            Progressing your request
                        </p>
                    </div>
                </div>
            }

            <ToastContainer />
        </div>
    );
}