import React, { useState, useEffect } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import "./Modal_ready_review.css";
import Modal_give_review from '../../MyComponent/Modal_give_review/Modal_give_review';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import Image_connection from "../../Image_connection";

function Modal_ready_review({ handlereadyreviw, get_apointments, doctor_id, appointment_id, doctor_address, doctor_profile, doctor_nam, category, fcm_token, web_fcm }) {

    const [readyreview, setReadyreview] = useState(false);

    const handlegivereviw = () => {
        setReadyreview(false);
    };

    return (
        <div className="modal_readyreviewground">

            <div className="modalreadyreviewContainer">
                <div className="titlereadyreviewCloseBtn">
                    <button onClick={() => { handlereadyreviw(false); }}>
                        <ArrowBackIcon />
                    </button>
                </div>

                <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div className='circleouttime'>
                        <AccessTimeFilledIcon className='icninsideclock' />
                    </div>

                    <p className='showhedingtxtrvw'>The consultation has been ended</p>

                    <div style={{ width: '90%', border: '1px solid lightgray', margin: '10px auto' }}></div>

                    {doctor_profile == null ?
                        <img className="imageredyrivw" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIEFLVeftvEbJfqWQ2hnRMSPuF_b8VMn4PdPhXOJdkPm-bfdpWTZZYAUJyHVXS4wrsDAE&usqp=CAU" alt="doctor-image" />

                        :
                        <img
                            className='imageredyrivw'
                            src={Image_connection + doctor_profile}
                            alt="Doctor Profile"
                        />
                    }

                    <p className='readydocnme'>Dr. {doctor_nam}</p>
                    <p className='readycatg'>{category}</p>
                    <p className='readyaddess'>{doctor_address}</p>

                    <div style={{ width: '90%', border: '1px solid lightgray', margin: '10px auto' }}></div>

                    <div className='lastwobtnredtrivw'>
                        <div onClick={() => { handlereadyreviw(false); }} className='firstbackhmbtn'>
                            <p className='frsttxtbck'>Back to home</p>
                        </div>

                        <div onClick={() => { setReadyreview(true); }} className='leavereviwbtnscnd'>
                            <p className='levetextreviw'>Leave a review</p>
                        </div>
                    </div>
                </div>

            </div>

            {readyreview == true &&
                <Modal_give_review handlegivereviw={handlegivereviw} handlereadyreviw={handlereadyreviw} get_apointments={get_apointments} doctor_id={doctor_id} appointment_id={appointment_id} doctor_address={doctor_address} doctor_profile={doctor_profile} doctor_name={doctor_nam} category={category} fcm_token={fcm_token} web_fcm={web_fcm}>
                    <p>Modal Content Goes Here</p>
                </Modal_give_review>
            }

        </div>
    );
}

export default Modal_ready_review;
