import React, { useState, useEffect } from 'react'
import './Patient_All_Appointment.css';
import Connection from "../../connection";
import Image_connection from "../../Image_connection";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import Modal_detail from '../../MyComponent/Modal_detail/Modal_detail';
import Modal_cancel_appoint from '../../MyComponent/Modal_cancel_appoint/Modal_cancel_appoint';
import Modal_reschedual_appoint from '../../MyComponent/Modal_reschedual_appoint/Modal_reschedual_appoint';
import Modal_ready_review from '../../MyComponent/Modal_ready_review/Modal_ready_review';

import Drawer from 'react-drawer';
import 'react-drawer/lib/react-drawer.css';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

export default function Patient_All_Appointment() {

  const [skalton, setSkalton] = React.useState(true);
  const [upcom_arr, setUpcom_arr] = useState([]);
  const [past_arr, setPast_arr] = useState([]);

  const [show, setShow] = useState(false);
  const [cancelapoinSho, setCancelapoinSho] = useState(false);
  const [reschedualapoinSho, setReschedualapoinSho] = useState(false);
  const [readyreview, setReadyreview] = useState(false);

  const [user_id, setUser_id] = React.useState('');

  const [payment_method, setPayment_method] = React.useState('');
  const [date, setDate] = React.useState('');
  const [status, setStatus] = React.useState('');
  const [day, setDay] = React.useState('');
  const [time, setTime] = React.useState('');
  const [doctor_name, setDoctor_name] = React.useState('');
  const [category, setCategory] = React.useState('');
  const [doctor_address, setDoctor_address] = React.useState('');
  const [doctor_profile, setDoctor_profile] = React.useState('');
  const [fee, setFee] = React.useState('');
  const [user_name, setUser_name] = React.useState('');
  const [doctor_experience, setDoctor_experience] = React.useState('');
  const [appointment_id, setAppointment_id] = React.useState('');
  const [app_type, setApp_type] = React.useState('');
  const [user_type, setUser_type] = React.useState('');
  const [doctor_id, setDoctor_id] = React.useState('');
  const [doctor_email, setDoctor_email] = React.useState('');
  const [fcm_token, setFcm_token] = React.useState('');
  const [web_fcm, setWeb_fcm] = React.useState('');


  // RBSheet
  const [cancelOpen, setCancelOpen] = React.useState(false);
  const cancelmodalOpen = (id, fee, date, time, doctor_id, doc_fcm_token, doc_web_fcm, doctor_email, doctor_name) => {
    setAppointment_id(id)
    setFee(fee)
    setDate(date)
    setTime(time)
    setDoctor_id(doctor_id)
    setFcm_token(doc_fcm_token)
    setWeb_fcm(doc_web_fcm)
    setDoctor_email(doctor_email)
    setDoctor_name(doctor_name)

    setCancelOpen(true);
  };
  const cancelmodalClose = () => {
    setCancelOpen(false);
  };
  const cancelmodalnd_next = () => {
    document.body.style.overflow = 'hidden';
    setCancelOpen(false);
    setCancelapoinSho(true);
  };
  ////////////////////



  // Change Btn
  const [text4, setText4] = React.useState(2);
  const [text5, setText5] = React.useState(1);
  const [check_first_design, setCheck_first_design] = React.useState('Upcoming');
  const changebt1 = (text, val) => {
    setText4(1);
    setText5(1);

    if (text === "text4") {
      setText4(2);
    }
    else if (text === "text5") {
      setText5(2);
    }

    setCheck_first_design(val)
  }
  /////////////////////////



  // Change Btn
  const [text1, setText1] = React.useState(2);
  const [text2, setText2] = React.useState(1);
  const [text3, setText3] = React.useState(1);
  const [check_design, setCheck_design] = React.useState('All');
  const [all_arr, setAll_arr] = React.useState([]);
  const [online_arr, setOnline_arr] = React.useState([]);
  const [offline_arr, setOffline_arr] = React.useState([]);

  const changebtn2 = (text, val) => {
    setText1(1);
    setText2(1);
    setText3(1);

    if (text === "text1") {
      setText1(2);
    }
    else if (text === "text2") {
      setText2(2);
    }
    else if (text === "text3") {
      setText3(2);
    }

    setCheck_design(val)

    if (val == "All") {
      setUpcom_arr(all_arr)
    }
    else if (val == "Online Appointment") {
      setUpcom_arr(online_arr)
    }
    else if (val == "Offline Appointment") {
      setUpcom_arr(offline_arr)
    }
  }
  /////////////////////////



  // Change Btn
  const [text6, setText6] = React.useState(2);
  const [text7, setText7] = React.useState(1);
  const [text8, setText8] = React.useState(1);
  const [check_second_design, setCheck_second_design] = React.useState('All');
  const [pst_all_arr, setPst_all_arr] = React.useState([]);
  const [pst_online_arr, setPst_online_arr] = React.useState([]);
  const [pst_offline_arr, setPst_offline_arr] = React.useState([]);

  const changebt3 = (text, val) => {
    setText6(1);
    setText7(1);
    setText8(1);

    if (text === "text6") {
      setText6(2);
    }
    else if (text === "text7") {
      setText7(2);
    }
    else if (text === "text8") {
      setText8(2);
    }

    setCheck_second_design(val)

    if (val == "All") {
      setPast_arr(pst_all_arr)
    }
    else if (val == "Online Appointment") {
      setPast_arr(pst_online_arr)
    }
    else if (val == "Offline Appointment") {
      setPast_arr(pst_offline_arr)
    }
  }
  /////////////////////////

  useEffect(() => {
    window.scrollTo(0, 0);

    const auth = localStorage.getItem("customer");
    const parsed = JSON.parse(auth);
    const id = parsed[0].id;

    setUser_id(id)
    getProducts(id)
  }, [])

  const getProducts = async (id) => {
    let result = await fetch(Connection + `get_user_appointment_A/${id}`, {

    })
    result = await result.json()
    const record4 = result.response;
    console.log(record4)

    if (record4) {
      const currentDate = new Date();

      const upcomingArr = [];
      const pastAppArr = [];

      record4.forEach((appointment) => {
        const dateString = appointment.date;
        const timeString = appointment.time;

        if (dateString || timeString) {
          const dateObject = new Date(dateString);
          const [timeHours, timeMinutes] = timeString.split(':');

          let hours = parseInt(timeHours);
          const minutes = parseInt(timeMinutes);

          const isPM = timeString.toLowerCase().includes('pm');
          if (isPM && hours !== 12) {
            hours += 12;
          } else if (!isPM && hours === 12) {
            hours = 0;
          }

          dateObject.setHours(hours, minutes, 0, 0);
          const isUpcoming = dateObject.getTime() > currentDate.getTime();

          console.log('Current Date:', currentDate);
          console.log('Date to Check:', dateObject);
          console.log('Is Upcoming:', isUpcoming);

          if (isUpcoming) {
            upcomingArr.push(appointment);
          } else {
            pastAppArr.push(appointment);
          }
        }
      });

      setUpcom_arr(upcomingArr.filter((record) => record.u_doctor_id !== null && record.status !== 'wait_for_pay' && record.status !== 'request'));
      setAll_arr(upcomingArr.filter((record) => record.u_doctor_id !== null && record.status !== 'wait_for_pay' && record.status !== 'request'));
      setOnline_arr(upcomingArr.filter((record) => record.type === 'online' && record.u_doctor_id !== null && record.status !== 'wait_for_pay' && record.status !== 'request'));
      setOffline_arr(upcomingArr.filter((record) => record.type === 'offline' && record.u_doctor_id !== null && record.status !== 'wait_for_pay' && record.status !== 'request'));


      setPast_arr(pastAppArr.filter((record) => record.u_doctor_id !== null && record.status !== 'wait_for_pay' && record.status !== 'request'));
      setPst_all_arr(pastAppArr.filter((record) => record.u_doctor_id !== null && record.status !== 'wait_for_pay' && record.status !== 'request'));
      setPst_online_arr(pastAppArr.filter((record) => record.type === 'online' && record.u_doctor_id !== null && record.status !== 'wait_for_pay' && record.status !== 'request'));
      setPst_offline_arr(pastAppArr.filter((record) => record.type === 'offline' && record.u_doctor_id !== null && record.status !== 'wait_for_pay' && record.status !== 'request'));

      setSkalton(false);
    }
    else {
      setSkalton(false);
      setUpcom_arr([]);
      setPast_arr([]);
    }
  }


  const handleShow = (payment_method, date, status, day, time, doctor_name, category, doctor_address, doctor_profile, fee, user_name, doctor_experience, id, type, user_type, doctor_id, doctor_email, fcm_token, web_fcm) => {
    document.body.style.overflow = 'hidden';

    setPayment_method(payment_method)
    setDate(date)
    setStatus(status)
    setDay(day)
    setTime(time)
    setDoctor_name(doctor_name)
    setCategory(category)
    setDoctor_address(doctor_address)
    setDoctor_profile(doctor_profile)
    setFee(fee)
    setUser_name(user_name)
    setDoctor_experience(doctor_experience)
    setAppointment_id(id)
    setApp_type(type)
    setUser_type(user_type)
    setDoctor_id(doctor_id)
    setDoctor_email(doctor_email)
    setFcm_token(fcm_token)
    setWeb_fcm(web_fcm)

    setShow(true);
  };

  const handleClose = () => {
    document.body.style.overflow = 'auto';
    setShow(false);
  };

  const handlecancelappoint = () => {
    document.body.style.overflow = 'auto';
    setCancelapoinSho(false);
  };


  const openreschedul = (id, doctor_id, doctor_name, doctor_email, category, date, time, doc_fcm_token, doc_web_fcm, type) => {
    document.body.style.overflow = 'hidden';

    setAppointment_id(id)
    setDoctor_id(doctor_id)
    setDoctor_name(doctor_name)
    setDoctor_email(doctor_email)
    setCategory(category)
    setDate(date)
    setTime(time)
    setFcm_token(doc_fcm_token)
    setWeb_fcm(doc_web_fcm)
    setApp_type(type)

    setReschedualapoinSho(true);
  };
  const handlerescheappoint = () => {
    document.body.style.overflow = 'auto';
    setReschedualapoinSho(false);
  };


  const openreadyreviw = (doctor_id, id, doctor_address, doctor_profile, doctor_name, category, doc_fcm_token, doc_web_fcm) => {
    document.body.style.overflow = 'hidden';

    setDoctor_id(doctor_id)
    setAppointment_id(id)
    setDoctor_address(doctor_address)
    setDoctor_profile(doctor_profile)
    setDoctor_name(doctor_name)
    setCategory(category)
    setFcm_token(doc_fcm_token)
    setWeb_fcm(doc_web_fcm)

    setReadyreview(true);
  };
  const handlereadyreviw = () => {
    document.body.style.overflow = 'auto';
    setReadyreview(false);
  };


  const alertfreenotcancel = () => {
    alert("You can't cancel HISD Select appointment.")
  }

  return (

    <div className='appointment_startup'>

      <div className="buttonContainer">
        <button className={text4 === 1 ? 'inactiveButton' : 'activeButton'} onClick={() => changebt1('text4', 'Upcoming')}>
          Upcoming
        </button>

        <button className={text5 === 1 ? 'inactiveButton' : 'activeButton'} onClick={() => changebt1('text5', 'Past')}>
          History
        </button>
      </div>

      {check_first_design == 'Upcoming' &&
        <>
          {/* <div className='viewofchange_btn'>
            <div className={text1 === 1 ? "in_active_button" : "active_button"} onClick={() => changebtn2("text1", 'All')}>
              <h4>All</h4>
            </div>

            <div className={text2 === 1 ? "in_active_button" : "active_button"} onClick={() => changebtn2("text2", 'Online Appointment')}>
              <h4>Online Appointment</h4>
            </div>

            <div className={text3 === 1 ? "in_active_button" : "active_button"} onClick={() => changebtn2("text3", 'Offline Appointment')}>
              <h4>Clinic Appointment</h4>
            </div>
          </div> */}

          {skalton == true ?
            <SkeletonTheme baseColor="lightgray" highlightColor="#bebebe">
              <div className='skelton_appoint_div'>
                <Skeleton count={2} className='sklnappointheight' />
              </div>
            </SkeletonTheme>
            :
            <>
              {upcom_arr != '' ?
                <>
                  {upcom_arr.map((row) => {
                    const appointment_id = row.id;
                    return (
                      <div key={appointment_id} className='one_appointment_card'>
                        <div className='imag_nd_nameetc'>
                          <div className='pahli_image'>
                            {row.doctor_profile == null ?
                              <img className="ima_apoint_doc" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIEFLVeftvEbJfqWQ2hnRMSPuF_b8VMn4PdPhXOJdkPm-bfdpWTZZYAUJyHVXS4wrsDAE&usqp=CAU" alt="doctor-image" />
                              :
                              <img className="ima_apoint_doc" src={Image_connection + row.doctor_profile} alt="doctor-image" />
                            }
                          </div>

                          <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: 15 }}>
                            <div className='name_top'>
                              <h3>Dr. {row.doctor_name}</h3>
                              <h4>${row.fee}</h4>
                            </div>

                            <div className='date_status_top'>
                              <h5>{row.category}</h5>
                            </div>
                          </div>
                        </div>

                        <div className='categ_detais'>
                          <h4><CalendarMonthIcon /> {row.time} | {row.date}</h4>
                          <div
                            onClick={() => handleShow(
                              row.payment_method,
                              row.date,
                              row.status,
                              row.day,
                              row.time,
                              row.doctor_name,
                              row.category,
                              row.doctor_address,
                              row.doctor_profile,
                              row.fee,
                              row.user_name,
                              row.doctor_experience,
                              row.id,
                              row.type,
                              row.user_type,
                              row.doctor_id,
                              row.doctor_email,
                              row.doc_fcm_token,
                              row.doc_web_fcm,
                            )}>
                            <h4>View Details</h4>
                          </div>
                        </div>

                        <div className='Paid_progress'>
                          {row.user_type == 'hisd' ?
                            <h4>(FREE HISD)</h4>
                            :
                            <>
                              {row.type == 'offline' ?
                                <h4>(Clinic Appointment)</h4>
                                :
                                <h4>(Paid)</h4>
                              }
                            </>
                          }

                          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                            <div className='doted_sta'></div>
                            {row.status == 'pending' &&
                              <h4>In-Process</h4>
                            }
                            {row.status == 'complete' &&
                              <h4>Showed-up</h4>
                            }
                            {row.status == 'cancel' &&
                              <h4>Cancelled</h4>
                            }
                            {row.status == 'request' &&
                              <h4>Requested</h4>
                            }
                          </div>
                        </div>

                        <div className='outsidetyprcheck'>
                          {row.user_type == 'hisd' &&
                            <>
                              {row.type == 'offline' ?
                                <h4>(Clinic Appointment)</h4>
                                :
                                <h4>(Schedule Appointment)</h4>
                              }
                            </>
                          }
                        </div>

                        <hr className="line"></hr>

                        {row.type != 'offline' &&
                          <>
                            {row.status == 'pending' &&
                              <div className='last_cancel_nd_reschedule'>
                                {row.user_type == 'hisd' ?
                                  <div onClick={() => alertfreenotcancel()} className='last_cancel'>
                                    <h3>Cancel Appointment</h3>
                                  </div>
                                  :
                                  <div onClick={() => cancelmodalOpen(row.id, row.fee, row.date, row.time, row.doctor_id, row.doc_fcm_token, row.doc_web_fcm, row.doctor_email, row.doctor_name)} className='last_cancel'>
                                    <h3>Cancel Appointment</h3>
                                  </div>
                                }

                                <div onClick={() => { openreschedul(row.id, row.doctor_id, row.doctor_name, row.doctor_email, row.category, row.date, row.time, row.doc_fcm_token, row.doc_web_fcm, row.type) }} className='last_reschedule'>
                                  <h3>Reschedule</h3>
                                </div>
                              </div>
                            }

                            {row.status == 'complete' &&
                              <div className='last_cancel_nd_reschedule'>
                                {row.review == appointment_id ?
                                  <div className='last_submitreview'>
                                    <h3>Submitted Review</h3>
                                  </div>
                                  :
                                  <div onClick={() => openreadyreviw(row.doctor_id, row.id, row.doctor_address, row.doctor_profile, row.doctor_name, row.category, row.doc_fcm_token, row.doc_web_fcm)} className='last_leavereview'>
                                    <h3>Leave Review</h3>
                                  </div>
                                }
                              </div>
                            }
                            {row.payment_method == 'Insurance' && <p style={{ color: '#00c480', fontSize: 14, fontWeight: 'bold', paddingLeft: 5 }}>Insurance Appointment</p>}
                          </>
                        }
                      </div>
                    )
                  })}
                </>
                :
                <div style={{ width: '100%', height: '60vh', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                  <p>No Record Found</p>
                </div>
              }
            </>
          }
        </>
      }




      {check_first_design == 'Past' &&
        <>
          {/* <div className='viewofchange_btn'>
            <div className={text6 === 1 ? "in_active_button" : "active_button"} onClick={() => changebt3("text6", 'All')}>
              <h4>All</h4>
            </div>

            <div className={text7 === 1 ? "in_active_button" : "active_button"} onClick={() => changebt3("text7", 'Online Appointment')}>
              <h4>Online Appointment</h4>
            </div>

            <div className={text8 === 1 ? "in_active_button" : "active_button"} onClick={() => changebt3("text8", 'Offline Appointment')}>
              <h4>Clinic Appointment</h4>
            </div>
          </div> */}

          {skalton == true ?
            <SkeletonTheme baseColor="lightgray" highlightColor="#bebebe">
              <div className='skelton_appoint_div'>
                <Skeleton count={2} className='sklnappointheight' />
              </div>
            </SkeletonTheme>
            :
            <>
              {past_arr != '' ?
                <>
                  {past_arr.map((row) => {
                    const appointment_id = row.id;
                    return (
                      <div key={appointment_id} className='one_appointment_card'>
                        <div className='imag_nd_nameetc'>
                          <div className='pahli_image'>
                            {row.doctor_profile == null ?
                              <img className="ima_apoint_doc" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIEFLVeftvEbJfqWQ2hnRMSPuF_b8VMn4PdPhXOJdkPm-bfdpWTZZYAUJyHVXS4wrsDAE&usqp=CAU" alt="doctor-image" />
                              :
                              <img className="ima_apoint_doc" src={Image_connection + row.doctor_profile} alt="doctor-image" />
                            }
                          </div>

                          <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: 15 }}>
                            <div className='name_top'>
                              <h3>Dr. {row.doctor_name}</h3>
                              <h4>${row.fee}</h4>
                            </div>

                            <div className='date_status_top'>
                              <h5>{row.category}</h5>
                            </div>
                          </div>
                        </div>

                        <div className='categ_detais'>
                          <h4><CalendarMonthIcon /> {row.time} | {row.date}</h4>
                          <div
                            onClick={() => handleShow(
                              row.payment_method,
                              row.date,
                              row.status,
                              row.day,
                              row.time,
                              row.doctor_name,
                              row.category,
                              row.doctor_address,
                              row.doctor_profile,
                              row.fee,
                              row.user_name,
                              row.doctor_experience,
                              row.id,
                              row.type,
                              row.user_type,
                              row.doctor_id,
                              row.doctor_email,
                              row.doc_fcm_token,
                              row.doc_web_fcm,
                            )}>
                            <h4>View Details</h4>
                          </div>
                        </div>

                        <div className='Paid_progress'>
                          {row.user_type == 'hisd' ?
                            <h4>(FREE HISD)</h4>
                            :
                            <>
                              {row.type == 'offline' ?
                                <h4>(Clinic Appointment)</h4>
                                :
                                <h4>(Paid)</h4>
                              }
                            </>
                          }

                          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                            <div className='doted_sta'></div>
                            {row.status == 'pending' &&
                              <h4>Expired</h4>
                            }
                            {row.status == 'complete' &&
                              <h4>Showed-up</h4>
                            }
                            {row.status == 'cancel' &&
                              <h4>Cancelled</h4>
                            }
                            {row.status == 'request' &&
                              <h4>Requested</h4>
                            }
                          </div>
                        </div>

                        <div className='outsidetyprcheck'>
                          {row.user_type == 'hisd' &&
                            <>
                              {row.type == 'offline' ?
                                <h4>(Clinic Appointment)</h4>
                                :
                                <h4>(Schedule Appointment)</h4>
                              }
                            </>
                          }
                        </div>

                        <hr className="line"></hr>

                        {row.type != 'offline' &&
                          <>
                            {row.status == 'pending' &&
                              <div className='last_cancel_nd_reschedule'>
                                {row.user_type == 'hisd' ?
                                  <div onClick={() => alertfreenotcancel()} className='last_cancel'>
                                    <h3>Cancel Appointment</h3>
                                  </div>
                                  :
                                  <div onClick={() => cancelmodalOpen(row.id, row.fee, row.date, row.time, row.doctor_id, row.doc_fcm_token, row.doc_web_fcm, row.doctor_email, row.doctor_name)} className='last_cancel'>
                                    <h3>Cancel Appointment</h3>
                                  </div>
                                }

                                <div onClick={() => { openreschedul(row.id, row.doctor_id, row.doctor_name, row.doctor_email, row.category, row.date, row.time, row.doc_fcm_token, row.doc_web_fcm, row.type) }} className='last_reschedule'>
                                  <h3>Reschedule</h3>
                                </div>
                              </div>
                            }

                            {row.status == 'complete' &&
                              <div className='last_cancel_nd_reschedule'>
                                {row.review == appointment_id ?
                                  <div className='last_submitreview'>
                                    <h3>Submitted Review</h3>
                                  </div>
                                  :
                                  <div onClick={() => openreadyreviw(row.doctor_id, row.id, row.doctor_address, row.doctor_profile, row.doctor_name, row.category, row.doc_fcm_token, row.doc_web_fcm)} className='last_leavereview'>
                                    <h3>Leave Review</h3>
                                  </div>
                                }
                              </div>
                            }

                            {row.payment_method == 'Insurance' && <p style={{ color: '#00c480', fontSize: 14, fontWeight: 'bold', paddingLeft: 5 }}>Insurance Appointment</p>}
                          </>
                        }
                      </div>
                    )
                  })}
                </>
                :
                <div style={{ width: '100%', height: '60vh', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                  <p>No Record Found</p>
                </div>
              }
            </>
          }
        </>
      }




      {show == true &&
        <Modal_detail
          show={show}
          handleClose={handleClose}
          payment_method={payment_method}
          date={date}
          status={status}
          day={day}
          time={time}
          doctor_name={doctor_name}
          category={category}
          doctor_address={doctor_address}
          doctor_profile={doctor_profile}
          fee={fee}
          user_name={user_name}
          doctor_experience={doctor_experience}
          appointment_id={appointment_id}
          app_type={app_type}
          user_type={user_type}
          doctor_id={doctor_id}
          doctor_email={doctor_email}
          fcm_token={fcm_token}
          web_fcm={web_fcm}
        >
          <p>Modal Content Goes Here</p>
        </Modal_detail>
      }


      <div className={`bottom-sheet-overlay ${cancelOpen ? 'open' : ''}`}>
        <div className="cancel_sheet_content">
          <h2>Cancel Appointment</h2>
          <hr className='line'></hr>
          <p>Are you sure you want to cancel your appointment?</p>
          <p style={{ marginTop: 10 }}>Only 80% of the funds will return to your accouont?</p>
          <hr className='line'></hr>

          <div className='twobtn_cancel'>
            <button className='close' onClick={cancelmodalClose}>Close</button>
            <button className='yescancel' onClick={cancelmodalnd_next}>Yes, Cancel</button>
          </div>
        </div>
      </div>

      {cancelapoinSho == true &&
        <Modal_cancel_appoint handlecancelappoint={handlecancelappoint} get_apointments={getProducts} appointment_id={appointment_id} doctor_id={doctor_id} user_id={user_id} doctor_nam={doctor_name} doctor_email={doctor_email} fee={fee} time={time} date={date} fcm_token={fcm_token} web_fcm={web_fcm}>
          <p>Modal Content Goes Here</p>
        </Modal_cancel_appoint>
      }

      {reschedualapoinSho == true &&
        <Modal_reschedual_appoint handlerescheappoint={handlerescheappoint} get_apointments={getProducts} appointment_id={appointment_id} doctor_id={doctor_id} user_id={user_id} doctor_nam={doctor_name} doctor_email={doctor_email} category={category} appointme_date={date} time={time} fcm_token={fcm_token} web_fcm={web_fcm} type_of={app_type}>
          <p>Modal Content Goes Here</p>
        </Modal_reschedual_appoint>
      }

      {readyreview == true &&
        <Modal_ready_review handlereadyreviw={handlereadyreviw} get_apointments={getProducts} doctor_id={doctor_id} appointment_id={appointment_id} doctor_address={doctor_address} doctor_profile={doctor_profile} doctor_nam={doctor_name} category={category} fcm_token={fcm_token} web_fcm={web_fcm}>
          <p>Modal Content Goes Here</p>
        </Modal_ready_review>
      }

    </div>
  );
}