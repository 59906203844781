import React, { useState, useEffect } from 'react'
import './Clinic_Number.css';
import Image_connection from "../../Image_connection";
import Connection from "../../connection";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PulseLoader from "react-spinners/PulseLoader";

function Clinic_Number({ handleClose }) {
    const [user_id, setUser_id] = React.useState('');
    const [clinic_number, setClinic_number] = React.useState('');
    const [profile, setProfile] = useState(null);

    const [spinner, setSpinner] = useState(false);


    useEffect(() => {
        const auth = localStorage.getItem("customer");
        const parsed = JSON.parse(auth);
        const id = parsed[0].id;
        const clinc_number = parsed[0].clinic_number;

        setUser_id(id)
        setClinic_number(clinc_number)


        let profile1 = parsed[0].profile;
        if (profile1 == null) {
            setProfile(null)
        }
        else {
            let profile = Image_connection + profile1
            setProfile(profile)
        }
    }, [])


    const add_clinic_number = async () => {
        if (clinic_number == "" || clinic_number == null) {
            alert("Please enter your clinic number.");
        }
        else {
            setSpinner(true)
            const id = user_id
            let result = await fetch(Connection + "add_clinic_number", {
                method: 'post',
                body: JSON.stringify({ clinic_number, id }),
                headers: {
                    "Content-Type": 'application/json'
                }
            })
            result = await result.json()
            const record4 = result.response

            if (record4 == "fail") {
                setSpinner(false)
                alert("Please try again later");
            }
            else {
                const user = localStorage.getItem("customer");
                let parsed = JSON.parse(user);
                parsed[0].clinic_number = clinic_number;
                localStorage.setItem("customer", JSON.stringify(parsed))

                setSpinner(false)
                toast.success('You have successfully add your clinic number.');
            }
        }

    }


    return (
        <div className="Clinicnumberbground">

            <div className="ClinicnumberContainer">
                <div style={{ width: '97%', backgroundColor: '#874DAF', padding: '5px 10px', display: 'flex', alignItems: 'center', flexDirection: 'row', marginTop: 15, alignSelf: 'center' }}>
                    <img
                        style={{ width: '55px', height: '55px', borderRadius: '40px', objectFit: 'cover' }}
                        src={profile || 'https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG.png'}
                        alt="Profile"
                    />
                    <div style={{ marginLeft: '10px', justifyContent: 'center' }}>
                        <p style={{ color: 'white', fontWeight: 'bold', fontSize: '18px', margin: '0' }}>Clinic Number</p>
                    </div>
                </div>

                <h3 style={{ color: 'gray', fontWeight: 'normal', fontSize: 14, marginLeft: 15, marginRight: 15, marginTop: 15, marginBottom: 10 }}>Here you can add your Clinic Number.</h3>


                <div className="label">Clinic Number</div>
                <input
                    type="text"
                    placeholder="Enter Clinic Number"
                    className="phoneinput"
                    value={clinic_number}
                    onChange={(e) => setClinic_number(e.target.value)}
                />


                {clinic_number == null || clinic_number == '' ?
                    <div onClick={() => add_clinic_number()} className='Clinicnumber_btn'>
                        <p>Add Clinic Number</p>
                    </div>
                    :
                    <div onClick={() => add_clinic_number()} className='Clinicnumber_btn'>
                        <p>Update Clinic Number</p>
                    </div>
                }

                <ToastContainer />
            </div>


            {spinner == true &&
                <div style={{ width: '100%', height: '100%', backgroundColor: 'rgba(2, 2, 2, 0.8)', position: 'absolute', top: 0, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <div style={{ display: 'flex', width: '30%', backgroundColor: 'white', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', padding: '30px', boxShadow: '0 1px 5px rgba(0, 0, 0, 0.2)', borderRadius: '6px', }}>
                        <PulseLoader
                            color={"#874DAF"}
                            loading={spinner}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                        <p style={{ fontSize: '16px', color: '#874DAF', fontWeight: 'bold', marginLeft: '15px' }}>
                            Progressing your request
                        </p>
                    </div>
                </div>
            }
        </div>
    );
}

export default Clinic_Number;
