import React, { useState, useEffect, createContext, memo } from 'react'
import { Button } from './Dropdown/Button';
import { Link } from 'react-router-dom';
import './Navbar.css';
import MoreOptionsDropdown from './Dropdown/MoreOptionsDropdown';

function Navbar() {
  const [click, setClick] = useState(false);
  const [activeButton, setActiveButton] = useState(null); // New state variable
  const [role, setRole] = useState('');

  const handleClick = () => {
    setClick(!click)

    if (!click) {
      document.body.style.overflow = 'hidden';
    }
    else {
      document.body.style.overflow = 'auto';
    }
  };

  const closeMobileMenu = () => {
    setClick(false);
    document.body.style.overflow = 'auto';
  }

  useEffect(() => {
    let user = localStorage.getItem('customer');
    if (user != null) {
      const parsed = JSON.parse(user);
      const getrole = parsed[0].role;
      setRole(getrole)
    }

  }, [])

  const pat = [
    {
      title: 'Dashboard',
      path: '/',
      cName: 'nav-links',
    },
    {
      title: 'Chat',
      path: '/Chat',
      cName: 'nav-links',
    },
    {
      title: 'Doctors',
      path: '/Doctor_list',
      cName: 'nav-links',
    },
    {
      title: 'Urgent Visit',
      path: '/All_consult_patient_site',
      cName: 'nav-links',
    },
    {
      title: 'Appointment',
      path: '/Patient_All_Appointment',
      cName: 'nav-links',
    },
  ];


  const doc = [
    {
      title: 'Dashboard',
      path: '/',
      cName: 'nav-links',
    },
    {
      title: 'Chat',
      path: '/Chat',
      cName: 'nav-links',
    },
    {
      title: 'Urgent Visit',
      path: '/All_consult_doctor_site',
      cName: 'nav-links',
    },
    {
      title: 'Appointment',
      path: '/Doctor_All_Appointment',
      cName: 'nav-links',
    },
  ];

  const handleButtonClick = (index) => {
    setActiveButton(index);
  };


  return (
    <>
      <nav className='navbar'>

        <div className='shortyscrn'>
          <div className='navbar_logo'>
            Mas Clinicas
          </div>

          <div className='menu_icon' onClick={handleClick}>
            <i className={click ? '' : 'fas fa-bars'} />
          </div>
        </div>

        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
          <i onClick={handleClick} className={'fas fa-times'} />

          {role == 'user' ?
            <>
              {pat.map((item, index) => {
                return (
                  <li className='nav-item' key={index}>
                    <Link
                      to={item.path}
                      className={activeButton === index ? 'nav-links active' : 'nav-links'}
                      onClick={() => {
                        closeMobileMenu();
                        handleButtonClick(index);
                      }}
                    >
                      {item.title}
                    </Link>
                  </li>
                );
              })}
            </>
            :
            <>
              {doc.map((item, index) => {
                return (
                  <li className='nav-item' key={index}>
                    <Link
                      to={item.path}
                      className={activeButton === index ? 'nav-links active' : 'nav-links'}
                      onClick={() => {
                        closeMobileMenu();
                        handleButtonClick(index);
                      }}
                    >
                      {item.title}
                    </Link>
                  </li>
                );
              })}
            </>
          }

          <div className="butnidshortlgot">
            <Button />
          </div>
        </ul>

        <div className='lgotorsetngbtn'>
          <MoreOptionsDropdown />
          <div className="butnidlgot">
            <Button />
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
