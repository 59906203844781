import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom"
import Image_connection from "../../Image_connection";
import "./Patient_consult_details.css";
import Connection from '../../connection';

import PulseLoader from "react-spinners/PulseLoader";
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import html2canvas from 'html2canvas';

function Patient_consult_details({ handleClose, getProducts, fee, status, user_address, meeting_id, receiver_id, receiver_name, user_email, user_mobile, sender_id, sender_name, receiver_image, sender_image, fcm_token, user_online, date2, app_type, user_type, active }) {

    const navigate = useNavigate();

    const [skalton, setSkalton] = useState(true);

    const [data, setData] = useState([]);
    const [reason, setReason] = useState('');
    const [symptoms, setSymptoms] = useState('');
    const [issue, setIssue] = useState('');
    const [allergic, setAllergic] = useState('');
    const [medication, setMedication] = useState('');


    // Change Btn
    const [text1, setText1] = useState(2);
    const [text2, setText2] = useState(1);
    const [checkDesign, setCheckDesign] = useState('Details');

    const changeBtn = (text, val) => {
        setText1(1);
        setText2(1);

        if (text === "text1") {
            setText1(2);
        }
        else if (text === "text2") {
            setText2(2);
        }

        setCheckDesign(val);
    };
    /////////////////////////



    useEffect(() => {
        const auth = localStorage.getItem("customer");
        const parsed = JSON.parse(auth);
        const id = parsed[0].id;

        getIssueAppointment(id);
    }, [])

    const getIssueAppointment = async (id) => {
        setSkalton(true);

        const appointment_id = meeting_id

        let result = await fetch(Connection + "getissue_appointment_for_patient", {
            method: 'post',
            body: JSON.stringify({ id, appointment_id }),
            headers: {
                "Content-Type": 'application/json'
            }
        })
        const response = await result.json();
        let response1 = response.response;
        console.log("response .........", response1);

        if (response1 !== 'fail') {
            let reason = response1[0].reason;
            let symptoms = response1[0].symptoms;
            let issue = response1[0].issue;
            let allergic = response1[0].allergic;
            let medication = response1[0].medication;

            setData(response1);
            setReason(reason);
            setSymptoms(symptoms);
            setIssue(issue);
            setAllergic(allergic);
            setMedication(medication);
            setSkalton(false);
        }
        else {
            setData([]);
            setSkalton(false);
        }
    };


    const handleDownloadImage = () => {
        const prescriptionContainer = document.querySelector('.prescription-container');

        if (prescriptionContainer) {
            html2canvas(prescriptionContainer)
                .then((canvas) => {
                    const url = canvas.toDataURL('image/png');

                    // Create an anchor element and trigger the download
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = `Prescription_${meeting_id}.png`; // You can customize the filename
                    document.body.appendChild(a);
                    a.click();

                    // Cleanup
                    document.body.removeChild(a);
                })
                .catch((error) => {
                    console.error('Error capturing image:', error);
                });
        }
    };


    return (
        <div className="Patient_urgentsideBackground">

            <div className="patienturgentsideContainer">
                <div className="titlepatienturgentsideCloseBtn">
                    <button onClick={() => { handleClose(false); }}>
                        <ArrowBackIcon />
                    </button>
                    <h3>Consult Detail</h3>
                    <h1></h1>
                </div>

                <hr style={{ marginTop: 15, marginBottom: 3 }} />

                {status == 'complete' &&
                    <div style={{ width: '90%', backgroundColor: 'white', borderRadius: 10, margin: '10px auto', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', boxShadow: '0px 2px 3.84px rgba(0, 0, 0, 0.25)', elevation: 5, paddingTop: 3, paddingBottom: 3 }}>
                        <button style={{ width: '49%' }} onClick={() => changeBtn('text1', 'Details')} className={text1 === 1 ? 'inactive-button' : 'active-button'}>
                            <span className={text1 === 1 ? 'inactive-text' : 'active-text'}>{'Details'}</span>
                        </button>

                        <button style={{ width: '49%' }} onClick={() => changeBtn('text2', 'Prescription')} className={text2 === 1 ? 'inactive-button' : 'active-button'}>
                            <span className={text2 === 1 ? 'inactive-text' : 'active-text'}>{'Prescription'}</span>
                        </button>
                    </div>
                }


                {checkDesign == "Details" &&
                    <>
                        <div className="your-container-style">
                            <div className="row-style">
                                <div className="column-style">
                                    <p className="label-style">Patient Name:</p>
                                    <p className="label-style">Doctor Name:</p>
                                    <p className="label-style">Meeting:</p>
                                    <p className="label-style">Date:</p>
                                    <p className="label-style">Status:</p>
                                    <p className="label-style">Type:</p>
                                </div>

                                <div className="column-style">
                                    <p className="value-style" style={{ maxWidth: '85%' }}>{receiver_name}</p>
                                    <p className="value-style" style={{ maxWidth: '85%' }}>{sender_name}</p>
                                    <p className="value-style">Urgent Visits</p>
                                    <p className="value-style" style={{ maxWidth: '85%' }}>{date2}</p>
                                    <p className={`status-style ${status.toLowerCase()}`}>{status}</p>
                                    <p className={`payment-style`}>{user_type == 'hisd' ? 'FREE HISD' : 'Paid'}</p>
                                </div>
                            </div>
                        </div>


                        <div className="your-component-container">
                            {skalton ? (
                                <div className="skalton-container">
                                    <PulseLoader
                                        color={"gray"}
                                        loading={skalton}
                                        aria-label="Loading Spinner"
                                        data-testid="loader"
                                    />
                                </div>
                            ) : (
                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    {data.length === 0 ? (
                                        <div className="no-data-container">
                                            <p style={{ color: 'black' }}>No treatment result</p>
                                        </div>
                                    ) : (
                                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                            <div className="data-container">
                                                <p style={{ color: "#874DAF", fontWeight: 'bold', fontSize: 14 }}>More Details</p>

                                                <hr className="divider" />

                                                <div className="detail-row">
                                                    <p style={{ color: 'black', fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>Reason:</p>
                                                    <p style={{ color: 'black', fontSize: 12, maxWidth: '90%' }}>{reason}</p>
                                                </div>

                                                <div className="detail-row">
                                                    <p style={{ color: 'black', fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>Symptoms:</p>
                                                    <p style={{ color: 'black', fontSize: 12, maxWidth: '90%' }}>{symptoms}</p>
                                                </div>

                                                <div className="detail-row">
                                                    <p style={{ color: 'black', fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>Issues:</p>
                                                    <p style={{ color: 'black', fontSize: 12, maxWidth: '90%' }}>{issue}</p>
                                                </div>

                                                <div className="detail-row">
                                                    <p style={{ color: 'black', fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>Allergic:</p>
                                                    <p style={{ color: 'black', fontSize: 12, maxWidth: '90%' }}>{allergic}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </>
                }


                {checkDesign == "Prescription" &&
                    <div className="your-component-container">
                        {skalton ? (
                            <div className="skalton-container">
                                <PulseLoader
                                    color={"gray"}
                                    loading={skalton}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                />
                            </div>
                        ) : (
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                {data.length === 0 ? (
                                    <div className="no-data-container">
                                        <p style={{ color: 'black' }}>No treatment result</p>
                                    </div>
                                ) : (
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '-25px' }}>
                                        <div className="prescription-container">
                                            <p className="prescription-title">Prescription</p>
                                            <div className="divider"></div>
                                            <p className="prescription-text">{medication}</p>
                                        </div>

                                        <button onClick={handleDownloadImage}
                                            className="download-button"
                                        >
                                            <DownloadOutlinedIcon style={{ color: 'white', fontSize: 22, marginRight: 5 }} />
                                            <p style={{ color: 'white', fontWeight: 'bold' }}>Download</p>
                                        </button>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                }
            </div>

        </div>
    );
}

export default Patient_consult_details;
