import React, { useState, useEffect, } from 'react'
import { Link, parsePath, useParams, useNavigate, useLocation } from "react-router-dom"
import './Doctor_Details.css';
import PulseLoader from "react-spinners/PulseLoader";
import Connection from "../../connection";
import Image_connection from "../../Image_connection";
import ReactStars from "react-rating-stars-component";
import Modal from '../../MyComponent/Modal/Modal';
import { GoogleMap, Marker } from '@react-google-maps/api';

export default function Doctor_Details() {
    const params = useParams();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const type_of = searchParams.get('value1');
    const usertype = searchParams.get('value2');


    const [text1, setText1] = React.useState(true);
    const [text2, setText2] = React.useState(false);

    const [name, setName] = React.useState("");
    const [category, setCategory] = React.useState("");
    const [city, setCity] = React.useState("");

    const [mobile_number, setMobile_number] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [fcm_token, setFcm_token] = React.useState("");
    const [web_fcm, setWeb_fcm] = React.useState("");
    const [address, setAddress] = React.useState("");

    const [lat, setLat] = React.useState(0);
    const [lng, setLng] = React.useState(0);

    const [gender, setGender] = React.useState("");
    const [experience, setExperience] = React.useState("");
    const [degree, setDegree] = React.useState("");
    const [about, setAbout] = React.useState("");
    const [license_number, setLicense_number] = React.useState("");
    const [profile, setProfile] = React.useState("");
    const [license_image, setIicense_image] = React.useState("");
    const [in_person, setIn_person] = React.useState("");
    const [insurance_fee, setInsurance_fee] = React.useState("");
    const [fee, setFee] = React.useState("");
    const [patLoading, setPatLoading] = useState(false);
    const [reviews, setReviews] = useState(false);
    const [user_profile, setUser_profile] = useState(null);

    const [show, setShow] = useState(false);

    const [day_11, setDay_11] = useState('');
    const [final_date_1, setFinal_date_1] = useState('');

    const navigate = useNavigate();

    const [doctor_id, setDoctor_id] = React.useState(params.id);


    // Map
    const mapContainerStyle = {
        width: '100%',
        height: '100%',
    };

    const center = {
        lat: lat,
        lng: lng,
    };
    ///////////


    // Change Btn
    const openText1 = (event) => {
        event.preventDefault();
        setText1(true);
        setText2(false);
    };
    const openText2 = (event) => {
        event.preventDefault();
        setText2(true);
        setText1(false);
    };


    useEffect(() => {
        window.scrollTo(0, 0);

        console.log("checkkkkkkkkkkkk")

        getProducts()
        getReview()

        var today = new Date();
        var nextweek_T = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        const date0 = nextweek_T.toString()
        const ddd = date0.split(' ')
        const day_1 = ddd[0]
        const dd_2 = ddd[1]
        const dd_3 = ddd[2]
        const dd_4 = ddd[3]
        const final_date_1 = dd_2 + ' ' + dd_3 + ', ' + dd_4
        setDay_11(day_1)
        setFinal_date_1(final_date_1)
    }, [])

    const getProducts = async () => {
        let result = await fetch(Connection + `one_doctor/${params.id}`, {

        })
        result = await result.json()
        setName(result[0].name)
        setCategory(result[0].category)
        setCity(result[0].city)
        setMobile_number(result[0].mobile_number)
        setEmail(result[0].email)
        setFcm_token(result[0].fcm_token)
        setWeb_fcm(result[0].web_fcm)

        let lat1 = result[0].lat
        let lng1 = result[0].lng
        let lat = parseFloat(lat1);
        let lng = parseFloat(lng1);
        setLat(lat)
        setLng(lng)

        setAddress(result[0].address)
        setGender(result[0].gender)
        setExperience(result[0].experience)
        setDegree(result[0].degree)
        setAbout(result[0].c_name)
        setLicense_number(result[0].license_number)
        setProfile(result[0].profile)
        setIicense_image(result[0].license_image)
        setIn_person(result[0].in_person)
        setInsurance_fee(result[0].insurance_fee)
        setFee(result[0].fee)
    }

    const getReview = async () => {
        let result = await fetch(Connection + `getReview_bydoctor/${params.id}`, {

        })
        result = await result.json()

        if (result) {
            setUser_profile(result[0].user_profile)
            setReviews(result)
        }
    }

    const handleShow = () => {
        document.body.style.overflow = 'hidden';
        setShow(true);
    };

    const handleClose = () => {
        document.body.style.overflow = 'auto';
        setShow(false);
    };

    return (
        <div className='Doctor_head'>
            <h2 className='toptextprof'>DOCTOR PROFILE</h2>

            <div className='All_in_this'>

                <div className='first_column'>
                    <div className='Profile_card'>
                        <div className='img_wrapper'>
                            {profile == null ?
                                <img src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiQc9dZn33Wnk-j0sXZ19f8NiMZpJys7nTlA&usqp=CAU"} title="Profile Image" alt="" />
                                :
                                <img src={Image_connection + profile} title="Profile Image" alt="" />
                            }
                        </div>

                        <div className='profile_detail'>
                            <h2 className='nameofdoc'>{name}</h2>
                            <div className='star'>
                                <i className="bi bi-star-fill"></i>
                                <i className="bi bi-star-fill"></i>
                                <i className="bi bi-star-fill"></i>
                                <i className="bi bi-star-fill"></i>
                                <i className="bi bi-star-fill"></i>
                                <span>(38)</span>
                            </div>
                            <h3 className='cateogdoc'>{category}</h3>

                            <div className='just_row'>
                                <i className="bi bi-telephone"></i>
                                <h4>{mobile_number}</h4>
                            </div>

                            <div className='just_row_2'>
                                <i className="bi bi-envelope-at"></i>
                                <h4>{email}</h4>
                            </div>

                            <div className='just_row_2'>
                                <i className="bi bi-currency-dollar"></i>
                                <h4>{fee}</h4>
                            </div>
                        </div>
                    </div>

                    <div className='chanebtn_card'>
                        <div className={text1 == false ? "about_me_btn_inactive" : "about_me_btn_active"} onClick={openText1}>
                            <a href='#'>About</a>
                        </div>

                        <div className={text2 == false ? "about_me_btn_inactive" : "about_me_btn_active"} onClick={openText2}>
                            <a href='#'>Reviews</a>
                        </div>
                    </div>


                    {text1 == true ?
                        <div className='About_in_btn'>
                            <h3 className='aboutmaintext'>ABOUT</h3>
                            <hr></hr>

                            <div className='numer_licen'>
                                <h3>License Number</h3>
                                {license_number != '' && license_number != null ?
                                    <h4>{license_number}</h4>
                                    :
                                    <h4>No Record Found</h4>
                                }
                                <hr></hr>
                            </div>

                            <div className='img_licen'>
                                <h3>License Image</h3>
                                {license_image == null ?
                                    <img src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiQc9dZn33Wnk-j0sXZ19f8NiMZpJys7nTlA&usqp=CAU"} title="Profile Image" alt="" />
                                    :
                                    <img src={Image_connection + license_image} title="license Image" alt="" />
                                }
                            </div>
                        </div>
                        :
                        <div></div>
                    }


                    {text2 == true ?
                        <div className='Review_Card'>
                            <h3 className='revewmaintext'>Recent Review</h3>
                            <hr></hr>

                            {reviews == "" ?
                                <div className='Noreview'>
                                    <h4>No Record Found</h4>
                                </div>
                                :
                                <>
                                    {reviews.map((row) => (
                                        <div className='review_all'>
                                            {user_profile == null ?
                                                <img src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiQc9dZn33Wnk-j0sXZ19f8NiMZpJys7nTlA&usqp=CAU"} title="Profile Image" alt="" />
                                                :
                                                <img src={Image_connection + user_profile} title="license Image" alt="" />
                                            }

                                            <div className='review_details'>
                                                <h3 className='nameofuserrev'>{row.user_name}</h3>

                                                <p>{row.comment}</p>

                                                <div className='bottom_deatil_review'>
                                                    <h3>({row.rating})</h3>
                                                    <ReactStars
                                                        count={5}
                                                        value={row.rating}
                                                        size={24}
                                                        edit={false}
                                                        activeColor="#ffd700"
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                    ))}
                                </>
                            }

                        </div>
                        :
                        <div></div>
                    }

                </div>

                <div className='second_column'>
                    <div className='Map_Card'>
                        <div className='address_head'>
                            <h3>Map</h3>
                        </div>

                        <hr></hr>

                        <div className='address_paragraph'>
                            <GoogleMap mapContainerStyle={mapContainerStyle} center={center} zoom={13}>
                                <Marker position={center} />
                            </GoogleMap>
                        </div>
                        <h4>{address}</h4>
                    </div>


                    <div className='About_card'>
                        <div className='about_head'>
                            <h3>About Me</h3>
                        </div>

                        <hr></hr>

                        <div className='about_data'>
                            <p>{about}</p>
                            <hr></hr>
                            <div className='about_dat_row'>
                                <h3>Gender</h3>
                                <h4>{gender}</h4>
                            </div>

                            <hr></hr>

                            <div className='about_dat_row'>
                                <h3>Experience</h3>
                                <h4>{experience} years</h4>
                            </div>

                            <hr></hr>

                            <div className='about_dat_row'>
                                <h3>Degree</h3>
                                <h4>{degree}</h4>
                            </div>

                            <hr></hr>

                            {/* <div className='about_dat_row'>
                                <h3>Designation</h3>
                                <h4>J.Professer</h4>
                            </div> */}

                            <hr></hr>

                            <div className='about_last_row'>
                                <div className='about_last_section'>
                                    <h3>37</h3>
                                    <h4>PATIENT</h4>
                                </div>

                                <div className='about_last_section'>
                                    <h3>61</h3>
                                    <h4>REVIEW</h4>
                                </div>

                                <div className='about_last_section'>
                                    <h3>{fee}</h3>
                                    <h4>FEE</h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='book_buttun'>
                        <button onClick={() => handleShow()} className="book">
                            {type_of == 'online' &&
                                <p>Online Appointment</p>
                            }

                            {type_of == 'offline' &&
                                <p>Clinic Appointment</p>
                            }

                            {type_of == 'urgent' &&
                                <p>Urgent Care</p>
                            }
                        </button>
                    </div>
                </div>

            </div>

            {show &&
                <Modal show={show} handleClose={handleClose} day_11={day_11} final_date_1={final_date_1} doctor_id={doctor_id} doctor_name={name} insurance_fee={insurance_fee} fee={fee} category={category} city={city} email={email} fcm_token={fcm_token} web_fcm={web_fcm} type_of={type_of} usertype={usertype}>
                    <p>Modal Content Goes Here</p>
                </Modal>
            }

        </div>
    );
}